import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";

export const PRODUCT_TEMPLATE_API = {

    BASE: "/company/product-template",

    getTemplates: (params: any = {}, cancelToken: any) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params:{...params, is_paginate: true},
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/company/product-template`, options, true, false);
    },
    getFullTemplates: (params: any = {}, cancelToken: any) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            data: params
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/company/product-template`, options, true, false);
    },
    getOne: ({uid}) => {
        return axiosRequest(`${PRODUCT_TEMPLATE_API.BASE}/${uid}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },
    createOne: (data = {}) => {
        return axiosRequest(`${PRODUCT_TEMPLATE_API.BASE}`, {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, true, false);
    },
    updateOne: (data: any) => {
        const {uid} = data;
        if (uid) {
            return axiosRequest(`${PRODUCT_TEMPLATE_API.BASE}/${uid}`, {
                method: 'PUT',
                data,
                headers: {...AUTH.getHeader()},
            }, true, false);
        }
    },
    deleteOne: ({uid}: any) => {
        return axiosRequest(`${PRODUCT_TEMPLATE_API.BASE}/${uid}`, {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },


}