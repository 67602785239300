import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {getBasicConfigAsync} from "@redux/reducers/configSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {userMetadataState} from "@redux/reducers/userSlice";
import {CookieService, fibLogger} from "@src/helpers";
import {companyMetaState} from "@redux/reducers/companyMetaSlice";
import {getListLanguages} from "@redux/reducers/languageSlice";
import {useNavigate} from "react-router-dom";
import {appProviderState} from "@redux/reducers/appProviderSlice";
import {COMMON_API} from "@api/Common";
import {showMessage} from "@redux/reducers/messageSlice";
import {Message} from "@src/types";

const AppProvider = ({children}) => {

    /**
     * @todo:
     * Remove context and replace with redux to improve speed
     */
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {isLoggedIn} = useAppSelector(appProviderState)
    const userMetadata = useAppSelector(userMetadataState);
    const companyMetaStore = useAppSelector(companyMetaState)
    const appProvideStore = useAppSelector(appProviderState)

    useEffect(() => {

        const abortController = new AbortController();
        dispatch(getListLanguages({signal: abortController.signal}))

        dispatch(getBasicConfigAsync()).then(unwrapResult).then(config => {
            fibLogger({message: "load app config", config})
        })

        return () => {
            abortController.abort();
        }
    }, []);

    useEffect(() => {

        COMMON_API.getCFGeoData().then((res) => {
            fibLogger({message: "getCFGeoData", res})
            const {headers} = res;
            if (headers) {
                const {'cloudFront-viewer-country': country = ""} = headers;

                let viewerCountry = country || "";
                if (!CookieService.get("cf-geo-data") && viewerCountry === "CN") {
                    dispatch(showMessage({
                        isShow: true,
                        type: Message.NEUTRAL,
                        title: "LABEL.CONTACT_SUPPORT_FOR_ASSISTANCE",
                        message: "LABEL.FIBRETRACE.REQUIREMENTS",
                        size: "lg",
                        style: {
                            fontSize: "16px",
                            lineHeight: "30px",
                            color: "#004B8D",
                            // color: "#FF6600"
                        }
                    }))
                    CookieService.set("cf-geo-data", JSON.stringify(res.data), 8640);
                }
            }

        })
    }, [])

    return <>{children}</>
};

export default AppProvider;
