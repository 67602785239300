import {useEffect, useState} from 'react';
import {io, Socket} from 'socket.io-client';
import {ENV} from "../../environments/environment";
import {fetchNotificationAsync} from "@redux/reducers/notificationSlice";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {userState} from "@redux/reducers/userSlice";

export const useSocket = (isLoggedIn: boolean) => {
  const userInfo = useAppSelector(userState);
  const dispatch = useAppDispatch();
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    let newSocket: Socket | null = null;

    if (isLoggedIn && !socket) {

      newSocket = io(ENV.SOCKET_URL);
      setSocket(newSocket);

      newSocket.emit('register', {email: userInfo.email});

      newSocket.on('notification', (data) => {
        dispatch(fetchNotificationAsync({}));
      });

      newSocket.on('connect_error', (err) => {
        console.error("Socket connection error:", err);
      });
    }

    return () => {
      if (newSocket) {
        newSocket.disconnect();
        setSocket(null);
        console.log("Goodbye")
      }
    };
  }, [isLoggedIn]);

  return socket;
};
