import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {DEFAULT_MODAL} from "@src/variables";
import {ConfirmationMessageInterface} from "@src/types/modal";
import _ from "lodash-es";

const initialState: ConfirmationMessageInterface = {
    ...DEFAULT_MODAL,
    size: "lg",
    confirmAction: "",
    cancelAction: "",
    chainConfirmActions: [],
    chainCancelActions: [],
    confirmButton: {
        visible: true,
        variant: "success",
        label: "Yes"
    },
    cancelButton: {
        visible: true,
        variant: "outline-dark",
        label: "No"
    },
    children: null
}

export const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        showMessage: (state) => {
            state.isShow = true;
        },
        hideMessage: (state) => {
            state.isShow = false;
        },
        setState: (state, action: PayloadAction<ConfirmationMessageInterface>) => {
            _.merge(state, action.payload);
        }
    },
});

export const registerState = (state: RootState) => state.register;
export default registerSlice.reducer