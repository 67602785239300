import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPANY_API} from "@api/Company";
import {CompanyInterface} from "@redux/reducers/companySlice";
import {I_METADATA_GETTER, I_METADATA_SETTER} from "@src/types/metadata";

export interface CompanyMetaFieldsRequest {
    fields: Array<keyof CompanyInterface | "company_data">
}

export interface CompanyMetaData {
    has_audits: boolean
    has_partners: boolean
    has_processes: boolean
    has_products: boolean
    has_sites: boolean
}

const initialState: any = {}

export const getCompanyMetaAsync = createAsyncThunk(
    'company/getCompanyMeta',
    async (args: CompanyMetaFieldsRequest, store) => {
        try {
            const response: {
                data: any;
                error: any;
            } = await COMPANY_API.getCompanyMeta(args)

            return response;
        } catch (e) {
            console.error(e)
            return {data: {
                    "company_data": {
                        "has_audits": true,
                        "has_partners": true,
                        "has_processes": true,
                        "has_products": true,
                        "has_sites": true
                    }
                }, error: false}
        }

    }
);

export const getCompanyMetaV2Async = createAsyncThunk(
    'companyMeta/getMetadataV2',
    async (args: I_METADATA_GETTER = {object: "COMPANY"}) => {
        try {
            const {data, message, error} = await COMPANY_API.getCompanyMetaData(args)
            return {data, message, error}
        } catch (e) {
            return {
                data: [
                    {
                        "key": "is_setup_done",
                        "value": "1"
                    },
                    {
                        "key": "is_claim_dispute_required",
                        "value": "0"
                    }
                ]
            }
        }
    }
);

export const updateCompanyMetaV2Async = createAsyncThunk(
    'companyMeta/updateMetadataV2',
    async (args: I_METADATA_SETTER) => {
        try {
            const {data, message, error} = await COMPANY_API.updateCompanyMetaData(args)
            return {data, message, error}
        } catch (e) {
            return {data: {key: "", value: ""}}
        }
    }
);
export const companyMetaSlice = createSlice({
    name: 'companyMeta',
    initialState: {
        initialized: false,
        company_data: {
            has_audits: false,
            has_partners: false,
            has_processes: false,
            has_products: false,
            has_sites: false,
        },
        is_setup_done: '1', // data return by BE
        is_claim_dispute_required: '0'  // data return by BE
    },
    reducers: {
        setCompanyMeta: (state, action) => {
            Object.assign(state, action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyMetaAsync.fulfilled, (state, action) => {
                const {data, error} = action.payload
                if (!error) {
                    Object.assign(state, data)
                }
            })
            .addCase(getCompanyMetaV2Async.fulfilled, (state, action) => {
                if (action.payload.data) {
                    for (const meta of action.payload.data) {
                        state[meta.key] = meta.value
                    }
                }
                state.initialized = true;
            })
            .addCase(updateCompanyMetaV2Async.fulfilled, (state, action) => {
                if (action.payload.data) {
                    const {key, value} = action.payload.data
                    state[key] = value;
                }
            })
    },
});

export const {setCompanyMeta} = companyMetaSlice.actions;

export const companyMetaState = (state: RootState) => state.companyMeta;

export default companyMetaSlice.reducer;
