import React from 'react';
import {translateV2} from '@src/helpers';

import {useAppSelector} from "@redux/hooks/hooks";
import {configState} from "@redux/reducers/configSlice";

const CookiePolicy = ({handleAccept}: any) => {
    const config = useAppSelector(configState);
    console.log({config})
    return (
        <>
            <div className="modal-backdrop fade show"/>
            <div className="modal cookie-policy fade show">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title h2-title">{translateV2('LABEL.POLICY_COOKIE')}</h2>
                        </div>
                        <div className="modal-body">{translateV2('LABEL.POLICY_COOKIE_CONTENT')}</div>
                        <div className="modal-footer">
                            <button type="button" className="btn-green btn-flex"
                                    onClick={handleAccept}>{translateV2('LABEL.ACCEPT')}</button>
                            <a href={config.webapp_policy_url} target={"_blank"} rel={'noopener noreferrer'}
                               className="link">{translateV2('LABEL.READ_MORE')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CookiePolicy;
