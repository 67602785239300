import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPONENT_INTERACTION, PAGING_DATA, QUERIES} from "@src/types";
import {APP_ROUTES_NAME} from "@src/routes";
import {DEFAULT_MODAL, DEFAULT_VARIABLES} from "@src/variables";
import _ from "lodash-es";
import {SUBSCRIPTION_API} from "@api/Subscription";
import {MessageInterface} from "@redux/reducers/messageSlice";

const initialState: COMPONENT_INTERACTION = {
    name: APP_ROUTES_NAME.PROFILE,
    search: {
        ...DEFAULT_VARIABLES.SEARCH_SETTINGS,
        queries: {
            ...DEFAULT_VARIABLES.SEARCH_SETTINGS.queries,
            sort: 'asc',
            sort_by: 'name'
        }
    },
    loading: false,
    data: []
}

export const fetchAccountInvoicesAsync = createAsyncThunk(
    'accountInvoices/fetch',
    async (queries: QUERIES & { signal?: any }) => {
        try {
            const r = await SUBSCRIPTION_API.getInvoices(
                _.omitBy(queries, _.isNil), {}
            );

            if (r) {
                const {data, meta, error} = r;
                if (data && !error) {
                    return {data, meta, queries}
                }
            }
            return {data: [], meta: {}, queries}
        } catch (e) {
            console.error(e)
            return {data: [], meta: {}, queries}
        }
    }
);

export const accountInvoicesSlice = createSlice({
    name: 'accountInvoices',
    initialState,
    reducers: {
        setAccountInvoicesState: (state, action: PayloadAction<COMPONENT_INTERACTION>) => {
            Object.assign(state, action.payload)
        },
        setAccountInvoicesData: (state, action: PayloadAction<[]>) => {
            state.data = [...action.payload]
        },
        setAccountInvoicesQueries: (state, action: PayloadAction<QUERIES>) => {
            state.search.queries = {...action.payload}
        },
        setAccountInvoicesPaging: (state, action: PayloadAction<PAGING_DATA>) => {
            state.search.pagingData = {...action.payload}
        },
        setAccountInvoicesSingle: (state, action) => {
            const {itemIndex, product} = action.payload

            if (product && itemIndex >= 0 && itemIndex < state.data.length) {
                state.data[itemIndex] = {...state.data[itemIndex], ...product};
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountInvoicesAsync.fulfilled, (state, action) => {
                const {data, meta, queries} = action.payload
                const total_pages = Math.ceil(meta.total / queries.number_per_page)

                if (!_.size(data) && queries.page > 1) {
                    queries.page = (meta.current_page - 1);
                }
                /**
                 * @todo: check comment about asyncThunk
                 */
                _.unset(queries, 'signal')

                state.search.queries = {...queries}
                state.search.pagingData = {...meta, total_pages}
                state.data = [...data]
            })

        ;
    },
});

export const fetchAccountInvoiceDetailAsync = createAsyncThunk(
    'accountInvoices/fetchDetail',
    async ({code, signal}: any) => {
        try {
            const {
                data = {},
                error = ""
            } = await SUBSCRIPTION_API.getInvoiceDetail({code, signal})

            return {data, error}
        } catch (e) {
            console.error(e)
            return {data: {}, error: ""}
        }
    }
);

export const invoiceDetailModalSlice = createSlice({
    name: 'invoiceDetailModal',
    initialState: {
        ...DEFAULT_MODAL,
    },
    reducers: {
        showMessage: (state, action: PayloadAction<MessageInterface>) => {
            Object.assign(state, action.payload);
        },
        hideMessage: (state) => {
            state.isShow = false;
        }
    }
});
export const invoiceDetailSlice = createSlice({
    name: 'invoiceDetail',
    initialState: {},
    reducers: {
        reset: (state) => {
            state = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountInvoiceDetailAsync.fulfilled, (state, action) => {
                const {data, error} = action.payload
                Object.assign(state, data && !error ? data : {})
            })
        ;
    },
});

export const {
    setAccountInvoicesData,
    setAccountInvoicesQueries,
    setAccountInvoicesPaging,
    setAccountInvoicesState,
    setAccountInvoicesSingle
} = accountInvoicesSlice.actions;
export const accountInvoicesState = (state: RootState) => state.accountInvoices;
export const invoiceDetailState = (state: RootState) => state.invoiceDetail;
export const invoiceDetailModalState = (state: RootState) => state.invoiceDetailModal;
export default accountInvoicesSlice.reducer;
