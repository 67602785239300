import React, {ReactNode, useState} from 'react';
import {CookieService} from '@src/helpers';
import CookiePolicy from '@components/CookiePolicy/CookiePolicy';
import {AUTH} from "@api/Auth";
import {useAppDispatch} from "@redux/hooks/hooks";

interface ILangHOC {
    children: ReactNode,
    isRequireCookie?: Boolean
}

const LanguageHOC = ({children, isRequireCookie = false}: ILangHOC) => {
    const [acceptPolicy, setAcceptPolicy] = useState(Boolean(CookieService.get('fib-consent')));
    const closePolicy = () => {
        CookieService.set('fib-consent', 'accepted', 3650);
        setAcceptPolicy(true);
    }
    const dispatch = useAppDispatch();
    const defaultLang = AUTH.getLocalLang();

    return (
        <>
            {isRequireCookie && !acceptPolicy && <CookiePolicy handleAccept={closePolicy}/>}
            {children}
        </>
    )
}

export default LanguageHOC;
