import {AUTH} from "@api/Auth";
import AppProvider from "./context/AppProvider";
import {APP_ROUTES_PATH} from "@src/routes";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";

import {appRoutesState, getStaticRoutesAsync} from '@redux/reducers/appRoutesSlice';
import {ENV} from "../environments/environment";
import {Button, Modal} from "react-bootstrap";
import {ASSETS} from "@assets/Assets";
import {DEFAULT_APP_CONFIG, DEFAULT_MODAL} from "@src/variables";

import {userMetadataState, userState} from "@redux/reducers/userSlice";
import {companyClaimState, companyState} from "@redux/reducers/companySlice";
import {blockStore} from "@redux/reducers/blockSlice";
import {Route, Routes, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import React, {FunctionComponent, ReactElement, Suspense, useEffect, useState} from "react";
import {
  axiosRequest,
  blockPage,
  CookieService,
  createSearchQuery,
  fibLogger,
  getParams,
  logoutCleaner,
  navigateUrlStr,
  parseJwt,
  safelyParseJSON,
  translateV2
} from "@src/helpers";
import {CompanyTabs} from "@src/types";
import {userIntroManagerState} from "@redux/reducers/userIntroManagerSlice";
import {COMMON_API} from "@api/Common";
import _ from "lodash-es";
import {appProviderSlice, appProviderState} from "@redux/reducers/appProviderSlice";
import {AndroidInterfaceHelper} from "@api/AndroidInterfaceHelper";
import AuthProvider from "@context/AuthProvider";
import SocketProvider from "@context/SocketProvider";

import LoadingV2 from "@v2components/Loading/LoadingV2";
import LanguageHOC from "@components/HOCs/LanguageHOC/LanguageHOC";
import TemplatePrimaryHOC from "@components/HOCs/TemplatePrimaryHOC/TemplatePrimaryHOC";
import TemplateSecondaryHOC from "@components/HOCs/TemplateSecondaryHOC/TemplateSecondaryHOC";
import StaticPageHOC from "@components/HOCs/StaticPageHOC/StaticPageHOC";

const JoinRequestV2 = React.lazy(() => import("@v2pages/User/JoinRequestV2"))
const ImageLazyLoader = React.lazy(() => import ("@v2components/Loading/ImageLazyLoader"))
const GlobalModalManager = React.lazy(() => import ("@components/ModalMessage/GlobalModalManager"))
const ContactUsV2 = React.lazy(() => import ("@v2pages/ContactUs/ContactUsV2"))
const AuthenticateCallback = React.lazy(() => import ("@pages/shopify/AuthenticateCallback"))
const Account = React.lazy(() => import ("@pages/account/Account"))
const Activate = React.lazy(() => import ('@pages/activate/Activate'))
const AfterChangePass = React.lazy(() => import ('@pages/AfterChangePass/AfterChangePass'))
const AfterRequestAccount = React.lazy(() => import ('@pages/AfterRequestAccount/AfterRequestAccount'))
const ChangePasswordPage = React.lazy(() => import ('@pages/ChangePasswordPage/ChangePasswordPage'))
const Company = React.lazy(() => import ('@pages/company/Company'))
const FAQ = React.lazy(() => import ('@pages/FAQ/FAQ'))
const ForgotPassword = React.lazy(() => import ('@pages/forgot-password/ForgotPassword'))
const Maintenance = React.lazy(() => import ('@pages/Maintenance/Maintenance'))
const PageError = React.lazy(() => import ('@pages/page-error/PageError'))
const PageForbidden = React.lazy(() => import ('@pages/PageForbidden/PageForbidden'))
const PageNotFound = React.lazy(() => import ('@pages/page-not-found/PageNotFound'))
const PreviewStepDetail = React.lazy(() => import ('@pages/PreviewStepDetail/PreviewStepDetail'))
const StepDetail = React.lazy(() => import ('@pages/StepDetail/StepDetail'))
const TempLogin = React.lazy(() => import ('@pages/TempLogin/TempLogin'))
const PageLoader = React.lazy(() => import ('@components/PageLoader/PageLoader'))
const ProcessPreview = React.lazy(() => import ("@v2components/Company/CompanyProcessV2/Preview/ProcessPreview"))
const GuideContentV2 = React.lazy(() => import("@v2components/GuideContent/GuideContentV2"))
const ScrollToTop = React.lazy(() => import('@components/ScrollToTop/ScrollToTop'))
const Help = React.lazy(() => import("@v2pages/Static/Help"))
const LoginPage = React.lazy(() => import('@pages/login/Login'))
const DashboardV2 = React.lazy(() => import("@v2pages/Dashboard/DashboardV2"))
const ProductsV2 = React.lazy(() => import("@v2pages/Product/ProductsV2"))
const Orders = React.lazy(() => import("@pages/orders/Orders"))
const OrderDetailV2 = React.lazy(() => import("@v2pages/OrderDetail/OrderDetailV2"))
const MyAudits = React.lazy(() => import("@pages/MyAudits/MyAudits"))
const MyScanners = React.lazy(() => import("@pages/my-scanners/MyScanners"))
const AnalysisPage = React.lazy(() => import("@pages/Analysis/Analysis"))
const AnalysisDetailPage = React.lazy(() => import("@pages/AnalysisDetail/AnalysisDetail"))
const PageAuditTemplates = React.lazy(() => import("@pages/AuditTemplates/AuditTemplates"))
const PageAuditReceived = React.lazy(() => import("@pages/AuditReceived/AuditReceived"))
const PageAuditAccepted = React.lazy(() => import("@pages/AuditReceived/AuditAccepted"))
const PageAuditRejected = React.lazy(() => import("@pages/AuditReceived/AuditRejected"))
const PageNotification = React.lazy(() => import("@pages/Notification/PageNotification"))
const CompanyAdmin = React.lazy(() => import("@components/CompanyAdmin/CompanyAdmin"))

// import JoinRequestV2 from "@v2pages/User/JoinRequestV2";

interface RenderWithHOCsParams {
  isRequireCookie?: boolean;
  showRegister?: boolean;
  params?: any;
  className?: any;
  mainClass?: any;
  title?: string;
}

const renderLanguagePrimaryHOCs = (
  Component: FunctionComponent<any>,
  {
    isRequireCookie = false,
    showRegister = false,
    params = {},
    className = "",
    mainClass = "",
    title = ""
  }: RenderWithHOCsParams = {}
): ReactElement => {
  return (
    <LanguageHOC isRequireCookie={isRequireCookie}>
      <TemplatePrimaryHOC className={className} mainClass={mainClass}>
        <Component showRegister={showRegister} params={params} title={title}/>
      </TemplatePrimaryHOC>
    </LanguageHOC>
  );
}
const renderPrimaryHOCs = (
  Component: FunctionComponent<any>,
  {
    showRegister = false,
    params = {},
    className = "",
    mainClass = "",
    title = ""
  }: RenderWithHOCsParams = {}
): ReactElement => {
  return (
    <AuthProvider>
      <TemplatePrimaryHOC className={className} mainClass={mainClass}>
        <Component showRegister={showRegister} params={params} title={title}/>
      </TemplatePrimaryHOC>
    </AuthProvider>
  );
}
const renderLanguageHOCs = (
  Component: FunctionComponent<any>,
  {isRequireCookie = false, showRegister = false, params = {}, className = ""}: RenderWithHOCsParams = {}
): ReactElement => {
  return (
    <LanguageHOC isRequireCookie={isRequireCookie}>
      <Component showRegister={showRegister} params={params} className={className}/>
    </LanguageHOC>
  );
}

const renderSecondaryHOCs = (
  Component: FunctionComponent<any>,
  {isRequireCookie = false, showRegister = false, params = {}, className = ""}: RenderWithHOCsParams = {}
): ReactElement => {
  return (
    <LanguageHOC isRequireCookie={isRequireCookie}>
      <TemplateSecondaryHOC>
        <Component showRegister={showRegister} params={params} className={className}/>
      </TemplateSecondaryHOC>
    </LanguageHOC>
  );
}


const App = () => {

  const langData = useAppSelector(languageState);
  const blockState = useAppSelector(blockStore);
  const block = blockState.waiting;
  const company = useAppSelector(companyState);

  const userInfo = useAppSelector(userState);
  const userMetadata = useAppSelector(userMetadataState);
  const userIntroManager = useAppSelector(userIntroManagerState);

  const navigate = useNavigate();

  const [billingModal, setBillingModal] = useState({...DEFAULT_MODAL});
  const appRoutesStore = useAppSelector(appRoutesState)

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const {isLoggedIn} = useAppSelector(appProviderState)
  // const isLoggedIn = AUTH.isLogin();

  const params = getParams();
  const location = useLocation();

  /**
   * FIP-2472 Claim company modal
   */
  const claimCompany = useAppSelector(companyClaimState);

  useEffect(() => {
    const t = new AbortController();
    if (location.pathname.search('maintenance') === -1) {
      dispatch(getStaticRoutesAsync({lang: langData.lang, signal: t.signal}))
    }
    return () => {
      t.abort();
    }
  }, [dispatch, langData.lang])

  useEffect(() => {
    (async () => {
      try {
        let user = AUTH.getToken() ? parseJwt(AUTH.getToken()) : null;
        if (user) {
          if (ENV.LOG_ROCKET_ID && ENV.MODE === "production") {
            const LogRocket = (await import('logrocket')).default;

            fibLogger(`ENV.LOG_ROCKET_ID ${ENV.LOG_ROCKET_ID}`)
            LogRocket.init(ENV.LOG_ROCKET_ID);
            LogRocket.startNewSession();
            LogRocket.identify(user.email);
          }
        }
      } catch (e) {
        console.error(e)
      }
    })();
    return () => {

    }
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      let labelsMissing = CookieService.get('labelsMissing') || "[]";
      let labelsMissingSent = CookieService.get('labelsMissingSent') || "[]";

      const k = "labelsMissingSent";
      let labels = safelyParseJSON(labelsMissing);
      let labelsSent = safelyParseJSON(labelsMissingSent);
      let labelsDiff = _.difference(labels, labelsSent);
      let labelsCompare = _.difference(labelsDiff, _.keys(langData.localize));

      if (labelsDiff && labelsDiff.length > 0) {
        if (labelsCompare && labelsCompare.length > 0) {
          COMMON_API.reportMissingLabel({label: labelsDiff}).then(r => {
            CookieService.set(k, JSON.stringify([...labelsSent, ...labelsDiff]), 1)
          })
        }
      } else {
        /**
         * @todo: discuss with BE when FE stop sending APIs
         * clearInterval(interval);
         */
      }

    }, 15 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Suspense fallback={<LoadingV2 fixed={true}/>}>

        <AppProvider>

          <ScrollToTop/>

          {blockState.waiting && <PageLoader/>}

          <Routes>

            <Route path="/"
                   element={renderSecondaryHOCs(
                     LoginPage,
                     {isRequireCookie: true, params}
                   )}>
            </Route>

            <Route path={APP_ROUTES_PATH.LOGIN}
                   element={
                     renderSecondaryHOCs(
                       LoginPage,
                       {isRequireCookie: true}
                     )}>
            </Route>

            <Route path={APP_ROUTES_PATH.REGISTER}
                   element={renderSecondaryHOCs(
                     LoginPage,
                     {showRegister: true}
                   )}>
            </Route>

            <Route path="/activate/:code" element={renderLanguageHOCs(Activate)}/>
            <Route path="/recovery/:code" element={renderLanguageHOCs(Activate)}/>
            <Route path="/set-password/:code" element={renderLanguageHOCs(ChangePasswordPage)}/>

            <Route path={APP_ROUTES_PATH.THANK_YOU}
                   element={renderLanguageHOCs(AfterRequestAccount)}
            />

            <Route path={APP_ROUTES_PATH.CONTACT}
                   // element={renderPrimaryHOCs(ContactUsV2, {mainClass: "contact-page"})}

            />

            {/*private route*/}
            <Route path={APP_ROUTES_PATH.DASHBOARD}
                   element={renderPrimaryHOCs(DashboardV2, {
                     params, className: "bg-gray-v2"
                   })}
            />

            <Route path={APP_ROUTES_PATH.PRODUCTS}
                   element={renderPrimaryHOCs(ProductsV2, {
                     params, className: "page-products page-wrapper has-subheader"
                   })}
            />

            <Route path={APP_ROUTES_PATH.PROFILE}
                   element={renderPrimaryHOCs(Account, {
                     params, className: "page-accounts page-wrapper has-subheader"
                   })}
            />

            <Route path={APP_ROUTES_PATH.FORGOT_PASSWORD} element={renderLanguageHOCs(ForgotPassword)}/>

            <Route path="/process-preview/:code" element={renderLanguageHOCs(PreviewStepDetail)}/>

            <Route path={APP_ROUTES_PATH.COMPANY}
                   element={renderPrimaryHOCs(Company, {
                     params, className: "page-company page-wrapper has-subheader"
                   })}
            />

            <Route path={"/request-to-join/:uid?"}
                   element={renderPrimaryHOCs(JoinRequestV2, {
                     params, className: "page-company page-wrapper has-subheader"
                   })}
            />

            <Route path={APP_ROUTES_PATH.MY_AUDITS}
                   element={renderPrimaryHOCs(MyAudits, {
                     params, mainClass: "page-audits"
                   })}
            />

            {/* +Received, Accepted, Rejected Audits */}
            <Route path={APP_ROUTES_PATH.RECEIVED_AUDITS}
                   element={renderPrimaryHOCs(PageAuditReceived, {
                     params, mainClass: "page-audits"
                   })}
            />

            <Route path={APP_ROUTES_PATH.ACCEPTED_AUDITS}
                   element={renderPrimaryHOCs(PageAuditAccepted, {
                     params, mainClass: "page-audits"
                   })}
            />

            <Route path={APP_ROUTES_PATH.REJECTED_AUDITS}
                   element={renderPrimaryHOCs(PageAuditRejected, {
                     params, mainClass: "page-audits"
                   })}
            />

            <Route path={APP_ROUTES_PATH.MY_SCANNERS}
                   element={renderPrimaryHOCs(MyScanners)}
            />

            <Route path="/orders/:code/:position"
                   element={renderPrimaryHOCs(StepDetail)}
            />
            <Route path="/orders/:code"
                   element={renderPrimaryHOCs(OrderDetailV2)}
            />
            <Route path="/process/:id"
                   element={renderPrimaryHOCs(ProcessPreview,
                     {className: "page-products page-wrapper has-subheader"})}
            />

            <Route path={APP_ROUTES_PATH.ORDERS}
                   element={renderPrimaryHOCs(Orders,
                     {className: "page-orders page-wrapper has-subheader"})}
            />

            <Route path="/analysis/:code"
                   element={renderPrimaryHOCs(AnalysisDetailPage)}
            />

            <Route path="/partner/:uid/:action"
                   element={<LanguageHOC>
                     <TemplatePrimaryHOC
                       className="page-company page-wrapper has-subheader"><Company
                       tab={CompanyTabs.PARTNERS}/></TemplatePrimaryHOC>
                   </LanguageHOC>}
                   loader={({params}) => {
                     if (!isLoggedIn) {
                       navigate(APP_ROUTES_PATH.LOGIN)
                     }
                   }}
            />

            <Route path={APP_ROUTES_PATH.ANALYSIS}
                   element={renderPrimaryHOCs(AnalysisPage,
                     {className: "page-analysis page-wrapper has-subheader"})}
            />

            <Route path={APP_ROUTES_PATH.AUDIT_TEMPLATES}
                   element={renderPrimaryHOCs(PageAuditTemplates,
                     {className: "page-audit-templates"})}
            />

            <Route path={APP_ROUTES_PATH.NOTIFICATION}
                   element={renderPrimaryHOCs(PageNotification,
                     {className: "page-notification"})}
            />

            <Route path={APP_ROUTES_PATH.DEMO}
                   element={renderPrimaryHOCs(GuideContentV2,
                     {params, className: "page-products page-wrapper has-subheader"})}
            />

            <Route path={APP_ROUTES_PATH.SHOPIFY_AUTHENTICATE} element={<AuthenticateCallback/>}/>

            {/*private route*/}

            <Route path={APP_ROUTES_PATH.PAGE_ERROR} element={<PageError/>}/>
            <Route path={APP_ROUTES_PATH.MAINTENANCE} element={<Maintenance/>}/>
            <Route path={APP_ROUTES_PATH.AFTER_CHANGE_PASSWORD}
                   element={renderPrimaryHOCs(AfterChangePass,
                     {params, className: "after-change-pass"})}
            />
            <Route path={APP_ROUTES_PATH.FORBIDDEN} element={renderLanguageHOCs(PageForbidden)}/>

            <Route path={APP_ROUTES_PATH.HELP}
                   element={renderPrimaryHOCs(Help,
                     {params, title: "LABEL.HELP", mainClass: "static-page"})}
            />

            {
              appRoutesStore.routes.map((route: any, idx: number) => {
                switch (route.type) {
                  case 'fe-not-found':
                    return <Route path="*" key={`route-${idx}`}
                                  element={renderLanguageHOCs(PageNotFound)}/>
                  case 'faq':
                    return <Route path={`/${route.slug}`} key={`route-${idx}`}
                                  element={<TemplatePrimaryHOC mainClass="static-page">
                                    <FAQ data={route.content} title={route.title}/>
                                  </TemplatePrimaryHOC>}/>
                  default:
                    return <Route path={`/${route.slug}`} key={`route-${idx}`}
                                  element={<TemplatePrimaryHOC
                                    className="static-page custom-static-page">
                                    <StaticPageHOC content={route.content} title={route.title}/>
                                  </TemplatePrimaryHOC>}/>
                }
              })
            }
            <Route path="/temp_login/:code" element={<TempLogin/>}/>

            <Route path="*" element={renderLanguageHOCs(PageLoader)}/>

          </Routes>

          <GlobalModalManager/>

          {company.has_unpaid_bills && <Modal
              show={billingModal.isShow}
              backdrop={"static"}
              size={"lg"}>

              <Modal.Body>

                  <div className="text-center pt-5">
                      <ImageLazyLoader src={ASSETS.logo} alt={DEFAULT_APP_CONFIG.app_name}
                                       className={"mb-2"}/>
                      <p>{translateV2("LABEL.REGISTER.GUIDE.ACCOUNT")}</p>
                  </div>

                  <div className={"ft-modal-container"}>

                      <div className={"ft-modal-inner"}>

                          <p className="text-center">
                              <ImageLazyLoader src={ASSETS.IconEcommerceDollarSign} width={80}
                                               loading={"lazy"}/>
                          </p>

                          <p><b>{translateV2("LABEL.PAID_TO_USE_FIBRETRACE")}</b></p>

                          <p dangerouslySetInnerHTML={{
                            __html: translateV2("LABEL.FIBRETRACE_ADMINISTRATOR_PAY_BILLS", "", {
                              '0': `<a class="text-green" href="${APP_ROUTES_PATH.PROFILE}?t=PACKAGE">${translateV2("LABEL.PACKAGES")}</a>`,
                              '1': `<a class="text-green" href="${APP_ROUTES_PATH.PROFILE}?t=BILLING">${translateV2("LABEL.BILLS")}</a>`,
                            })
                          }}/>

                          <p dangerouslySetInnerHTML={{
                            __html: translateV2("LABEL.FIBRETRACE_CONTACT_ALTERNATIVE", "", {
                              '0': `<a class="text-green" href="${APP_ROUTES_PATH.CONTACT}">${translateV2("LABEL.CONTACT_US")}</a>`
                            })
                          }}/>


                          <div className={"d-flex justify-content-center"}>

                            {
                              userInfo.is_company_admin
                                ? <Button
                                  disabled={false}
                                  variant={"success"} onClick={() => {
                                  setBillingModal({...billingModal, isShow: false})
                                  navigate(navigateUrlStr({
                                    pathname: APP_ROUTES_PATH.PROFILE,
                                    search: createSearchQuery({
                                      t: 'BILLING'
                                    }),
                                  }))
                                }}
                                  style={{marginTop: "36px", width: "100%"}}
                                >{translateV2(userInfo.is_company_admin ? "LABEL.PAY_BILLS" : "LABEL.CONTACT_US")}</Button>
                                : <CompanyAdmin/>
                            }

                          </div>

                          <p className={"text-green text-center mt-3"}>
                              <strong className={"text-underline"}
                                      style={{cursor: "pointer"}}
                                      onClick={async () => {

                                        try {
                                          blockPage()
                                          const {message, status} = await axiosRequest('/logout', {
                                            method: 'GET',
                                            headers: {...AUTH.getHeader()},
                                          }, true, false);
                                          if (message && status) {

                                          }
                                        } catch (e) {

                                        } finally {
                                          dispatch(appProviderSlice.actions.setState({isLoggedIn: false}))
                                          navigate(APP_ROUTES_PATH.LOGIN)

                                          logoutCleaner();
                                          blockPage(false)
                                          AndroidInterfaceHelper.appLogout()
                                        }

                                      }}>{translateV2("LABEL.LOG_OUT")}</strong>
                          </p>

                      </div>

                  </div>


              </Modal.Body>
          </Modal>}

          <SocketProvider/>

        </AppProvider>

      </Suspense>

    </>
  );
}

export default App;
