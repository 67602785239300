import {AnyAction, createAsyncThunk, createSlice, PayloadAction, ThunkDispatch, unwrapResult} from '@reduxjs/toolkit';
import {RootState, store} from '@redux/hooks/store';
import {companySetupSlice} from "@redux/reducers/companySetupSlice";
import {Message} from "@src/types";
import {importPartnersSlice} from "@redux/reducers/importPartnersSlice";
import {wizardSlice} from "@redux/reducers/wizardSlice";
import {userIntroSlice} from "@redux/reducers/userIntroSlice";
import {auditConfirmPendingSlice, auditConfirmTriggerSlice} from "@redux/reducers/auditConfirmation";
import _ from "lodash-es";
import {importPartnersProgressSlice} from "@redux/reducers/importPartnersProgressSlice";
import {updateUserMetaAsync, updateUserV2Async} from "@redux/reducers/userSlice";
import {E_USER_METADATA} from "@src/types/metadata";

export interface I_INTRO_ITEM {
    order?: number
    isShow: boolean
}

export enum E_INTRO_QUEUE {
    COMPANY_SETUP = "COMPANY_SETUP",
    QUICKSTART_WIZARD = "QUICKSTART_WIZARD",
    PARTNER_IMPORT = "PARTNER_IMPORT",
    INTRO = "INTRO",
    AUDIT_CONFIRMATION = "AUDIT_CONFIRMATION"
}

export interface I_INTRO_MANAGER {
    company_billing: I_INTRO_ITEM
    company_claim: I_INTRO_ITEM
    company_setup: I_INTRO_ITEM
    quickstart_wizard: I_INTRO_ITEM
    partner_import: I_INTRO_ITEM
    intro: I_INTRO_ITEM
    audit_confirmation: I_INTRO_ITEM
    queue: E_INTRO_QUEUE[]
    queueOriginal: E_INTRO_QUEUE[]
    showing: E_INTRO_QUEUE | null | undefined

    [key: string]: any
}

/**
 * order starts from 90 so we can define custom hook
 */
const initialState: I_INTRO_MANAGER = {
    company_billing: {
        order: 90, isShow: false
    },
    company_claim: {
        order: 100, isShow: false
    },
    company_setup: {
        order: 200, isShow: false
    },
    quickstart_wizard: {
        order: 300, isShow: false
    },
    partner_import: {
        order: 400, isShow: false
    },
    intro: {
        order: 500, isShow: false
    },
    audit_confirmation: {
        order: 600, isShow: false
    },
    showing: null,
    showNext: false,
    queue: [],
    queueOriginal: []
}
export const userIntroManagerSlice = createSlice({
    name: 'introManager',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<I_INTRO_MANAGER | { I_INTRO_ITEM }>) => {
            _.merge(state, action.payload)
        },
        turnOff: (state, action: PayloadAction<I_INTRO_MANAGER>) => {
            state.company_billing.isShow = false
            state.company_claim.isShow = false
            state.company_setup.isShow = false
            state.quickstart_wizard.isShow = false
            state.partner_import.isShow = false
            state.intro.isShow = false
        },
        addQueue: (state, action: PayloadAction<E_INTRO_QUEUE>) => {
            /**
             * @todo:
             * consider to add more items to queue or just one
             * I will only allow to add only 1 by now
             */
            state.queue = [];
            state.queue.push(action.payload)

            state.queueOriginal = [];
            state.queueOriginal.push(action.payload)
        },
        deQueue: (state, action: PayloadAction<I_INTRO_MANAGER>) => {
            state.queue = [];
            state.queueOriginal = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(triggerModalAsync.fulfilled, (state, action) => {

                const {item, nextItems} = action.payload;

                state.company_billing.isShow = false
                state.company_claim.isShow = false
                state.company_setup.isShow = false
                state.quickstart_wizard.isShow = false
                state.partner_import.isShow = false
                state.intro.isShow = false
                state.showing = item;

                if (state[item.toLowerCase()]) {
                    state[item.toLowerCase()].isShow = true;
                }

                if (nextItems && nextItems.length > 0) {
                    state.queue = nextItems
                }

            })
            .addCase(triggerNextOrCloseModalAsync.fulfilled, (state, action) => {
                const {firstItem, queue, itemsQueue} = action.payload
                if (firstItem) {
                    state.showing = firstItem;
                }
                if (queue) {
                    state.queue = queue
                }
                if (itemsQueue) {
                    state.queueOriginal = itemsQueue;
                }
            })

        ;
    },
});

export const triggerModalAsync = createAsyncThunk(
    'introManager/triggerOne',
    async ({item, nextItems}: { item: E_INTRO_QUEUE, nextItems?: E_INTRO_QUEUE[] }, thunkAPI) => {

        switch (item) {
            case E_INTRO_QUEUE.COMPANY_SETUP:
                thunkAPI.dispatch(companySetupSlice.actions.showMessage({
                    isShow: true,
                    title: "",
                    message: "",
                    type: Message.NEUTRAL
                }))

                break;

            case E_INTRO_QUEUE.PARTNER_IMPORT:
                thunkAPI.dispatch(importPartnersSlice.actions.show())
                break;

            case E_INTRO_QUEUE.QUICKSTART_WIZARD:
                thunkAPI.dispatch(wizardSlice.actions.showMessage({
                    isShow: true,
                    title: "",
                    message: "",
                    type: Message.NEUTRAL
                }))
                break;

            case E_INTRO_QUEUE.INTRO:
                thunkAPI.dispatch(userIntroSlice.actions.show());
                break;

            case E_INTRO_QUEUE.AUDIT_CONFIRMATION:
                thunkAPI.dispatch(auditConfirmTriggerSlice.actions.start());
                break;
        }

        return {item, nextItems}
    }
);

export const triggerNextOrCloseModalAsync = createAsyncThunk(
    'introManager/triggerOneNext',
    async ({userIntroManager, userMetadata, itemsQueue}: triggerNextOrCloseModalV2Async, {dispatch, getState}) => {

        const currentShowing = userIntroManager.showing
        const currentQueue = !itemsQueue || _.isEmpty(itemsQueue) ? userIntroManager.queue : itemsQueue;
        const queueOriginal = userIntroManager.queueOriginal;

        hideModalByQueueItem({dispatch, userMetadata, userIntroManager,})

        const [firstItem, ...queue] = currentQueue;

        if (firstItem) {
            dispatch(triggerModalAsync({item: firstItem}))
        }

        return {firstItem, queue, itemsQueue}
    }
);

type userMetadata = {
    initialized: boolean,
    is_first_login: boolean,
    is_done_company_setup: boolean,
    is_done_import_partner: boolean,
    is_done_wizard_setup: boolean,
    is_done_intro: boolean,
    is_has_st_scanner: boolean,
}

interface triggerNextOrCloseModalV2Async {
    userIntroManager: I_INTRO_MANAGER,
    userMetadata: userMetadata,
    itemsQueue?: E_INTRO_QUEUE[]
}

export const hideModalByQueueItem = ({dispatch, userMetadata, userIntroManager}: {
    dispatch: ThunkDispatch<any, any, AnyAction>,
    userMetadata: userMetadata,
    userIntroManager: I_INTRO_MANAGER
}) => {
    const gStore = store.getState();
    let currentShowing: I_INTRO_MANAGER["showing"] = userIntroManager.showing;
    let previousShowing: E_INTRO_QUEUE;

    let steps = {
        [E_INTRO_QUEUE.COMPANY_SETUP]: () => {
            dispatch(companySetupSlice.actions.hideMessage())
        },
        [E_INTRO_QUEUE.PARTNER_IMPORT]: () => {
            dispatch(importPartnersSlice.actions.hide())
            dispatch(importPartnersProgressSlice.actions.reset())

        },
        [E_INTRO_QUEUE.QUICKSTART_WIZARD]: () => {
            dispatch(wizardSlice.actions.hideMessage())
        },
        [E_INTRO_QUEUE.INTRO]: () => {
            dispatch(userIntroSlice.actions.hide());
        },
        [E_INTRO_QUEUE.AUDIT_CONFIRMATION]: () => {
            dispatch(auditConfirmPendingSlice.actions.hide())
            dispatch(auditConfirmTriggerSlice.actions.stop());
        },
    }

    let updateUserState = {
        [E_INTRO_QUEUE.COMPANY_SETUP]: () => {
            if (!_.toInteger(userMetadata.is_done_company_setup)) dispatch(updateUserMetaAsync({
                object: "USER",
                key: E_USER_METADATA.IS_DONE_COMPANY_SETUP,
                value: "1"
            }))
        },
        [E_INTRO_QUEUE.PARTNER_IMPORT]: () => {
            if (!_.toInteger(userMetadata.is_done_import_partner)) dispatch(updateUserMetaAsync({
                object: "USER",
                key: E_USER_METADATA.IS_DONE_IMPORT_PARTNER,
                value: "1"
            }))
        },
        [E_INTRO_QUEUE.QUICKSTART_WIZARD]: () => {
            if (!_.toInteger(userMetadata.is_done_wizard_setup)) dispatch(updateUserMetaAsync({
                object: "USER",
                key: E_USER_METADATA.IS_DONE_WIZARD_SETUP,
                value: "1"
            }))
            dispatch(updateUserV2Async({first_login_and_first_user: true}))
        },
        [E_INTRO_QUEUE.INTRO]: () => {
            if (!_.toInteger(userMetadata.is_done_intro)) dispatch(updateUserMetaAsync({
                object: "USER",
                key: E_USER_METADATA.IS_DONE_INTRO,
                value: "1"
            })).then(unwrapResult)
                .then(() => {
                    if (_.toInteger(userMetadata.is_first_login)) dispatch(updateUserMetaAsync({
                        object: "USER",
                        key: E_USER_METADATA.IS_FIRST_LOGIN,
                        value: "0"
                    }))
                })
        },
        [E_INTRO_QUEUE.AUDIT_CONFIRMATION]: () => {

        },
    }

    if (currentShowing) {
        steps[currentShowing]();
        updateUserState[currentShowing]();
    }

    /**
     * this code logic : update user state after close next modal
     */
    if (_.indexOf(userIntroManager.queueOriginal, currentShowing) > 0) {
        // previousShowing = userIntroManager.queueOriginal[_.indexOf(userIntroManager.queueOriginal, currentShowing) - 1];
        // updateUserState[previousShowing]();
    }

    return false;
}

export const userIntroManagerState = (state: RootState) => state.userIntroManager;

export default userIntroManagerSlice.reducer;
