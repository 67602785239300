import {GuideMenuCollection, initialGuideMenuState} from "@redux/reducers/guideSlice";
import {
    E_INTRO_QUEUE,
    I_INTRO_MANAGER,
    triggerModalAsync,
    triggerNextOrCloseModalAsync
} from "@redux/reducers/userIntroManagerSlice";
import {UserInterface} from "@src/types";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {USER_API} from "@api/User";
import {setSelectedJobByValue} from "@redux/reducers/jobSlice";
import {DEFAULT_NAVIGATION} from "@src/variables";
import {AUTH} from "@api/Auth";
import {CookieService, fibLogger} from "@src/helpers";
import {AndroidInterfaceHelper} from "@api/AndroidInterfaceHelper";
import {RootState, store} from "@redux/hooks/store";
import _ from "lodash-es";
import {I_METADATA_GETTER, I_METADATA_ITEM_RESPONSE, I_METADATA_RESPONSE, I_METADATA_SETTER} from "@src/types/metadata";
import {getCompanyAsync} from "@redux/reducers/companySlice";
import {getCompanyMetaAsync, getCompanyMetaV2Async} from "@redux/reducers/companyMetaSlice";
import {appProviderSlice} from "@redux/reducers/appProviderSlice";
import {getTranslateAsync} from "@redux/reducers/languageSlice";

export interface UserIntroInterface {
    INTRO_DASHBOARD_DISABLED: boolean
}

export interface I_ModalSetup {

}

export interface UserUpdateInterface {
    uid?: any
    accept_marketing?: boolean | number | null
    brand?: string
    can_be_deleted?: boolean | number | null
    can_be_disabled?: boolean | number | null
    company?: string
    deleted?: boolean | number | null
    email?: string
    first_login_and_first_user?: boolean | number | null
    first_name?: string
    id?: number | null | string
    is_company_admin?: boolean | number | null | undefined
    job_role?: string | null
    job_role_id?: number | null
    lang?: string
    last_name?: string
    manufacturer?: string
    name?: null | any
    need_2_fa?: boolean
    phone?: string
    receive_notify?: number | boolean | null
    role?: string
    settings?: null | any | {
        guideMenu: GuideMenuCollection,
        intro: UserIntroInterface,
        header?: boolean,
        introManager?: I_INTRO_MANAGER
    }
    status?: number
    token?: null | string
    notification_emails?: object | null | any
    show_contact_details?: boolean | null
    can_be_edited_name?: boolean
}

export const initialUserState: UserInterface = {
    accept_marketing: false,
    brand: "",
    can_be_deleted: true,
    can_be_disabled: true,
    company: "",
    deleted: false,
    email: "",
    first_login_and_first_user: false,
    first_name: "",
    id: null,
    is_company_admin: undefined,
    job_role: "",
    job_role_id: null,
    lang: "",
    last_name: "",
    manufacturer: "",
    name: null,
    need_2_fa: false,
    phone: "",
    receive_notify: 1,
    role: "",
    status: 1,
    token: null,
    notification_emails: {},
    settings: {},
    show_contact_details: false,
    can_be_edited_name: true
}

export const getUserAsync = createAsyncThunk(
    'user/getUser',
    async (__, store) => {
        const {data} = await USER_API.getUser();

        if (!data) {
            return null;
        }

        const {is_company_admin, settings} = data;
        localStorage.setItem("administrator_enable", `${Number(is_company_admin)}`);

        if (data.job_role_id) {
            store.dispatch(setSelectedJobByValue({value: data.job_role_id}))
        }

        let userSettings = {}

        if (settings) {
            const {guideMenu, intro = {}, quick_navigation = {}, header} = settings;

            if (guideMenu) {
                _.forIn(guideMenu, (v: any, key) => {
                    localStorage.setItem(`guide_menu_${key}_visibility`, `${Number(v.show)}`);
                })
            } else {
                userSettings = {...userSettings, guideMenu: initialGuideMenuState}
            }

            if (!_.isEmpty(intro)) {
                _.forIn(guideMenu, (v: any, key) => {
                    localStorage.setItem(`${key}`, `${Number(v.show)}`);
                })
            } else {
                userSettings = {...userSettings, intro: {INTRO_DASHBOARD_DISABLED: false,}}
            }

            if (_.isEmpty(quick_navigation)) {
                userSettings = {...userSettings, quick_navigation: {...DEFAULT_NAVIGATION}}
            }
            if (!_.has(settings, 'header') || settings.header) {
                userSettings = {...userSettings, header: false}
            }
        } else {
            let quick_navigation = {...DEFAULT_NAVIGATION};
            if (!is_company_admin) {
                quick_navigation.profile.pin = true
                quick_navigation.company.pin = false
            }
            userSettings = {
                ...userSettings,
                intro: {INTRO_DASHBOARD_DISABLED: false,},
                guideMenu: initialGuideMenuState,
                quick_navigation: {...quick_navigation}
            }
        }

        if (!_.isEmpty(userSettings)) {
            store.dispatch(updateUserV2Async({
                settings: {
                    ...data.settings,
                    ...userSettings
                }
            }))
        }

        return data;
    }
);
export const updateUserAsync = createAsyncThunk(
    'user/updateProfile',
    async (user: object) => {
        const response = await USER_API.updateProfile({...user, token: await AUTH.getToken()})
        return response.data;
    }
);

export const updateUserV2Async = createAsyncThunk(
    'user/updateUserV2',
    async (data: UserUpdateInterface) => {
        try {
            const response = await USER_API.updateUserV2({...data})
            return response.data;
        } catch (e) {
            console.error(e)
            return []
        }
    }
);

export const userLogoutAsync = createAsyncThunk(
    'user/logout',
    async () => {
        try {
            AUTH.logout();
            AndroidInterfaceHelper.appLogout()

            return true;
        } catch (e) {
            AndroidInterfaceHelper.appLogout()
            console.error(e)
            sessionStorage.clear();
            localStorage.clear();
            CookieService.deleteAllCookies();

            return false;
        } finally {

        }
    }
);
export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setProfile: (state, action: PayloadAction<UserInterface>) => {
            Object.assign(state, action.payload)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserAsync.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
            })

            .addCase(updateUserAsync.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
            })
            .addCase(updateUserV2Async.fulfilled, (state, action: PayloadAction<UserUpdateInterface>) => {
                if (!_.isNil(action.payload)) for (const [key, value] of Object.entries(action.payload)) {
                    state[key] = value
                }
            })
        ;
    },
});


/**
 * User meta
 */
export const getUserMetaAsync = createAsyncThunk(
    'userMeta/getMetadata',
    async (args: I_METADATA_GETTER = {object: "USER"}) => {
        try {
            const {data, message, error} = await USER_API.getUserMetaData(args)
            return {data, message, error}
        } catch (e) {
            return {data: []}
        }
    }
);

export const updateUserMetaAsync = createAsyncThunk(
    'userMeta/updateMetadata',
    async (args: I_METADATA_SETTER) => {
        try {
            const {data, message, error} = await USER_API.updateUserMetaData(args)
            return {data, message, error}
        } catch (e) {
            return {data: {key: "", value: ""}}
        }
    }
);

export const loadInitialDataAsync = createAsyncThunk(
    'users/loadInitialData',
    async (__, {dispatch, getState, rejectWithValue}) => {
        try {

            const gStore = store.getState();

            const user = await dispatch(getUserAsync()).unwrap();
            dispatch(getTranslateAsync({code: user.lang, userLang: user.lang}));
            fibLogger({message: "load user info", user});

            //
            // example metadata:
            // {
            //     "data": [
            //     {
            //         "key": "is_first_login",
            //         "value": "0"
            //     },
            //     {
            //         "key": "is_done_company_setup",
            //         "value": "1"
            //     },
            //     {
            //         "key": "is_done_import_partner",
            //         "value": "1"
            //     },
            //     {
            //         "key": "is_done_wizard_setup",
            //         "value": "1"
            //     },
            //     {
            //         "key": "is_done_intro",
            //         "value": "1"
            //     },
            //     {
            //         "key": "is_has_st_scanner",
            //         "value": "1"
            //     }
            // ]
            // }

            dispatch(appProviderSlice.actions.setState({isLoggedIn: true}));
            fibLogger({message: "user logged in", isLoggedIn: true});

            const userMeta = await dispatch(getUserMetaAsync()).unwrap();
            const userMetadata = userMeta.data;

            /**
             * Backend does not return meta by default, so Frontend has to guess and set.
             * Must force backend to run cronjob to update metadata for all users
             */
            if (!_.find(userMetadata, (v) => v.key === "is_first_login")) {
                userMetadata.push({
                    key: "is_first_login",
                    value: "1"
                });
            }

            ["is_done_company_setup", "is_done_import_partner", "is_done_wizard_setup", "is_done_intro"].map((k) => {
                if (!_.find(userMetadata, (v) => v.key === k)) {
                    userMetadata.push({
                        key: k,
                        value: "0"
                    })
                }
            })

            fibLogger({message: "load user meta", userMetadata});

            const company = await dispatch(getCompanyAsync()).unwrap();
            fibLogger({message: "load company info", company});

            const companyMeta = await dispatch(getCompanyMetaAsync({fields: ["company_data"]})).unwrap();
            fibLogger({message: "load company meta", companyMeta});

            const mate = await dispatch(getCompanyMetaV2Async()).unwrap();
            const companyMetadata = _.mapValues(_.mapValues(_.keyBy(mate.data, 'key'), 'value'), _.toInteger)

            fibLogger({message: "load company meta for initialized", mate});

            let r = {user, userMetadata, company, companyMeta: companyMeta.data, companyMetadata: mate.data};

            const userMetadataIntegers = _.mapValues(_.mapValues(_.keyBy(userMetadata, 'key'), 'value'), _.toInteger)
            // console.log({userMetadataIntegers})
            // companyMetadata.is_claim_dispute_required = 1;
            if (_.toInteger(companyMetadata.is_claim_dispute_required)) {

            } else if ((userMetadataIntegers.is_first_login)) {

                let newItemsQueue: Array<E_INTRO_QUEUE> = [];

                if (user.first_login_and_first_user) {

                    if (!(userMetadataIntegers.is_done_company_setup)) {
                        newItemsQueue.push(E_INTRO_QUEUE.COMPANY_SETUP);
                    }

                    if (company.company_package_payment.import_sites && !(userMetadataIntegers.is_done_import_partner)) {
                        // newItemsQueue.push(E_INTRO_QUEUE.PARTNER_IMPORT);
                    }

                    if (!(userMetadataIntegers.is_done_wizard_setup)) {
                        newItemsQueue.push(E_INTRO_QUEUE.QUICKSTART_WIZARD);
                    }
                    if (!(userMetadataIntegers.is_done_intro)) {
                        newItemsQueue.push(E_INTRO_QUEUE.INTRO);
                    }
                } else {
                    if (user.is_company_admin) {
                        if (!(userMetadataIntegers.is_done_wizard_setup)) {
                            newItemsQueue.push(E_INTRO_QUEUE.QUICKSTART_WIZARD);
                        }
                        if (!(userMetadataIntegers.is_done_intro)) {
                            newItemsQueue.push(E_INTRO_QUEUE.INTRO);
                        }
                    } else {
                        if (!(userMetadataIntegers.is_done_intro)) {
                            if (location.pathname.search('dashboard') !== -1) {

                            }
                            dispatch(triggerModalAsync({
                                item: E_INTRO_QUEUE.INTRO
                            }))
                        }
                    }
                }

                if (!_.isEmpty(newItemsQueue)) {
                    dispatch(triggerNextOrCloseModalAsync({
                        userMetadata,
                        userIntroManager: gStore.userIntroManager,
                        itemsQueue: newItemsQueue
                    }))
                }
            }

            return r

        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

export const userMetaSlice = createSlice({
    name: 'userMeta',
    initialState: {
        initialized: false,
        is_first_login: false,
        is_done_company_setup: false,
        is_done_import_partner: false,
        is_done_wizard_setup: false,
        is_done_intro: false,
        is_has_st_scanner: false,
    },
    reducers: {
        initialized: (state, action) => {
            state.initialized = !!action.payload.initialized
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserMetaAsync.fulfilled, (state, action: PayloadAction<I_METADATA_RESPONSE>) => {
                if (action.payload.data) {
                    for (const meta of action.payload.data) {
                        state[meta.key] = meta.value
                    }

                    let isFirstLogin = _.find(action.payload.data, (item: any) => item.key === "is_first_login");
                    if (!isFirstLogin || !_.includes(['0', '1',], isFirstLogin?.value)) {
                        state.is_first_login = true;
                    }
                }
                state.initialized = true;
            })
            .addCase(updateUserMetaAsync.fulfilled, (state, action: PayloadAction<I_METADATA_ITEM_RESPONSE>) => {
                if (action.payload.data) {
                    const {key, value} = action.payload.data
                    state[key] = value;
                }
            })
        ;
    },
});


export const {setProfile} = userSlice.actions;

export const userState = (state: RootState) => state.user;
export const userMetadataState = (state: RootState) => state.userMetadata;

export default userSlice.reducer;
