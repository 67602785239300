import React, {useEffect, useRef, useState} from 'react';
import {axiosRequest, blockPage, CookieService, fibLogger, logoutCleaner, translateV2} from '@src/helpers'
import logo from '@assets/images/logo.png';
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AUTH} from "@api/Auth";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import NotificationQuickList from "@components/Notification/List/NotificationQuickList";
import _ from "lodash-es";
import {APP_ROUTES_PATH} from "@src/routes";
import {DEFAULT_APP_CONFIG, DEFAULT_MODAL, DEFAULT_NAVIGATION} from "@src/variables";
import {MENU_ITEM} from "@src/types";
import {Image, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ASSETS} from "@assets/Assets";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {companyState} from "@redux/reducers/companySlice";
import {notificationState} from "@redux/reducers/notificationSlice";
import {updateUserV2Async, userState} from "@redux/reducers/userSlice";
import {ENV} from "../../../environments/environment";
import {configState} from "@redux/reducers/configSlice";
import {headerState, hideHeader, toggleHeader} from "@redux/reducers/headerSlice";
import {AndroidInterfaceHelper} from "@api/AndroidInterfaceHelper";

import {appProviderSlice, appProviderState} from "@redux/reducers/appProviderSlice";
import HeaderPackage from "@components/Header/HeaderPackage";
import FooterPackage from "@components/Footer/FooterPackage";

const Header = ({isAdmin = false, className = ''}) => {
  const headerGlobalState = useAppSelector(headerState)
  const langData = useAppSelector(languageState);
  const companyInfo = useAppSelector(companyState);
  const userInfo = useAppSelector(userState);
  const notification = useAppSelector(notificationState);
  const config = useAppSelector(configState);
  const location = useLocation();
  const {isLoggedIn} = useAppSelector(appProviderState)

  let dispatch = useAppDispatch();
  const navigate = useNavigate();

  const header = headerGlobalState.show;
  const quickNav = userInfo?.settings?.quick_navigation || {...DEFAULT_NAVIGATION}
  const [notifyToggle, setNotifyToggle] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const notiRef = useRef(null);
  const notiButtonRef = useRef(null);

  const [messageModal, setMessageModal] = useState({...DEFAULT_MODAL});
  const scannerFunction = companyInfo.scanner_function_available;
  // const [scannerFunctionTooltip, setScannerFunctionTooltip] = useState(false);
  const isVerifiedPackage = _.includes(_.toUpper(companyInfo.current_subscription.name), "VERIFIED")

  const headerEle = document.getElementsByTagName("header")
  const footer = document.querySelector("footer");
  const main = document.querySelector("main");

  const toggleHeaderState = () => {
    dispatch(toggleHeader())
  }

  const toggleNotification = async (extra: any = {}) => {
    setNotifyToggle(!notifyToggle);
  }

  const signOut = async () => {
    try {
      AUTH.logout().then(() => {
        navigate(APP_ROUTES_PATH.LOGIN, {replace: true});
      })
      AndroidInterfaceHelper.appLogout()
    } catch (e) {
      AndroidInterfaceHelper.appLogout()
      console.error(e)
      sessionStorage.clear();
      localStorage.clear();
      CookieService.deleteAllCookies();
      navigate(APP_ROUTES_PATH.LOGIN, {replace: true});
    }
  }

  const handleScroll = () => {
    setSticky(window.scrollY > 0);
    if (headerEle[0]) {
      document.documentElement.style.setProperty('--header-height', `${headerEle[0].clientHeight || 70}px`);
    }
  };

  const handleCloseMenu = () => {
    if (window.innerWidth < 768) {
      dispatch(hideHeader())
    }
  };

  const handleCloseNotification = () => {
    if (window.innerWidth < 768) setNotifyToggle(false);
  };

  const handleClickOutsideMenu = (event) => {
    // fibLogger('handleClickOutsideMenu')
    const curr = menuRef?.current as any;
    const bcurr = buttonRef?.current as any;
    if ((curr && !curr.contains(event.target)) && (bcurr && !bcurr.contains(event.target))) {
      if (header) {
        dispatch(hideHeader())
      }
    }
  };

  const handleClickOutsideNotification = (event) => {
    const curr = notiRef?.current as any;
    const bcurr = notiButtonRef?.current as any;
    if ((curr && !curr.contains(event.target)) && (bcurr && !bcurr.contains(event.target))) {
      if (notifyToggle) {
        setNotifyToggle(false);
      }
    }
  };

  const updateUserSettings = async (event, route = "" as MENU_ITEM) => {
    try {
      event.preventDefault()
      let tq = _.cloneDeep(quickNav);
      if (_.has(tq, route)) {
        let stt = _.countBy(tq, (v) => v.pin)
        if (!tq[route].pin && stt.true >= 3) {
          setMessageModal({
            ...messageModal, ...{
              isShow: true,
              message: "LABEL.QUICK_NAVIGATION.PIN_MAX"
            }
          })
          return false;
        }

        tq[route].pin = !quickNav[route].pin;
        dispatch(updateUserV2Async({
          settings: {...userInfo.settings, quick_navigation: tq}
        }))
      }
    } catch (e) {
      fibLogger(e, 'error')
    }
  }

  const handleMessageModal = {
    open: () => {
      try {
        setMessageModal({...messageModal, isShow: true});
      } catch (e) {
        console.error(e)
      }
    },
    close: () => {
      setMessageModal({...DEFAULT_MODAL});
    }
  }

  const generateReportUrl = () => {
    const token = AUTH.getBearer().replace('Bearer ', '');
    return `${ENV.REPORT_URL}/?token=${token}`
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleCloseMenu);
    window.addEventListener('click', handleClickOutsideMenu);

    return () => {
      window.removeEventListener('resize', handleCloseMenu);
      window.removeEventListener('click', handleClickOutsideMenu);
    };
  }, [header]);

  useEffect(() => {
    window.addEventListener('resize', handleCloseNotification);
    window.addEventListener('click', handleClickOutsideNotification);

    return () => {
      window.removeEventListener('resize', handleCloseNotification);
      window.removeEventListener('click', handleClickOutsideNotification);
    };
  }, [notifyToggle]);

  useEffect(() => {
    dispatch(hideHeader())
  }, [location])

  return (
    <>
      <header className={`header-menu${isSticky ? ' fixed' : ''} ${className}`}>
        <nav className="navbar navbar-light">
          <div className="container navbar-container">

            <div className={"d-flex align-items-center fibre-packages-container"}>

              <Link replace={true} className={"navbar-brand"}
                    to={isLoggedIn ? APP_ROUTES_PATH.DASHBOARD : "/"}>
                <img className={"header-logo"} src={logo} alt={DEFAULT_APP_CONFIG.app_name}/>
              </Link>

              <HeaderPackage/>

            </div>

            {
              isLoggedIn &&
                <>
                    <div className={"d-flex justify-flex-end align-items-center"}>

                        <div>
                            <button ref={notiButtonRef}
                                    className={`navbar-notification-icon ${notification.hasNotification ? "has-notification" : ""}`}
                                    type="button"
                                    onClick={toggleNotification}>
                                <div>
                                    <FontAwesomeIcon icon={faEnvelope} style={{fontSize: "32px", color: "black"}}/>
                                  {notification.hasNotification && <span className={""}/>}
                                </div>
                            </button>

                            <div id={"notifications"} ref={notiRef}
                                 className={`collapse navbar-collapse navbar-menu-wrapper ${notifyToggle ? 'show' : ''}`}>
                                <NotificationQuickList/>
                            </div>
                        </div>

                        <div>
                            <button ref={buttonRef} className="navbar-toggler" type="button"
                                    onClick={toggleHeaderState}>
                                <div className={`animated-bars ${header ? 'open' : ''}`}>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                </div>
                            </button>
                        </div>

                    </div>

                    <div id={"mainMenu"} ref={menuRef}
                         className={`collapse navbar-collapse navbar-menu-wrapper ${header ? 'show' : ''}`}>
                        <ul className="navbar-nav text-uppercase">
                            <li className="nav-item">
                                <NavLink replace={true} className="nav-link nav-link-wpin"
                                         to={APP_ROUTES_PATH.PROFILE}>
                                  {translateV2("LABEL.ACCOUNT")}

                                    <Image
                                        src={!quickNav?.profile?.pin ? ASSETS.IconUnPinV2 : ASSETS.IconPinV2}
                                        loading={"lazy"} height={20} decoding={"async"}
                                        style={{}} className={""}
                                        onClick={(event) => updateUserSettings(event, MENU_ITEM.profile)}
                                    />

                                </NavLink>
                            </li>

                          {userInfo.is_company_admin &&
                              <li className="nav-item">
                                  <NavLink replace={true} className="nav-link nav-link-wpin"
                                           to="/company">
                                    {translateV2("LABEL.COMPANY")}
                                      <Image
                                          src={!quickNav?.company?.pin ? ASSETS.IconUnPinV2 : ASSETS.IconPinV2}
                                          loading={"lazy"} height={20} decoding={"async"}
                                          style={{}} className={""}
                                          onClick={(event) => updateUserSettings(event, MENU_ITEM.company)}
                                      />
                                  </NavLink>
                              </li>}

                            <li className="nav-item">
                                <NavLink replace={true} className="nav-link nav-link-wpin"
                                         to="/products">
                                  {translateV2("LABEL.PRODUCTS")}
                                    <Image
                                        src={!quickNav?.products?.pin ? ASSETS.IconUnPinV2 : ASSETS.IconPinV2}
                                        loading={"lazy"} height={20} decoding={"async"}
                                        style={{}} className={""}
                                        onClick={(event) => updateUserSettings(event, MENU_ITEM.products)}
                                    />
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink replace={true} className="nav-link nav-link-wpin"
                                         to="/orders">
                                  {translateV2("LABEL.ORDERS")}
                                    <Image
                                        src={!quickNav?.orders?.pin ? ASSETS.IconUnPinV2 : ASSETS.IconPinV2}
                                        loading={"lazy"} height={20} decoding={"async"}
                                        style={{}} className={""}
                                        onClick={(event) => updateUserSettings(event, MENU_ITEM.orders)}
                                    />
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink replace={true} className="nav-link nav-link-wpin"
                                         to="/my-audits">
                                  {translateV2("LABEL.AUDITS")}
                                    <Image
                                        src={!quickNav.audits.pin ? ASSETS.IconUnPinV2 : ASSETS.IconPinV2}
                                        loading={"lazy"} height={20} decoding={"async"}
                                        style={{}} className={""}
                                        onClick={(event) => updateUserSettings(event, MENU_ITEM.audits)}
                                    />
                                </NavLink>
                            </li>

                          {companyInfo.company_package_payment.fim && <li className="nav-item">
                              <NavLink replace={true} className="nav-link nav-link-wpin"
                                       to="/analysis">
                                {translateV2('LABEL.MY_ANALYSIS')}
                                  <Image
                                      src={!quickNav.analysis.pin ? ASSETS.IconUnPinV2 : ASSETS.IconPinV2}
                                      loading={"lazy"} height={20} decoding={"async"}
                                      style={{}} className={""}
                                      onClick={(event) => updateUserSettings(event, MENU_ITEM.analysis)}
                                  />
                              </NavLink>
                          </li>}

                          {
                            /**
                             * @todo: rewrite or find other 3rd party for tooltip
                             */
                            scannerFunction
                              ? <li className={`nav-item ${scannerFunction ? "  " : " is-locked "}`}>
                                <NavLink replace={true} className={`nav-link nav-link-wpin`}
                                         to={scannerFunction ? `/my-scanners` : "/profile?t=PACKAGE"}>
                                  {translateV2("LABEL.SCANNERS")}

                                  <Image
                                    src={
                                      scannerFunction
                                        ? !quickNav.scanners.pin ? ASSETS.IconUnPinV2 : ASSETS.IconPinV2
                                        : ASSETS.IconLockGray
                                    }
                                    loading={"lazy"} height={scannerFunction ? 20 : 26}
                                    decoding={"async"}
                                    style={{}} className={""}
                                    onClick={async (event) => {
                                      if (scannerFunction) await updateUserSettings(event, MENU_ITEM.scanners)
                                    }}
                                  />
                                </NavLink>
                              </li>
                              : <OverlayTrigger
                                overlay={<Tooltip
                                  id={`header-scanner-nav`}>
                                  {translateV2("LABEL.UNLOCK_VERIFIED")}</Tooltip>}>
                                <li className={`nav-item ${scannerFunction ? "  " : " is-locked "}`}>
                                  <NavLink replace={true} className={`nav-link nav-link-wpin`}
                                           to={scannerFunction ? `/my-scanners` : "/profile?t=PACKAGE"}>
                                    {translateV2("LABEL.SCANNERS")}

                                    <Image
                                      src={
                                        scannerFunction
                                          ? !quickNav.scanners.pin ? ASSETS.IconUnPinV2 : ASSETS.IconPinV2
                                          : ASSETS.IconLockGray
                                      }
                                      loading={"lazy"} height={scannerFunction ? 20 : 26}
                                      decoding={"async"}
                                      style={{}} className={""}
                                      onClick={async (event) => {
                                        if (scannerFunction) await updateUserSettings(event, MENU_ITEM.scanners)
                                      }}
                                    />
                                  </NavLink>
                                </li>
                              </OverlayTrigger>
                          }

                          {
                            companyInfo.company_package_payment.reports ?
                              <li className={`nav-item `}>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={generateReportUrl()}
                                  className="nav-link nav-link-wpin"
                                >
                                  {translateV2("LABEL.NAV_REPORTS")}
                                  <Image
                                    src=""
                                    loading="lazy"
                                    height={20}
                                    decoding="async"
                                    style={{}}
                                    className=""
                                  />
                                </a>
                              </li>
                              :
                              <OverlayTrigger
                                overlay={<Tooltip
                                  id={`header-scanner-nav`}>
                                  {translateV2("LABEL.UNLOCK_VERIFIED_REPORTS")}</Tooltip>}>
                                <li className={`nav-item  is-locked`}>
                                                    <span className="nav-link nav-link-wpin disabled">
                                                        {translateV2("LABEL.NAV_REPORTS")}
                                                      <Image src={ASSETS.IconLockGray} loading="lazy" height={26}
                                                             decoding="async"/>
                                                    </span>
                                </li>
                              </OverlayTrigger>
                          }

                            <li className="nav-item">
                                <NavLink replace={true} className="nav-link"
                                         to="/contact">
                                  {translateV2("LABEL.CONTACT_US")}
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <a target='_blank' rel={"noopener noreferrer"}
                                   href={config.zendesk_help_url}
                                   className="nav-link nav-link-wpin">{translateV2("LABEL.HELP")}</a>
                            </li>

                            <li className="nav-item nav-item--logout"
                                onClick={async () => {
                                  try {
                                    blockPage()
                                    const {message, status} = await axiosRequest('/logout', {
                                      method: 'GET',
                                      headers: {...AUTH.getHeader()},
                                    }, true, false);
                                    if (message && status) {
                                      dispatch(appProviderSlice.actions.setState({isLoggedIn: false}))
                                      navigate(APP_ROUTES_PATH.LOGIN, {replace: true});
                                    }
                                  } catch (e) {
                                    console.error(e)
                                  } finally {
                                    logoutCleaner();
                                    blockPage(false)
                                    AndroidInterfaceHelper.appLogout()
                                    dispatch(appProviderSlice.actions.setState({isLoggedIn: false}))
                                    console.log("finally")
                                  }

                                }}>
                                        <span
                                            className="nav-link text-nowrap">{translateV2("LABEL.LOG_OUT")}</span>
                            </li>

                        <li className={"d-md-flex d-lg-none"}>
                          <FooterPackage className={"d-md-flex d-lg-none"}/>
                        </li>

                      </ul>
                    </div>

                </>

            }

            {isLoggedIn && <div className="quick-navigation-container">
              <div className="quick-navigation">

                  {
                    _.map(_.keys(quickNav), (v: any, index: number) =>
                      quickNav[v].pin && <Link replace={true} key={index}
                                               className={location.pathname === quickNav[v].path ? " active " : ""}
                                               to={quickNav[v].path}>{translateV2(quickNav[v].label)}</Link>
                    )
                  }
                </div>
            </div>}

          </div>
        </nav>


      </header>

      <Modal show={messageModal.isShow}
             onHide={handleMessageModal.close} size={"lg"}>

        <Modal.Body>

          <Image src={ASSETS.IconCloseV2} loading={"lazy"} height={20} decoding={"async"}
                 style={{marginBottom: "0.5em"}} className={"modal-fib-close"}
                 onClick={handleMessageModal.close}
          />

          <p style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            marginBottom: 0,
            paddingRight: "50px"
          }}>{translateV2(messageModal.message)}</p>

        </Modal.Body>
      </Modal>

    </>
  );
}

export default Header;


