import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPONENT_INTERACTION, QUERIES} from "@src/types";
import {APP_ROUTES_NAME} from "@src/routes";
import {DEFAULT_VARIABLES} from "@src/variables";
import _ from "lodash-es";
import {SCANNER_API} from "@api/Scanner";

const initialState: COMPONENT_INTERACTION = {
    name: APP_ROUTES_NAME.MY_SCANNERS,
    search: {
        ...DEFAULT_VARIABLES.SEARCH_SETTINGS,
        queries: {
            ...DEFAULT_VARIABLES.SEARCH_SETTINGS.queries,
            sort: 'asc',
            sort_by: 'name'
        }
    },
    loading: false,
    data: []
}

export const fetchScannersAsync = createAsyncThunk(
    'scanner/fetch',
    async (_args: QUERIES & { signal?: any }) => {
        const {signal, ...queries} = _args;
        try {
            const r = await SCANNER_API.getScannersV2(_args);

            if (r) {
                const {data, meta, error} = r;
                if (data && !error) {
                    return {data, meta, queries}
                }
            }

            return {data: [], meta: {}, queries}
        } catch (e) {
            console.error(e)
            return {data: [], meta: {}, queries}
        }
    }
);

export const scannerSlice = createSlice({
    name: 'scanner',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchScannersAsync.pending, (state, action) => {
                state.loading = true
            })
            .addCase(fetchScannersAsync.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(fetchScannersAsync.fulfilled, (state, action) => {
                const {data, meta, queries} = action.payload
                const total_pages = Math.ceil(meta.total / queries.number_per_page)

                if (!_.size(data) && queries.page > 1) {
                    queries.page = (meta.current_page - 1);
                }
                state.data = data
                state.search.queries = _.merge(state.search.queries, queries)
                state.search.pagingData = {...meta, total_pages}
                state.loading = false;
            })

        ;
    },
});

export const {} = scannerSlice.actions;

export const scannerState = (state: RootState) => state.scanner;

export default scannerSlice.reducer;
