import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";

export const LANGUAGE_API = {

    getListLang: async (data: any = {}) => {
        const {signal} = data;
        return await axiosRequest('/languages', _.omitBy({method: 'GET', signal}, _.isNil), false);
    },

    getTranslate: (lang: string) => {
        return axiosRequest(`/translations${lang ? `?lang=${lang}` : ''}`, {
            method: 'GET'
        }, false);
    },

    setLangServer: (data: any) => {
        return axiosRequest('/set-language', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {...AUTH.getHeader()},
        });
    },

}

