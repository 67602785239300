import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';

// {
//     serviceUuid: '',
//     characteristicUuid: '',
//     deviceName: '',
//     bluetoothDevice: undefined as any,
//     bluetoothCharacteristic: undefined as any,
//     bluetoothSendCharacteristic: undefined as any,
//     isConnected: false,
//     message: '',
//     expected: '',
//     detected: '',
// }

export interface SCANNER_DEVICE {
    serviceUuid: string | any,
    characteristicUuid: string | any,
    deviceName: string | any,
    bluetoothDevice: any,
    bluetoothCharacteristic: any,
    bluetoothSendCharacteristic: any,
    isConnected: boolean | any,
    message: string | any,
    expected: string | any,
    detected: string | any,
}

const initialState: SCANNER_DEVICE = {
    serviceUuid: '',
    characteristicUuid: '',
    deviceName: '',
    bluetoothDevice: undefined as any,
    bluetoothCharacteristic: undefined as any,
    bluetoothSendCharacteristic: undefined as any,
    isConnected: false,
    message: '',
    expected: '',
    detected: '',
}

export const device = createSlice({
    name: 'device',
    initialState,
    reducers: {
        setDevice: (state, action: PayloadAction<SCANNER_DEVICE>) => {
            state = {...action.payload}
            return state
        },
    },
});

export const {
    setDevice
} = device.actions;

export const deviceState = (state: RootState) => state.device;

export default device.reducer;
