import {axiosRequest, axiosRequestAlt, fibLogger} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";
import {store} from "@redux/hooks/store";
import {updateUserV2Async} from "@redux/reducers/userSlice";
import {I_METADATA_GETTER, I_METADATA_SETTER} from "@src/types/metadata";

export const USER_API = {

    setPass: (data: any, fipt: string) => {
        return axiosRequest('/set-password', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {...AUTH.getHeaderWithAuth(fipt)},
        }, false);
    },
    changePass: (data: any) => {
        return axiosRequest('/change-password', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {...AUTH.getHeader()},
        });
    },
    forgotPass: ({email, activation_code}: any) => {
        return axiosRequest('/forgot-password', {
            method: 'POST',
            body: JSON.stringify({email, activation_code})
        }, false);
    },
    requestAccount: (data: any) => {
        return axiosRequest('/request-account', {
            method: 'POST',
            body: JSON.stringify(data)
        }, false);
    },
    registerAccount: (data: any) => {
        return axiosRequest('/register', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }, false, false);
    },
    getUser: async () => {

        const res = await axiosRequest('/user', {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        });
        if (res) {
            const {data} = res;
            if (data) {
                /**
                 * Do stuffs later
                 */
            }
        }
        return res;
    },
    configNotify: (data: { receive_notify: number }) => {
        return axiosRequest('/toggle-receive-notify', {
            method: 'POST',
            headers: {...AUTH.getHeader()},
            body: JSON.stringify(data)
        });
    },

    verifyTwoFactor: (data: { transaction: string, token: string }) => {
        return axiosRequest('/check-2fa-token', {
            method: 'POST',
            body: JSON.stringify(data)
        }, false, false);
    },
    sendTwoFactor: (data: { transaction: string }) => {
        return axiosRequest('/resend-2fa-token', {
            method: 'POST',
            body: JSON.stringify(data)
        }, false);
    },
    updateProfile: (data: object = {}) => {
        return axiosRequest('/user', {
            method: 'PUT',
            headers: {...AUTH.getHeader()},
            data,
        }, true);
    },
    getUsers: async (data: any = {}, extra: any = {}) => {
        const {signal, ...params} = data;
        let options = {
            method: 'GET',
            params, signal,
            headers: {...AUTH.getHeader()}
        } as any;
        if (!_.isEmpty(extra)) options.cancelToken = extra
        const res = await axiosRequest('/users', options);

        if (res) {
            const {data} = res;
            if (data) {
                /**
                 * Do stuffs later
                 */
            }
        }
        return res;
    },
    setUserAdmin: async ({id}: { id: any }) => {

        const res = axiosRequest(`/users/${id}/admin`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        });
        if (res) {
            const {data} = res;
            if (data) {
                /**
                 * Do stuffs later
                 */
            }
        }
        return res;
    },
    revokeUserAdmin: async ({id}: { id: any }) => {

        const res = axiosRequest(`/users/${id}/admin/revoke`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        });
        if (res) {
            const {data} = res;
            if (data) {
                /**
                 * Do stuffs later
                 */
            }
        }
        return res;
    },
    deleteUser: async ({id}: { id: any }) => {

        const res = await axiosRequest(`/users/${id}`, {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
        });
        if (res) {
            const {data} = res;
            if (data) {
                /**
                 * Do stuffs later
                 */
            }
        }
        return res;
    },
    setUserStatus: async ({id}: { id: any }) => {

        const res = await axiosRequest(`/users/${id}/status`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        });
        if (res) {
            const {data} = res;
            if (data) {
                /**
                 * Do stuffs later
                 */
            }
        }
        return res;
    },
    restoreUser: async ({id}: { id: any }) => {

        const res = await axiosRequest(`/users/${id}/restore`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        });
        if (res) {
            const {data} = res;
            if (data) {
                /**
                 * Do stuffs later
                 */
            }
        }
        return res;
    },
    registerCheck: (data: any) => {
        return axiosRequest('/register/check', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }, false);
    },
    getJobRoles: async () => {

        const res = await axiosRequest('/job-roles', {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, true, false);

        return res
    },

    deactivateAccount: async () => {
        const res = await axiosRequest('/account/deactivate', {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        }, true, false);

        return res;
    },

    getPendingColleagues: (data: any = {}) => {
        const { signal, ...params } = data;
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params, signal,
        } as any;
        return axiosRequest(`/pending-colleague-invitations`, options, true, false);
    },

    resendPendingColleague: ({uid}) => {
        return axiosRequest(`/pending-colleague-invitations/${uid}/resend`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    deletePendingColleague: ({uid}) => {
        return axiosRequest(`/pending-colleague-invitations/${uid}/delete`, {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },
    requestToJoin: async (data = {}) => {
        const res = await axiosRequest('/request-to-join', {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, true, false);

        return res;
    },

    acceptRequest: async ({id}) => {
        const res = await axiosRequest(`/request-to-join/${id}/accept`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        }, true, false);

        return res;
    },

    rejectRequest: async ({id}) => {
        const res = await axiosRequest(`/request-to-join/${id}/reject`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        }, true, false);

        return res;
    },

    getRequests: async (data: any = {}, extra: any = {}) => {
        const {signal, ...params} = data;
        const res = await axiosRequest(`/request-to-join`, {
            method: 'GET',
            params, signal,
            headers: {...AUTH.getHeader()},
        }, true, false);

        return res;
    },

    getRequestDetail: async ({id}) => {
        const res = await axiosRequest(`/request-to-join/${id}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, true, false);
        return res;
    },

    updateUserV2: (data: Object = {}) => {
        return axiosRequestAlt('/user', {
            method: 'PUT',
            data,
            headers: {...AUTH.getHeader()},
        }, false);
    },

    getUserMeta: (data: { fields: Array<string> }) => {
        return axiosRequest('/user-meta', {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, false);
    },

    isFirstLoginFirstUser: async () => {
        try {
            const gStore = store.getState();

            let user;

            if (gStore.user) user = gStore.user;
            else {
                const {data, error} = await USER_API.getUser();
                if (!error) user = data;
            }

            if (user) {
                const {first_login_and_first_user} = user;
                return first_login_and_first_user;
            }

            return false
        } catch (e) {
            console.error("checkFirstRootLogin", e.message)
            return false
        }
    },

    checkUpdateVirginLogin: async () => {
        try {
            const gStore = store.getState();
            let user;

            if (gStore.user) user = gStore.user;
            else {
                const {data, error} = await USER_API.getUser();
                if (!error) user = data;
            }

            if (user) {
                const {first_login_and_first_user} = user;
                if (!first_login_and_first_user) {
                    fibLogger("first_login_and_first_user is false.")
                    return false
                }
                store.dispatch(updateUserV2Async({first_login_and_first_user: false}))
                fibLogger("first_login_and_first_user updated.")
                return false;
            }
            return "who are you?"
        } catch (e) {
            console.error("checkFirstRootLogin", e.message)
            return e.message
        }
    },

    // first_login_and_first_user
    verifyEmail: async (data: any = {}) => {
        return await axiosRequest('/verify-email', {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, true, false);

    },

    getUserMetaData: (data: I_METADATA_GETTER = {object: "USER"}) => {
        return axiosRequest('/get-metadata', {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, false);
    },

    updateUserMetaData: (data: I_METADATA_SETTER) => {
        return axiosRequest('/update-metadata', {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, false);
    },

}
