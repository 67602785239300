import _ from "lodash-es";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {translateV2} from "@src/helpers";
import React from "react";

const ProcessInputProductNames = ({inputProductNames}: any) => {
    if (!_.isArray(inputProductNames)) return null;

    let groups = _.chunk(inputProductNames, 3)


    return (
        <>
            <p className={"fz-10 lh-13 mb-0 mt-1"}>
                {groups[0] ? groups[0].join(", ") : null}

                {
                    _.size(inputProductNames) - _.size(groups[0]) > 0
                        ? <>
                            &nbsp;&&nbsp;
                            <OverlayTrigger
                                overlay={
                                    <Popover id={_.uniqueId(`popover-positioned-`)} bsPrefix={'popover fibre-popover'}>
                                        <Popover.Title
                                            as="h4">{translateV2("LABEL.ORDER_PROCESS.PRODUCT_MATERIALS")}</Popover.Title>
                                        <Popover.Content>
                                            <p className={"mb-0"}>{inputProductNames.join(", ")}</p>
                                        </Popover.Content>
                                    </Popover>}>

                    <span className={"text-somemore text-underline"} dangerouslySetInnerHTML={{
                        __html:
                            translateV2("LABEL.ORDER_PROCESS.SOME_MORE", "", {
                                total: _.size(inputProductNames) - _.size(groups[0]),
                            })
                    }}></span>
                            </OverlayTrigger>
                        </>
                        : null
                }


            </p>
        </>
    )
}
export default ProcessInputProductNames;