import React from 'react';
import {ITabs} from '@src/types';
import {translateV2} from '@src/helpers';
import {useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";

const Tabs = ({
                  selected,
                  click,
                  data,
                  cssClass = "navbar-nav nav nav-pills fip-nav justify-content-center text-center",
                  style = {}
              }: any) => {
    const langData = useAppSelector(languageState);

    return (
        <ul className={cssClass} style={style}>
            {
                data.map((tabs: ITabs) =>
                    <li className="nav-item" key={tabs.value} onClick={() => click(tabs.value)}>
                        <span
                            className={`nav-link ${selected === tabs.value ? ' active' : ''}`}>{translateV2( tabs.display)}</span>
                    </li>
                )
            }
        </ul>
    );
}

export default Tabs;
