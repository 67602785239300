import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {Message} from "@src/types";
import {DEFAULT_MODAL} from "@src/variables";
import {ButtonVariant} from "react-bootstrap/types";

export interface MessageInterface {
    isShow: boolean
    title: string | any
    message: string | any
    messageData?: any
    type: Message
    size?: "sm" | "lg" | "xl",
    style?: {},
    centered?: boolean
    cancelButton?: {
        visible: true,
        variant: ButtonVariant,
        label: "Close"
    },
}

const initialState: MessageInterface = {
    ...DEFAULT_MODAL, size: "lg", cancelButton: {
        visible: true,
        variant: "outline-dark",
        label: "Close"
    },
}

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        showMessage: (state, action: PayloadAction<MessageInterface>) => {
            Object.assign(state, action.payload);
        },
        hideMessage: (state) => {
            state.isShow = false;
        }
    },
});

export const {showMessage, hideMessage} = messageSlice.actions;

export const messageState = (state: RootState) => state.message;

export default messageSlice.reducer;
