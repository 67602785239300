import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {Message} from "@src/types";
import {DEFAULT_MODAL} from "@src/variables";
import {ButtonVariant} from "react-bootstrap/types";

interface I_AuditConfirmation {
    isShow: boolean
    title: string | any
    message: string | any
    messageData?: any
    type: Message
    size?: "sm" | "lg" | "xl",
    style?: {},
    centered?: boolean
    cancelButton?: {
        visible: true,
        variant: ButtonVariant,
        label: "Close"
    },
}

const initialState: I_AuditConfirmation = {
    ...DEFAULT_MODAL, size: "lg", cancelButton: {
        visible: true,
        variant: "outline-dark",
        label: "Close"
    },
}

export const auditConfirmTriggerSlice = createSlice({
    name: 'auditConfirmTrigger',
    initialState: false,
    reducers: {
        start: (state) => {
            return true
        },
        stop: (state) => {
            return false
        }
    },
});
export const auditConfirmPendingSlice = createSlice({
    name: 'auditConfirmPending',
    initialState,
    reducers: {
        show: (state, action: PayloadAction<I_AuditConfirmation>) => {
            Object.assign(state, action.payload);
        },
        hide: (state) => {
            Object.assign(state, initialState);
        }
    },
});

export const auditConfirmAcceptSlice = createSlice({
    name: 'auditConfirmAccept',
    initialState,
    reducers: {
        show: (state, action: PayloadAction<I_AuditConfirmation>) => {
            Object.assign(state, action.payload);
        },
        hide: (state) => {
            Object.assign(state, initialState);
        }
    },
});

export const auditConfirmRejectSlice = createSlice({
    name: 'auditConfirmReject',
    initialState,
    reducers: {
        show: (state, action: PayloadAction<I_AuditConfirmation>) => {
            Object.assign(state, action.payload);
        },
        hide: (state) => {
            Object.assign(state, initialState);
        }
    },
});

export const auditConfirmTriggerState = (state: RootState) => state.auditConfirmTrigger;
export const auditConfirmPendingState = (state: RootState) => state.auditConfirmPending;
export const auditConfirmAcceptState = (state: RootState) => state.auditConfirmAccept;
export const auditConfirmRejectState = (state: RootState) => state.auditConfirmReject;

export default auditConfirmPendingSlice.reducer;
