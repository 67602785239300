import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPANY_API} from "@api/Company";
import {FibSelectInterface, QUERIES} from "@src/types";
import _ from "lodash-es";
import {DEFAULT_SELECT} from "@src/variables";

const initialState: {
    publicCompanies: FibSelectInterface
} = {
    publicCompanies: DEFAULT_SELECT
}

export const fetchPublicCompaniesAsync = createAsyncThunk(
    'companyData/fetchPublicCompanies',
    async (queries: QUERIES & { [key: string]: any }) => {
        try {
            const r = await COMPANY_API.getPublicCompanies(_.omitBy(queries, _.isNil));
            if (r) {
                const {data, error} = r;
                if (data && !error) {
                    return {data}
                }
            }
            return {data: []}
        } catch (e) {
            console.error(e)
            return {data: []}
        }
    }
);

/**
 * stored some extra data related to companies, such as:
 * - companies list
 *
 */
export const companyDataSlice = createSlice({
    name: 'companyData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPublicCompaniesAsync.pending, (state, action) => {
                state.publicCompanies.isLoading = true
                state.publicCompanies.isDisabled = true
            })
            .addCase(fetchPublicCompaniesAsync.rejected, (state, action) => {
                state.publicCompanies.isLoading = false
                state.publicCompanies.isDisabled = false

            })
            .addCase(fetchPublicCompaniesAsync.fulfilled, (state, action) => {
                state.publicCompanies.isLoading = false
                state.publicCompanies.isDisabled = false
                state.publicCompanies.list = action.payload.data

            })
    },
});

export const {} = companyDataSlice.actions;

export const companyDataState = (state: RootState) => state.companyData;

export default companyDataSlice.reducer;
