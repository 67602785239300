import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {PROCESS_AUDIT} from "@src/types";
import _ from "lodash-es";
import {DEFAULT_MODAL} from "@src/variables";

export enum E_AuditClaimDispute {
    CLAIM = "CLAIMED",
    DISPUTE = "DISPUTE"
}

const initialState = {
    loading: false,
    action: "" as "" | E_AuditClaimDispute,
    stage: [] as Array<PROCESS_AUDIT & { checked?: boolean }>,
    data: [] as Array<PROCESS_AUDIT & { checked?: boolean }>
}

export const auditClaimDisputeModalSlice = createSlice({
    name: 'auditClaimDisputeModal',
    initialState: {...DEFAULT_MODAL},
    reducers: {
        show: (state, action) => {
            Object.assign(state, action.payload)
            state.isShow = true
        },
        hide: (state, action) => {
            Object.assign(state, action.payload)
            state.isShow = false
        },
    },
    extraReducers: (builder) => {

    },
});

export const auditSelectedSlice = createSlice({
    name: 'auditSelected',
    initialState: initialState,
    reducers: {
        reset: (state) => {
            Object.assign(state, initialState)
        },
        resetStage: (state) => {
            state.stage = []
        },
        setAction: (state, action: PayloadAction<E_AuditClaimDispute>) => {
            state.action = action.payload
            state.stage = []
        },
        add: (state, action: PayloadAction<PROCESS_AUDIT>) => {
            if (!_.find(state.data, (p: PROCESS_AUDIT) => p.id === action.payload.id)) {
                let audit: PROCESS_AUDIT & { checked?: boolean } = {...action.payload}
                audit.checked = true
                state.stage.push(audit)
                state.data.push(audit)
            }
        },
        remove: (state, action: PayloadAction<PROCESS_AUDIT>) => {
            const removedStageItems = _.remove(state.stage, (p: PROCESS_AUDIT) => p.id === action.payload.id);
            const removedItems = _.remove(state.data, (p: PROCESS_AUDIT) => p.id === action.payload.id);

            if (removedStageItems.length === 0) {
                // Log message/error or handle the case when no items were removed
            }
            if (removedItems.length === 0) {
                // Log message/error or handle the case when no items were removed
            }
        },
        toggleSingle: (state, action) => {
            const {itemIndex, process} = action.payload
            if (process && itemIndex >= 0 && itemIndex < state.data.length) {
                state.data[itemIndex].checked = !state.data[itemIndex].checked;
            }
        },
        toggleAll: (state) => {
            _.forEach(state.data, (p: PROCESS_AUDIT & { checked?: boolean }) => {
                p.checked = !p.checked
                return p
            })
        },
    },
    extraReducers: (builder) => {

    },
});

export const {} = auditSelectedSlice.actions;

export const auditSelectedState = (state: RootState) => state.auditSelected;
export const auditClaimDisputeModalState = (state: RootState) => state.auditClaimDisputeModal;

export default auditSelectedSlice.reducer;
