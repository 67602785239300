import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";

export const getDraftList = (params: any) => {
    const fipt = AUTH.getToken();

    return axiosRequest('/get-draft-analysis-list', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
        params: {
            ...params
        },
    });
}

export const getAnalysisList = (params: any) => {
    const fipt = AUTH.getToken();

    return axiosRequest('/get-analysis-list', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
        params: {
            ...params
        },
    });
}

export const getAnalysisByCode = (code: string) => {
    const fipt = AUTH.getToken();

    return axiosRequest('/get-analysis', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
        params: {
            code
        },
    });
}

export const startAnalysis = (data: { code: string }) => {
    const fipt = AUTH.getToken();
    return axiosRequest('/start-analysis', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
    });
};

export const saveAnalysis = (data: { code: string, content: any }) => {
    const fipt = AUTH.getToken();
    return axiosRequest('/save-analysis', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
    });
};

export const submitAnalysis = (data: { code: string, content: any, comment: string }) => {
    const fipt = AUTH.getToken();
    return axiosRequest('/complete-analysis', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
    });
};

export const duplicateAnalysis = (data: { code: string, name: string }) => {
    const fipt = AUTH.getToken();
    return axiosRequest('/duplicate-analysis', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
    });
};

export const deleteAnalysis = (data: { code: string }) => {
    const fipt = AUTH.getToken();
    return axiosRequest('/delete-analysis', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
    });
};

export const getSites = () => {
    const fipt = AUTH.getToken();
    return axiosRequest('/get-sites', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
    });
};

export const getManufacturerTypes = () => {
    const fipt = AUTH.getToken();
    return axiosRequest('/get-types', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
    });
};

export const getProcessesByType = (params: { type_id: number }) => {
    const fipt = AUTH.getToken();
    return axiosRequest('/get-processes-by-type', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
        params
    });
};
export const getMaterialByTypeProcess = (params: { type_id: number, process_id: number }) => {
    const fipt = AUTH.getToken();
    return axiosRequest('/get-materials-by-type-and-process', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
        params
    });
};

export const getTemplates = async (params: {
    type_id: number,
    process_id: number,
    material_id?: number
}) => {
    const fipt = AUTH.getToken();
    return axiosRequest('/get-template', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
        params
    });
};

export const createAnalysis = async (data: {
    template_id: number,
    site_id: number,
    name: string
}) => {
    const fipt = AUTH.getToken();
    return axiosRequest('/create-analysis', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
    });
};