import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import axios from "axios";

export const ACCOUNT_API = {
    inviteUser: async (data: object = {}) => {
        return await axiosRequest('/invite', {
            method: 'POST',
            data: data,
            headers: {...AUTH.getHeader()},
        }, false);
    },

    getAccountServices: async (data: any = {}) => {
        const {signal} = data;

        let options = ({
            method: 'GET',
            headers: {...AUTH.getHeader()},
            signal
        });

        try {
            return await axiosRequest(`/service-tab`, options, true, false);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled: getAccountServices', error.message);
            } else {
                throw error;
            }
        }
    }

}