import {Link} from "react-router-dom";
import {canViewStep} from "@v2pages/OrderDetail/Extra/OrderProcessHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPencilAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash-es";
import {Button, Image, OverlayTrigger, Tooltip} from "react-bootstrap";
import {translateV2} from "@src/helpers";
import {ASSETS} from "@assets/Assets";
import React from "react";

const styles = {
  linkedBtn: {
    width: "20px", height: "20px",
    minWidth: "20px", minHeight: "20px",
  }
}

const OrderProcessCtas = ({
                            item,
                            onClickEdit,
                            onClickLinkOrders,
                            onClickDelete
                          }) => {
  const index = _.uniqueId(`process_detail_`);

  return (
    <div className="order-group-action">

      <div className="square">
        <Link replace={true}
              to={`/orders/${item.order_code}/${item.process_position}`}
              className={`btn btn-outline-success${canViewStep(item.process_status, item.custom_scan_date) ? '' : ' disabled'}`}>
          <FontAwesomeIcon icon={faEye}/>
        </Link>
      </div>

      <div className="square">
        <OverlayTrigger overlay={

          <Tooltip id={`edit-order-${index}`}>
            {
              item.can_be_edited
                ? _.capitalize(translateV2("LABEL.EDIT")) + " " + translateV2("LABEL.ORDER_PROCESS")
                : _.capitalize(translateV2("LABEL.CANNOT_EDIT_ORDER_PROCESS"))
            }
          </Tooltip>}>

          <button
            className={`btn btn-outline-success btn-audit-process${index}`}
            disabled={!item.can_be_edited}
            onClick={(event) => {
              if (_.isFunction(onClickEdit) && item.can_be_edited) onClickEdit();
            }
            }>
            <FontAwesomeIcon icon={faPencilAlt}/>
          </button>

        </OverlayTrigger>
      </div>

      <div className="square">
        <OverlayTrigger overlay={

          <Tooltip id={`link-order-${index}`}>
            {_.capitalize(translateV2("LABEL.VIEW"))}&nbsp;{translateV2("LABEL.LINKED_ORDERS")}
          </Tooltip>}>

          <Button title="View linked orders"
                  className={`btn-vlink-orders ${!_.isEmpty(item.input_products.value) ? "" : " disabled "}`}
                  variant={"outline-success"}
                  onClick={(e) => {
                    if (!_.isEmpty(item.input_products.value) && _.isFunction(onClickLinkOrders)) onClickLinkOrders();
                  }}>
            <Image src={ASSETS.IconLinkOrderV2} alt={"View link orders"} style={styles.linkedBtn}/>
          </Button>

        </OverlayTrigger>
      </div>

      <div className="square">
        {item.can_be_deleted
          ? <button className="btn btn-outline-success"
                    onClick={() => {
                      if (_.isFunction(onClickDelete)) onClickDelete();
                    }}>
            <FontAwesomeIcon icon={faTrash}/>
          </button>
          : <OverlayTrigger overlay={<Tooltip
            id={`tooltip-disabled-${index}`}>{translateV2(item.can_not_delete_reason)}</Tooltip>}>
            <button className="btn btn-outline-success disabled"
            >
              <FontAwesomeIcon icon={faTrash}/>
            </button>
          </OverlayTrigger>
        }
      </div>
    </div>
  )
}

export default OrderProcessCtas;
