import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPONENT_INTERACTION, COMPONENT_SEARCH_QUERIES, PAGING_DATA, QUERIES,} from "@src/types";
import {APP_ROUTES_NAME} from "@src/routes";
import {DEFAULT_VARIABLES} from "@src/variables";
import _ from "lodash-es";
import {AUDIT_TEMPLATE_API} from "@api/AuditTemplate";
import axios from "axios";

const initialState: COMPONENT_INTERACTION = {
    name: APP_ROUTES_NAME.AUDIT_TEMPLATES,
    search: {...DEFAULT_VARIABLES.SEARCH_SETTINGS,},
    loading: false,
    data: [] as Array<any>
}

export const fetchAuditTemplatesAsync = createAsyncThunk(
    'auditTemplates/fetch',
    async (searchQueries: COMPONENT_SEARCH_QUERIES, {getState, signal}) => {
        try {
            const source = axios.CancelToken.source();

            signal.addEventListener('abort', () => {
                source.cancel();
            });

            const {
                data = [],
                meta = {},
                error
            } = await AUDIT_TEMPLATE_API.getTemplates(_.omitBy(searchQueries.queries, _.isNil), source.token);

            return _.omitBy({
                data,
                meta,
                error,
                queries: searchQueries.queries,
                selected: searchQueries.selected
            }, _.isNil)
        } catch (e) {
            console.error(e)
            return {data: [], meta: {}, queries: searchQueries.queries, selected: searchQueries.selected}
        }
    }
);

export const auditTemplateSlice = createSlice({
    name: 'auditTemplates',
    initialState,
    reducers: {
        setState: (state, action: PayloadAction<COMPONENT_INTERACTION>) => {
            state = {...action.payload}
            return state
        },
        setSearch: (state, action: PayloadAction<any>) => {
            state = {
                ...state,
                search: {...action.payload}
            }
            return state
        },
        setData: (state, action: PayloadAction<[]>) => {
            state = {
                ...state,
                data: [...action.payload]
            }
            return state
        },
        setQueries: (state, action: PayloadAction<QUERIES>) => {
            state = {
                ...state,
                search: {
                    ...state.search,
                    queries: {...action.payload}
                }
            }
            return state
        },
        setPaging: (state, action: PayloadAction<PAGING_DATA>) => {
            state = {
                ...state,
                search: {
                    ...state.search,
                    pagingData: {...action.payload}
                }
            }
            return state
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAuditTemplatesAsync.pending, (state, action) => {
                state.loading = true;
                return state
            })
            .addCase(fetchAuditTemplatesAsync.rejected, (state, action) => {
                state.loading = false
                return state
            })
            .addCase(fetchAuditTemplatesAsync.fulfilled, (state, action) => {

                const {data, meta, queries, selected} = action.payload
                const total_pages = Math.ceil(meta.total / queries.number_per_page)

                if (!_.size(data) && queries.page > 1) {
                    queries.page = (meta.current_page - 1);
                }

                state = {
                    ...state, data,
                    loading: false,
                    search: {
                        selected,
                        queries: {
                            ...state.search.queries,
                            ...queries
                        },
                        pagingData: {
                            ...state.search.pagingData,
                            ...meta,
                            total_pages
                        }
                    },
                }

                return state
            })

        ;
    },
});

export const {} = auditTemplateSlice.actions;

export const auditTemplateState = (state: RootState) => state.auditTemplate;

export default auditTemplateSlice.reducer;
