import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {NOTIFICATION_API} from "@api/Notification";
import _ from "lodash-es";
import {DEFAULT_VARIABLES} from "@src/variables";
import {AUTH} from "@api/Auth";
import {fibLogger} from "@src/helpers";

export interface NotificationItemInterface {
    created_at?: string
    created_at_utc?: string
    has_been_read: boolean
    id: number
    logo?: string | "" | null
    message?: string | "" | null
    summary?: string | ""
    type?: string | ""
}

export interface NotificationInterface {
    autoRefresh: boolean
    hasNotification: boolean
    intervalId?: any
    list: Array<NotificationItemInterface>

}

const initialState: NotificationInterface = {
    autoRefresh: true,
    hasNotification: false,
    list: [],

}

export const fetchNotificationAsync = createAsyncThunk(
    'notification/fetchNotification',
    async ({
               extra = {},
               queries = DEFAULT_VARIABLES.SEARCH_SETTINGS.queries
           }: { extra?: any | object, queries?: object }, thunkAPI) => {
        if (AUTH.isLogin()) {
            const response = await NOTIFICATION_API.getBatch(_.omitBy(queries, _.isNil), extra);
            return response.data;
        }
        return false;
    }
);

export const markAllReadAsync = createAsyncThunk(
    'notification/markAllRead',
    async () => {
        const {message, error} = await NOTIFICATION_API.markAllAsRead();
        return {message, error}
    }
);


export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        readNotification: (state, action: PayloadAction<NotificationItemInterface>) => {
            let idx = _.findIndex(state.list, (v: NotificationItemInterface) => v.id === action.payload.id)
            if (idx !== -1) state.list[idx].has_been_read = true;
            state.hasNotification = !_.every(state.list, ['has_been_read', true]);
        },
        setNotificationInterval: (state, action: PayloadAction<any>) => {
            state.intervalId = action.payload.intervalId
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotificationAsync.fulfilled, (state, action) => {

                if (!(AUTH.isLogin() && action.payload)) {
                    fibLogger("You are not logged in", "warn");
                    clearInterval(state.intervalId);
                }else{
                    state.hasNotification = !_.every(action.payload, ['has_been_read', true])
                    state.autoRefresh = AUTH.isLogin() && !!action.payload
                    state.list = !!action.payload ? action.payload : []
                }

            })
            .addCase(markAllReadAsync.fulfilled, (state, action) => {
                state.hasNotification = !action.payload.error
            })
        ;

    },
});

export const {readNotification, setNotificationInterval} = notificationSlice.actions;

export const notificationState = (state: RootState) => state.notification;

export default notificationSlice.reducer;
