import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {SEARCH_QUERIES} from "@src/types";
import {DEFAULT_VARIABLES} from "@src/variables";

const initialState: Omit<SEARCH_QUERIES, 'pagingData'> & any = {
    selected: "",
    queries: {...DEFAULT_VARIABLES.SEARCH_SETTINGS.queries},
    tabs: [],
    title: "",
    numPerPage: [5, 10, 20, 50, 100]
}
export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchState: (state, action: PayloadAction<SEARCH_QUERIES & any>) => {
            state = {
                ...state,
                ...action.payload
            }
            return state
        },
    },
});

export const {setSearchState} = searchSlice.actions;

export const searchState = (state: RootState) => state.search;

export default searchSlice.reducer;
