import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';

export interface I_Intro {
    isShow: boolean

    [key: string]: any
}

const initialState: I_Intro = {
    isShow: false,
    stepsEnabled: false,
    initialStep: 0,
    steps: [
        {
            element: ".ux-wizard-trigger",
            intro: ("LABEL.DASHBOARD.WELCOME.START_HERE")
        },
        {
            element: ".header-menu .animated-bars",
            intro: ("LABEL.DASHBOARD.WELCOME.DESCRIPTION")
        },
        {
            element: ".header-menu .navbar-notification-icon",
            intro: ("LABEL.DASHBOARD.WELCOME.NOTIFICATION")
        },
        {
            element: ".header-menu .header-logo",
            intro: ("LABEL.DASHBOARD.WELCOME.LOGO")
        },
        {
            element: ".action-box-container",
            intro: ("LABEL.DASHBOARD.WELCOME.TASKS")
        },
    ],
    hintsEnabled: false,
    hints: []
}

export const userIntroSlice = createSlice({
    name: 'userIntro',
    initialState,
    reducers: {
        show: (state) => {
            state.isShow = true;
            state.stepsEnabled = true;
        },
        hide: (state) => {
            state.isShow = false;
            state.stepsEnabled = false;
        },
        enableStep: (state, action: PayloadAction<I_Intro>) => {

        },
    },
});

export const userIntroState = (state: RootState) => state.userIntro;

export default userIntroSlice.reducer;
