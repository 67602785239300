import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {Message} from "@src/types";
import {DEFAULT_MODAL} from "@src/variables";

export interface VideoModalInterface {
    isShow: boolean
    title: string | any
    message: string | any
    messageData?: any
    type: Message
    size?: "sm" | "lg" | "xl"
    videoID: string,
    channel?: "youtube",
    start?: number | string
}

const initialState: VideoModalInterface = {
    ...DEFAULT_MODAL,
    videoID: "",
    channel: "youtube",
    start: "0"
}

export const videoModalSlice = createSlice({
    name: 'videoModal',
    initialState,
    reducers: {
        showVideoModal: (state, action: PayloadAction<VideoModalInterface>) => {
            state = {
                ...action.payload, isShow: true
            }
            return state
        },
        hideVideoModal: (state) => {
            state.isShow = false;
            return state
        }
    },
});

export const {showVideoModal, hideVideoModal} = videoModalSlice.actions;

export const videoModalState = (state: RootState) => state.videoModal;

export default videoModalSlice.reducer;
