import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {DEFAULT_MODAL} from "@src/variables";
import {ConfirmationMessageInterface} from "@src/types/modal";

const initialState: ConfirmationMessageInterface = {
    ...DEFAULT_MODAL, size: "lg",
    confirmAction: "",
    cancelAction: "",
    chainConfirmActions:[],
    chainCancelActions:[],
    confirmButton:{
        visible: true,
        variant: "success",
        label: "Yes"
    },
    cancelButton:{
        visible: true,
        variant: "outline-dark",
        label: "No"
    },
    children: null
}

export const confirmationMessageSlice = createSlice({
    name: 'confirmation',
    initialState,
    reducers: {
        showMessage: (state, action: PayloadAction<ConfirmationMessageInterface>) => {
            return {...action.payload, isShow: true}
        },
        hideMessage: (state) => {
            state.isShow = false;
            return state
        }
    },
});

export const confirmationState = (state: RootState) => state.confirmation;
export default confirmationMessageSlice.reducer