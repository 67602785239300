import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";

/**
 * @inheritDoc https://admin-webapp-dev.fibretrace.io/swagger/index.html#/Company/get_company_processes
 */
export const PROCESS_API = {

    BASE: '/company/process',

    getMetadata: () => {
        return axiosRequest(`${PROCESS_API.BASE}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    getBatch: (data = {} as Object, extra: any = {}) => {
        let options = {
            method: 'GET',
            params: data,
            headers: {...AUTH.getHeader()},
        } as any;
        if (!_.isEmpty(extra)) options.cancelToken = extra
        return axiosRequest(`${PROCESS_API.BASE}es`, options, true, false);
    },


    getOne: ({id}) => {
        return axiosRequest(`${PROCESS_API.BASE}/${id}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },
    createOne: (data = {}) => {
        try {
            return axiosRequest(`${PROCESS_API.BASE}`, {
                method: 'POST',
                data: data,
                headers: {...AUTH.getHeader()},
            }, true, false);
        } catch (e) {

        }
    },
    updateOne: (data: any) => {
        const {id} = data;
        if (id) {
            return axiosRequest(`${PROCESS_API.BASE}/${id}`, {
                method: 'PUT',
                data: data,
                headers: {...AUTH.getHeader()},
            }, true, false);
        }
    },
    deleteOne: ({id}: any) => {
        return axiosRequest(`${PROCESS_API.BASE}/${id}`, {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    restoreOne: ({id}: any) => {
        return axiosRequest(`${PROCESS_API.BASE}/${id}/restore`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    updateCertificate: (data: CERTIFICATE_REQUEST & CERTIFICATE) => {
        // {
        // "field": "aaa",
        // "name": "Tesst",
        // "path": "fibretrace/FIP/self/certificate/h86yeUtYssh8/Rmmse4n0zAAJFcDZleF9lKEe21I6XY8WWuIeUYrU.pdf",
        // "validity": "2022-04-01"
        // }
        // http://admin.fibretrace.test/api/v1/company/process/125/certificate/81

        const {process_id, certificate_id, field, name, path, validity} = data;

        if (process_id && certificate_id) {
            return axiosRequest(`${PROCESS_API.BASE}/${process_id}/certificate/${certificate_id}`, {
                method: 'PUT',
                data: _.omit(data, ['process_id', 'certificate_id']),
                headers: {...AUTH.getHeader()},
            }, true, false);
        }
        return false;
    },

    deleteCertificate: (data: CERTIFICATE_REQUEST) => {
        const {process_id, certificate_id} = data;
        return axiosRequest(`${PROCESS_API.BASE}/${process_id}/certificate/${certificate_id}`, {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    duplicateProcess: ({process_id}: any) => {

        return axiosRequest('/duplicate-process', {
            method: 'POST',
            body: {
                process_id
            },
            headers: {...AUTH.getHeader()},
        }, true, false);

    },

    publishTransactionCertificate: ({orderCode}: any) => {
        if (!orderCode) return false
        return axiosRequest(`/orders/${orderCode}/transaction-certificate-regenerate`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    getOneByCode: ({code}: any) => {
        return axiosRequest(`${PROCESS_API.BASE}/${code}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    deleteProcessImage: ({id}: any) => {
        return axiosRequest(`/process-image`, {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
            data: {id},
        }, true, false);
    },

}

interface CERTIFICATE_REQUEST {
    process_id: number | string
    certificate_id: number | string
}

interface CERTIFICATE {
    field: string
    name: string
    path: string
    validity: string
}

