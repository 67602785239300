import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPONENT_INTERACTION, COMPONENT_SEARCH_QUERIES, PAGING_DATA, QUERIES,} from "@src/types";
import {APP_ROUTES_NAME} from "@src/routes";
import {DEFAULT_VARIABLES} from "@src/variables";
import _ from "lodash-es";
import {PARTNER_API} from "@api/Partner";

const initialState: COMPONENT_INTERACTION = {
    name: APP_ROUTES_NAME.COMPANY,
    search: {
        ...DEFAULT_VARIABLES.SEARCH_SETTINGS,
        queries: {
            ...DEFAULT_VARIABLES.SEARCH_SETTINGS.queries,
            search: null,
            sort: 'asc',
            sort_by: 'name',
            is_paginate: true
        }
    },
    loading: false,
    data: [] as Array<any>
}

export const fetchPartnerAsync = createAsyncThunk(
    'partner/fetch',
    async (searchQueries: COMPONENT_SEARCH_QUERIES & { signal?: AbortController }, {getState}) => {
        try {
            const {queries, selected, signal} = searchQueries;
            const r = await PARTNER_API.getPartners(_.omitBy({...queries, signal}, _.isNil));

            if (r) {
                let {data = [], meta, error} = r;
                if (data && !error) {
                    return {data, meta, queries, selected}
                }
            }

            return {data: [], meta: {}, queries, selected}
        } catch (e) {
            console.error(e)
            return {data: [], meta: {}, queries: searchQueries.queries, selected: searchQueries.selected}
        }
    }
);

export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        setPartnerState: (state, action: PayloadAction<COMPONENT_INTERACTION>) => {
            state = {...action.payload}
            return state
        },
        setPartnerSearch: (state, action: PayloadAction<any>) => {
            state = {
                ...state,
                search: {...action.payload}
            }
            return state
        },
        setPartnerData: (state, action: PayloadAction<[]>) => {
            state = {
                ...state,
                data: [...action.payload]
            }
            return state
        },
        setPartnerQueries: (state, action: PayloadAction<QUERIES>) => {
            state = {
                ...state,
                search: {
                    ...state.search,
                    queries: {...action.payload}
                }
            }
            return state
        },
        setPartnerPaging: (state, action: PayloadAction<PAGING_DATA>) => {
            state = {
                ...state,
                search: {
                    ...state.search,
                    pagingData: {...action.payload}
                }
            }
            return state
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPartnerAsync.fulfilled, (state, action) => {
                const {data, meta, queries, selected} = action.payload
                const total_pages = Math.ceil(meta.total / queries.number_per_page)

                if (!_.size(data) && queries.page > 1) {
                    queries.page = (meta.current_page - 1);
                }
                state = {
                    ...state, data,
                    search: {
                        selected,
                        queries: {
                            ...state.search.queries,
                            ...queries
                        },
                        pagingData: {
                            ...state.search.pagingData,
                            ...meta,
                            total_pages
                        }
                    },
                }

                return state
            })

        ;
    },
});

export const {
    setPartnerData, setPartnerSearch, setPartnerQueries, setPartnerPaging, setPartnerState,
} = partnerSlice.actions;

export const partnerState = (state: RootState) => state.partner;

export default partnerSlice.reducer;
