import logo from "@assets/images/logo.png";

/**
 * Images used for login flow
 */
import IconDialogV2 from "@assets/images/icons/v2/icon_dialog.png";
import IconEmailV2 from "@assets/images/icons/v2/icon_email.png";
import IconPenV2 from "@assets/images/icons/v2/icon_pen.png";
import IconUploadV2 from "@assets/images/icons/v2/icon_upload.png";
import IconUserV2 from "@assets/images/icons/v2/icon_user.png";
import IconCloseV2 from "@assets/images/icons/v2/icon_close.png";

import IconPinV2 from "@assets/images/icons/v2/icon_pin.png";
import IconUnPinV2 from "@assets/images/icons/v2/icon_unpin.png";
import IconDocumentV2 from "@assets/images/icons/v2/icon_document.png";

import IconPreviousV2 from "@assets/images/icons/v2/icon_prev.svg";
import IconNextV2 from "@assets/images/icons/v2/icon_next.svg";
import IconCheckboxCheckedGreen from "@assets/images/icons/v2/icon_checkbox_checked_green.png";
import IconCheckboxCheckedGreenSvg from "@assets/images/icons/v2/icon_checkbox_checked_green.svg";

/**
 * Loader
 */
import FibrePreloader from "@assets/images/page-loading.gif";
import LoaderFibretrace from "@assets/images/page-loading.gif";
/**
 * Used in dashboard
 */
import IconCheckGreenV2 from "@assets/images/icons/v2/icon_check_green.png";

import IconDownloadFIle from "@assets/images/icons/Download-File.svg";
import IconEcommerceDollarSign from "@assets/images/icons/dollarSign.png";

/**
 * For UX Wizard
 */
import IconUxWindmill from "@assets/images/uxwizard/Windmill.svg";
import IconUxFactory from "@assets/images/uxwizard/Factory-2.svg";
import IconUxShop from "@assets/images/uxwizard/Shop.svg";
import IconUxCargo from "@assets/images/uxwizard/Hand-cargo.svg";
import IconUxTruck from "@assets/images/uxwizard/Truck.svg";
import IconUxQuestionMark from "@assets/images/uxwizard/Question-Mark.svg";
import IconUxBox from "@assets/images/uxwizard/Box.png";
import IconUxBoxQuestion from "@assets/images/uxwizard/Box-Quesion_96px.png";
import IconUxQuestionTask from "@assets/images/uxwizard/Question-Task_96px.png";
import IconUxRightArrow from "@assets/images/uxwizard/Right-Arrow.png";
import IconUxLeftArrow from "@assets/images/uxwizard/Left-Arrow.png";
import IconUxDoneBadge from "@assets/images/uxwizard/Done-Award-Badge_96px.png";
import IconUxUserSearch from "@assets/images/uxwizard/User-Search.png";
import IconUxMagicWand from "@assets/images/uxwizard/Magic-Wand_240px.png";
import IconUxMagicWandGreen from "@assets/images/uxwizard/Magic-Wand-green.svg";
import IconUxScanner from "@assets/images/uxwizard/fibretrace-scanner.png";

import IconUxFibProduct from "@assets/images/uxwizard/Fibretrace-Product.svg";
import IconUxFibOrder from "@assets/images/uxwizard/Fibretrace-Order.svg";
import IconUxFibAudit from "@assets/images/uxwizard/Fibretrace-Audit.png";

import IconLockGray from "@assets/images/icons/lock-ico-gray.svg";
import IconErrorMarkYellow from "@assets/images/icons/v2/icon_error_mark_yellow.svg";
import IconGooglePlayBadge from "@assets/images/icons/google-play-badge.png";
import QRCodeFibreTraceTraceabilityJourney from "@assets/images/QR_Code_B2C_Demo_Blue_Shirt.png";
import IconEditInline from "@assets/images/icons/inline/edit-ico.svg";


/**
 * Partners Import template
 */
import IconInfoCircleError from "@assets/images/icons/v2/icon_info_circle.svg";
import IconInfoCircleInfo from "@assets/images/icons/v2/icon_exclamation_circle_blue.svg";

/**
 * Product Archive FIP-2655
 */
import IconArchive from "@assets/images/icons/v2/icon_archive.svg";
import IconTrash from "@assets/images/icons/v2/icon_trash.svg";

import IconCheckSuccessGreenV2 from "@assets/images/icons/v2/icon_check_green_success.png";

/**
 * For 2642 Order detail page
 */
import IconDownloadBlackV2 from "@assets/images/icons/v2/icon_download_black.png";
import IconDocumentBlackV2 from "@assets/images/icons/v2/icon_document_black.png";
import IconQrCodeBlackV2 from "@assets/images/icons/v2/icon_qrcode_black.png";
import IconLocationBlackV2 from "@assets/images/icons/v2/icon_location_black.png";
import IconLinkBlackV2 from "@assets/images/icons/v2/icon_link_black.png";
import IconExclaimationBlackV2 from "@assets/images/icons/v2/icon_exclaimation_black.png";
import IconPreviousCircleBlackV2 from "@assets/images/icons/v2/icon_previous_order.png";
import IconOrderFileBlackV2 from "@assets/images/icons/v2/icon_order_file_black.png";
import IconChevronDownBlackV2 from "@assets/images/icons/v2/icon_chevron_down_black.png";
import IconChevronDownWhiteV2 from "@assets/images/icons/v2/icon_chevron_down_white.png";
import IconExpanderV2 from "@assets/images/icons/v2/icon_expander.png";
import IconLinkOrderV2 from "@assets/images/icons/v2/icon_link_orders.svg";

/**
 * Subscription Packages
 */
import PackageVerified from "@assets/images/packages/verified.png";
import PackageMapped from "@assets/images/packages/mapped.png";

/**
 * FIP-2909
 */
import IconDownloadWhiteV2 from "@assets/images/icons/v2/icon_download_white.png";
import IconUploadLightRedSVGV2 from "@assets/images/icons/v2/icon_upload_lightred.svg";

export const ASSETS = {
    logo
    , IconDialogV2, IconEmailV2, IconPenV2, IconUploadV2, IconUserV2, IconCloseV2
    , IconCheckGreenV2, IconPinV2, IconUnPinV2, IconDocumentV2, IconPreviousV2, IconNextV2
    , FibrePreloader
    , IconDownloadFIle
    , IconEcommerceDollarSign
    , IconUxWindmill
    , IconUxFactory
    , IconUxShop
    , IconUxCargo
    , IconUxTruck
    , IconUxQuestionMark
    , IconUxBox
    , IconUxBoxQuestion
    , IconUxQuestionTask
    , IconUxRightArrow
    , IconUxDoneBadge
    , IconUxUserSearch
    , IconUxLeftArrow
    , IconUxMagicWand
    , IconUxMagicWandGreen
    , IconUxScanner
    , IconUxFibProduct
    , IconUxFibOrder
    , IconUxFibAudit
    , IconLockGray
    , IconErrorMarkYellow
    , IconGooglePlayBadge
    , QRCodeFibreTraceTraceabilityJourney
    , IconEditInline
    , LoaderFibretrace
    , IconCheckboxCheckedGreen
    , IconCheckboxCheckedGreenSvg
    , IconInfoCircleError
    , IconArchive
    , IconTrash
    , IconInfoCircleInfo
    , IconCheckSuccessGreenV2
    , IconDownloadBlackV2
    , IconDocumentBlackV2
    , IconQrCodeBlackV2
    , IconLocationBlackV2
    , IconLinkBlackV2
    , IconExclaimationBlackV2
    , IconPreviousCircleBlackV2
    , IconOrderFileBlackV2
    , IconChevronDownBlackV2
    , IconChevronDownWhiteV2
    , IconExpanderV2
    , PackageVerified
    , PackageMapped
    , IconDownloadWhiteV2
    , IconUploadLightRedSVGV2
    , IconLinkOrderV2
}
