export const drawDashedCurve = (P1:any, P2:any, type: number = 0) => {
    const lineLength = google.maps.geometry.spherical.computeDistanceBetween(P1, P2);
    const lineHeading = google.maps.geometry.spherical.computeHeading(P1, P2);
    let lineHeading1 = lineHeading + 45;
    let lineHeading2 = lineHeading + 135;
    if (type !== 0) {
        lineHeading1 = lineHeading + -45;
        lineHeading2 = lineHeading + -135;
    }
    const pA = google.maps.geometry.spherical.computeOffset(P1, lineLength / 2.2, lineHeading1);
    const pB = google.maps.geometry.spherical.computeOffset(P2, lineLength / 2.2, lineHeading2);

    const curvedLine = gmapsCubicBezier(P1, pA, pB, P2, 0.0005);
    return curvedLine;
}

const gmapsCubicBezier = (latlong1: any, latlong2: any, latlong3: any, latlong4: any, resolution: any) => {
    const lat1 = latlong1.lat();
    const long1 = latlong1.lng();
    const lat2 = latlong2.lat();
    const long2 = latlong2.lng();
    const lat3 = latlong3.lat();
    const long3 = latlong3.lng();
    const lat4 = latlong4.lat();
    const long4 = latlong4.lng();

    let points : any [] = [];

    for (let it = 0; it <= 1; it += resolution) {
        const cb = new CubicBezier();
        points.push(cb.getBezier({
            x: lat1,
            y: long1
        }, {
            x: lat2,
            y: long2
        }, {
            x: lat3,
            y: long3
        }, {
            x: lat4,
            y: long4
        }, it));
    }
    let path : any [] = [];
    for (let i = 0; i < points.length - 1; i++) {
        path.push(new google.maps.LatLng(points[i].x, points[i].y));
        path.push(new google.maps.LatLng(points[i + 1].x, points[i + 1].y, false));
    }

    const lineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 3
    };

    const middlePoint = points[Math.floor((points.length - 1) / 2)];
    const nextPoint = points[Math.floor((points.length - 1) / 2) - 1];
    const middleLatLng = new google.maps.LatLng(middlePoint.x, middlePoint.y);
    const nextLatLng = new google.maps.LatLng(nextPoint.x, nextPoint.y);

    const rot = google.maps.geometry.spherical.computeHeading(middleLatLng, nextLatLng);

    return ({
        line: {
            path: path,
            geodesic: true,
            strokeOpacity: 0.0,
            icons: [{
                icon: lineSymbol,
                offset: '0',
                repeat: '14px'
            }],
            strokeColor: '#015b9e'
        },
        arrow: {
            position: middleLatLng,
            icon: {
                path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                rotation: rot,
                strokeColor: '#015b9e',
                scale: 3
            }
        }
    });
};

class CubicBezier {
    B1(t:number){
        return t * t * t;
    }
    B2(t: number){
        return 3 * t * t * (1 - t);
    }
    B3(t: number){
        return 3 * t * (1 - t) * (1 - t);
    }
    B4(t: number){
        return (1 - t) * (1 - t) * (1 - t);
    }
    getBezier(C1: any, C2: any, C3: any, C4: any, percent: number){
        var pos = {} as any;
        pos.x = C1.x * this.B1(percent) + C2.x * this.B2(percent) + C3.x * this.B3(percent) + C4.x * this.B4(percent);
        pos.y = C1.y * this.B1(percent) + C2.y * this.B2(percent) + C3.y * this.B3(percent) + C4.y * this.B4(percent);
        return pos;
    }
};