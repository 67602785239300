import React from 'react';
import {withGoogleMap, withScriptjs} from "react-google-maps";
import {compose, withProps, withStateHandlers} from "recompose";
import {GG_KEY} from '@constants/variables';

const GoogleMapHOC = (Component: any) => compose<any, any>(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GG_KEY}&libraries=geometry`,
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `100%`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withScriptjs,
    withGoogleMap,
    withStateHandlers(
        {},
        {
            onMapMounted: () => (ref: any) => ({
                mapRef: ref
            })
        }
    ),
)(Component);

export default GoogleMapHOC;
