import React, {useEffect} from "react";
import {AUTH} from "@api/Auth";
import {useAppDispatch} from "@redux/hooks/hooks";
import {loadInitialDataAsync} from "@redux/reducers/userSlice";
import {Navigate} from "react-router-dom";
import {APP_ROUTES_PATH} from "@src/routes";

const AuthProvider = ({children}) => {

    const dispatch = useAppDispatch();
    const isLogin = AUTH.isLogin();

    useEffect(() => {
        if (isLogin) {
            dispatch(loadInitialDataAsync())
        }
    }, []);

    return isLogin ? <>{children}</> : <Navigate to={APP_ROUTES_PATH.LOGIN} replace={true}/>
};

export default AuthProvider;
