import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {LANGUAGE_API} from '@api/Languge';
import {CookieService, translateV2} from '@src/helpers';
import {Link, useLocation} from 'react-router-dom';
import Language from '@components/language/Language';
import {handleFooter} from "./helper";
import {AUTH} from "@api/Auth";
import {useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {blockState} from "@redux/reducers/blockSlice";
import {userState} from "@redux/reducers/userSlice";
import {configState} from "@redux/reducers/configSlice";
import {appRoutesState} from "@redux/reducers/appRoutesSlice";
import {appProviderState} from "@redux/reducers/appProviderSlice";
import _ from "lodash-es";
import FooterPackage from "@components/Footer/FooterPackage";
import {APP_ROUTES_PATH} from "@src/routes";

const PageLoader = React.lazy(() => import ('@components/PageLoader/PageLoader'))

const Footer = ({className = ''}) => {
    const langData = useAppSelector(languageState);
    const block = useAppSelector(blockState);
    const user = useAppSelector(userState);
    const config = useAppSelector(configState);

    const location = useLocation();
    // const routeData = useSelector((state: any) => state.route);
    const appRoutesStore = useAppSelector(appRoutesState)
    const routeData = appRoutesStore.routes

    const dispatch = useDispatch();

    const [faqUrl, setfaqUrl] = useState('');
    const [termUrl, setTermUrl] = useState('');
    const [policyUrl, setPolicyUrl] = useState('');

    const [isChangeLang, setChangeLang] = useState(false);

    const year = (new Date()).getFullYear();
    const {isLoggedIn} = useAppSelector(appProviderState)

    const {google_tag_manager_id, google_analytic_id} = config;

    const changeLang = async (lang: any) => {
        if (lang && langData.lang !== lang) {

            setChangeLang(true);

            if (isLoggedIn) {
                const {data} = await LANGUAGE_API.setLangServer({lang});
                if (data && data.token) {
                    AUTH.refreshToken(data.token);
                }
            }

            await AUTH.setLang(lang);

            CookieService.set('lang', lang, 3650);
            setChangeLang(false);

        }
    }

    useEffect(() => {

        const faqU: any = routeData.find((route: any) => route.type === 'faq');
        if (faqU && faqU.slug) {
            setfaqUrl(faqU.slug);
        }

        const termU: any = routeData.find((route: any) => route.type === 'term');
        if (termU && termU.slug) {
            setTermUrl(termU.slug);
        }

        const policyU: any = routeData.find((route: any) => route.type === 'policy');
        if (policyU && policyU.slug) {
            setPolicyUrl(policyU.slug);
        }

        window.addEventListener('load', handleFooter);
        window.addEventListener('resize', handleFooter);
        window.addEventListener('scroll', handleFooter);

        return () => {
            window.removeEventListener('load', handleFooter);
            window.removeEventListener('resize', handleFooter);
            window.removeEventListener('scroll', handleFooter);
        };

    }, [routeData.length]);

    useEffect(() => {
        if (isLoggedIn) {
            window["zESettings"] = {
                webWidget: {
                    offset: {horizontal: '100px', vertical: '150px'},
                    chat: {
                        connectOnPageLoad: false
                    }
                }
            };
            let bd = document.getElementById("zenDeskChat");
            let script = document.createElement('script');
            script.src = `https://static.zdassets.com/ekr/snippet.js?key=3743c967-e14b-49cc-a77e-cbb36b3bf99d`;
            script.id = "ze-snippet";
            bd?.append(script);
        }
    }, [])

    useEffect(() => {
        if (google_tag_manager_id && google_analytic_id) {
            addGoogleScript(google_tag_manager_id, google_analytic_id);
        }
    }, [google_tag_manager_id, google_analytic_id]);

    const addGoogleScript = (googleTagManagerId: string, googleAnalyticId: string) => {
        if(!document.getElementById('googleTagManagerId')) {
            const script = document.createElement('script');
            script.id = 'googleTagManagerId';
            script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${googleTagManagerId}');`;
            document.head.appendChild(script);

            const noscript = document.createElement('noscript');
            const iframe = document.createElement('iframe');
            iframe.src = `https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`;
            iframe.height = '0';
            iframe.width = '0';
            iframe.style.display = 'none';
            iframe.style.visibility = 'hidden';
            noscript.appendChild(iframe);
            document.body.prepend(noscript);
        }

        if(!document.getElementById('googleAnalyticId')) {
            const googleAnalyticScriptSrc = document.createElement('script');
            googleAnalyticScriptSrc.id = 'googleAnalyticId';
            googleAnalyticScriptSrc.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticId}`;
            googleAnalyticScriptSrc.async = true;
            document.body.appendChild(googleAnalyticScriptSrc);

            const googleAnalyticScript = document.createElement('script');
            googleAnalyticScript.innerHTML = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleAnalyticId}');`;
            document.body.appendChild(googleAnalyticScript);
        }
    }

    return (
        <>
            {isChangeLang && <PageLoader/>}

            <footer className={`footer ${className}`}>
                <div className="container">
                    <div className="footer-center">
                        <div className={`footer-link`}>
                            <div className="footer-link_item">

                                <a target='_blank' rel={"noopener noreferrer"}
                                   href={config.zendesk_help_url}
                                   className="link mr-4">{translateV2("LABEL.HELP")}</a>

                                <Link to="/contact"
                                      className="link">{translateV2("LABEL.CONTACT_US")}</Link>
                            </div>
                            <div className="footer-link_item">
                                {termUrl && <Link to={`/${termUrl}`}
                                                  className="link mr-4">{translateV2('LABEL.TERMS_CONDITIONS')}</Link>}
                                {policyUrl && <Link to={`/${policyUrl}`}
                                                    className="link">{translateV2('LABEL.PRIVACY_POLICY')}</Link>}
                            </div>
                        </div>
                    </div>
                    <div className="footer-right">
                        <div className="selector">

                            {
                                _.size(langData.listLanguages) === 1 &&
                                <p className="f14 mb-0">
                                    <span className="active">{langData.listLanguages[0].code.toUpperCase()}</span>
                                </p>
                            }

                            {
                                _.size(langData.listLanguages) > 1 &&
                                <Language curLang={langData.lang} listLang={langData.listLanguages}
                                          handleChange={changeLang}/>
                            }
                        </div>
                    </div>
                    <div className="footer-left">
                        {translateV2("LABEL.ALL_RIGHTS_RESERVED")} {year}
                    </div>
                </div>

                <div id="zenDeskChat"></div>

            </footer>

            {
                location.pathname === APP_ROUTES_PATH.DASHBOARD && <FooterPackage className={"is-sticky-footer d-none d-lg-flex"}/>
            }

        </>
    );
}
export default Footer;
