import {axiosRequest} from "@src/helpers";
import {AUTH} from "@api/Auth";
import _ from "lodash-es";

export const SITE_API = {
    getSites: (data: any = {}) => {
        const {signal, ...params} = data;
        let options = {
            method: 'GET',
            params, signal,
            headers: {...AUTH.getHeader()},
        } as any;
        return axiosRequest('/sites', options, true, false);
    },

    createSite: (data: object = {}) => {
        return axiosRequest('/sites', {
            method: 'POST',
            data: data,
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    getSiteByID: (id: string, cancelToken: any = {}) => {

        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        } as any;

        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/sites/${id}`, options, true, false);
    },

    updateSite: (id: string, data: object = {}) => {
        return axiosRequest(`/sites/${id}`, {
            method: 'POST',
            data: data,
            headers: {...AUTH.getHeader()},
        });
    },

    deleteSite: (id: string) => {
        return axiosRequest(`/sites/${id}`, {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    restoreSite: (id: string) => {
        return axiosRequest(`/sites/${id}/restore`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },
}