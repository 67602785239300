import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import _ from "lodash-es";

const initialState = {
    isLoggedIn: false,
    token: ""
}

type AppConfigType = typeof initialState;

export const appProviderSlice = createSlice({
    name: 'appProvider',
    initialState,
    reducers: {
        setState: (state, action: PayloadAction<any>) => {
            _.merge(state, action.payload)
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        // .addCase(getBasicConfigAsync.fulfilled, (state, action) => {
        //
        // });
    },
});

export const {setState} = appProviderSlice.actions;

export const appProviderState = (state: RootState) => state.appProvider;

export default appProviderSlice.reducer;
