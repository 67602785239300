import {E_AUDIT_CLAIMED_STATUS, StepTabs} from "@src/types";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

export const checkIcon = () => {
    return <span className={"mr-1"}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
         fill="none"
         style={{clipPath: "url(#clip0_2_654)"}}>
    <g>
        <path
            d="M11.0597 5.0535L6.66634 9.44683L4.27301 7.06016L3.33301 8.00016L6.66634 11.3335L11.9997 6.00016L11.0597 5.0535ZM7.99967 1.3335C4.31967 1.3335 1.33301 4.32016 1.33301 8.00016C1.33301 11.6802 4.31967 14.6668 7.99967 14.6668C11.6797 14.6668 14.6663 11.6802 14.6663 8.00016C14.6663 4.32016 11.6797 1.3335 7.99967 1.3335ZM7.99967 13.3335C5.05301 13.3335 2.66634 10.9468 2.66634 8.00016C2.66634 5.0535 5.05301 2.66683 7.99967 2.66683C10.9463 2.66683 13.333 5.0535 13.333 8.00016C13.333 10.9468 10.9463 13.3335 7.99967 13.3335Z"
            fill="#525252"/>
    </g>
    <defs>
    <clipPath id="clip0_2_654">
    <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
        </svg></span>
}

export const getOrderProcessScanType = (status: E_AUDIT_CLAIMED_STATUS) => {
    let badgeClassName = "";
    let filterStatus = "";
    let icon: React.ReactNode;

    switch (status) {
        case E_AUDIT_CLAIMED_STATUS.CLAIMED:
            badgeClassName = "fib-badge-audit-claimed"
            icon = checkIcon()
            filterStatus = "Claimed"
            break;
        case E_AUDIT_CLAIMED_STATUS.VERIFIED:
            badgeClassName = "fib-badge-audit-verified"
            icon = <FontAwesomeIcon icon={faInfoCircle} className={`mr-1`}/>
            filterStatus = "Unclaimed"
            break;
        case E_AUDIT_CLAIMED_STATUS.DISPUTE:
            badgeClassName = "fib-badge-audit-disputed"
            icon = checkIcon()
            filterStatus = "Verified"
            break;
        case E_AUDIT_CLAIMED_STATUS.SELF_REPORTED:
            badgeClassName = "fib-badge-audit-selfreport"
            icon = checkIcon()
            filterStatus = "Verified"
            break;
    }
    return {badgeClassName, icon, filterStatus};
}

export const canViewStep = (status: number, custom_date: string) => {
    return Boolean((status === Number(StepTabs.UPDATED) || (StepTabs.MISSED && custom_date)))
}
