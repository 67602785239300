import React from 'react';

interface IStaticPageHOC {
    title: string,
    content: string
}

const StaticPageHOC = ({title = '', content = ''}: IStaticPageHOC) => {
    return <>
        <div className="static-page_header">
            <div className="container">
                {title && <h1 className="title-primary">{title}</h1>}
            </div>
        </div>
        <div className="static-page_body">
            <div className="container" dangerouslySetInnerHTML={{__html: content}}/>
        </div>
    </>
}

export default StaticPageHOC;
