import {Middleware} from '@reduxjs/toolkit';
import {store} from "@redux/hooks/store";
import {
  cancelPendingCompanyObjAsync,
  deleteAuditTemplateAsync, downloadAuditCSV,
  downloadOrderFiles,
  publishTransactionCertificate,
  savePendingCompanyObjAsync,
  updateUserAndLogoutAsync
} from "@redux/hooks/actions";
import {fetchOrderHistoriesAsync, fetchOrderStatsAsync, fetchOrderSummaryAsync} from "@redux/reducers/orderDetailPage";

const fibretraceMiddleware: Middleware = (storeAPI) => (next) => (action) => {

  if (action.type === savePendingCompanyObjAsync.typePrefix) {
    return store.dispatch(savePendingCompanyObjAsync(action.value))
  }

  if (action.type === downloadOrderFiles.typePrefix) {
    return store.dispatch(downloadOrderFiles(action.value));
  }

  if (action.type == deleteAuditTemplateAsync.typePrefix) {
    return store.dispatch(deleteAuditTemplateAsync(action.value))
  }

  if (action.type == publishTransactionCertificate.typePrefix) {
    return store.dispatch(publishTransactionCertificate(action.value))
  }

  if (action.type == updateUserAndLogoutAsync.typePrefix) {
    return store.dispatch(updateUserAndLogoutAsync(action.value))
  }

  if (action.type === cancelPendingCompanyObjAsync.typePrefix) {
    return store.dispatch(cancelPendingCompanyObjAsync(action.value))
  }

  if (action.type == fetchOrderSummaryAsync.typePrefix) {
    return store.dispatch(fetchOrderSummaryAsync(action.value))
  }

  if (action.type == fetchOrderStatsAsync.typePrefix) {
    return store.dispatch(fetchOrderStatsAsync(action.value))
  }

  if (action.type == fetchOrderHistoriesAsync.typePrefix) {
    return store.dispatch(fetchOrderHistoriesAsync(action.value))
  }

  if (action.type == downloadAuditCSV.typePrefix) {
    return store.dispatch(downloadAuditCSV(action.value))
  }

  return next(action);
};

export default fibretraceMiddleware;
