export const handleFooter = () => {
    // console.log("FibreTrace footer sticky");

    const footer = document.querySelector("footer");
    const main = document.querySelector("main");


    footer && footer.classList.remove("fixed");

    if (footer && main) {

        let heightWillFix = window.innerHeight - 70 - footer.clientHeight;

        document.documentElement.style.setProperty('--footer-height', `${footer?.clientHeight || 50}px`);

        if (main.clientHeight < heightWillFix) {
            footer.classList.add("fixed");
        } else {
            footer.classList.remove("fixed");
        }
        return main.clientHeight;
    }

    return 0;
}
