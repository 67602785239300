import React from 'react';

interface I_Loading {
    style?: Object
}

const Loading = ({style = {}}: I_Loading) => {
    return (
        <div className="fibre-loading" style={style}/>
    );
}

export default Loading;
