import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {Message} from "@src/types";
import {DEFAULT_MODAL} from "@src/variables";

export interface MessageInterface {
    isShow: boolean
    title: string | any
    message: string | any
    messageData?: any
    type: Message
    size?: "sm" | "lg" | "xl"
}

const initialState: MessageInterface = {
    ...DEFAULT_MODAL, size: "lg",
}

export const wizardChildSlice = createSlice({
    name: 'wizardChild',
    initialState,
    reducers: {
        showMessage: (state, action: PayloadAction<MessageInterface>) => {
            state = {
                ...action.payload, isShow: true
            }
            return state
        },
        hideMessage: (state) => {
            state.isShow = false;
            return state
        }
    },
});

export const wizardChildState = (state: RootState) => state.wizardChild;

export default wizardChildSlice.reducer;
