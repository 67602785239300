import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";
import axios from "axios";

export const SCANNER_API = {
    getScanners: ({params = {}, cancelToken = {}}) => {
        let options = {
            method: 'GET',
            params,
            headers: {...AUTH.getHeader()}
        } as any
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken;
        return axiosRequest(`/company/scanners`, options)
    },
    getScannersV2: (data: any = {}) => {
        try {
            const {signal, ...params} = data

            let options = _.omitBy({
                method: 'GET',
                params,
                signal,
                headers: {...AUTH.getHeader()}
            }, _.isNil) as any

            return axiosRequest(`/company/scanners`, options)
        } catch (error) {
            if (axios.isCancel(error)) {
                console.error('Request canceled: getScanners', error.message);
            } else {
                console.error('Request error: getScanners', error.message);
            }
        }
    },
    transferScanner: ({id, data = {}, cancelToken = {}}) => {
        // const form_data = objectToFormData(data);
        let options = {
            method: 'PUT',
            // body: form_data,
            data: data,
            headers: {...AUTH.getHeader()},
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            //     'Authorization': AUTH.getBearer()
            // }
        } as any
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken;
        return axiosRequest(`/company/scanner/transfer/${id}`, options, true, false)
    },

    createScanner: ({data = {}, cancelToken = {}}) => {
        let options = {
            method: 'POST',
            data: data,
            headers: {...AUTH.getHeader()},
        } as any
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken;
        return axiosRequest(`/company/scanner`, options, true, false)
    },


}

export const getConditionList = () => {
    const fipt = AUTH.getToken();
    return axiosRequest('/get-list-scanner-status-condition', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        }
    });
};

export const postScanner = (data: any) => {
    const fipt = AUTH.getToken();
    return axiosRequest('/post-auditor-update-scanner', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + fipt
        },
    }, true, false);
};