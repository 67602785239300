import {APP_ROUTES_PATH} from "@src/routes";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "@redux/hooks/hooks";
import {companyState} from "@redux/reducers/companySlice";
import _ from "lodash-es";
import {createSearchQuery, navigateUrlStr, translateV2} from "@src/helpers";
import {useNavigate} from "react-router-dom";

const FooterPackage = ({className = ""}) => {

  const company = useAppSelector(companyState)
  const history = useNavigate();
  const isVerifiedPackage = _.includes(_.toUpper(company.current_subscription.name), "VERIFIED")
  const [show, setShow] = useState(false);

  const navigateToPackagePage = () => {
    history(navigateUrlStr({
      pathname: APP_ROUTES_PATH.PROFILE,
      search: createSearchQuery({t: "PACKAGE"})
    }), {replace: true})
  }

  useEffect(() => {
    if (company.company_code && !isVerifiedPackage) {
      setShow(true);
    }
  }, [company.company_code, company.current_subscription.name]);

  if (!show) return null;

  return <>

    <div className={`fibre-packages-upgrade ${className}`} onClick={() => {
      navigateToPackagePage()
    }}>

      <div className={"d-flex flex-column fibre-packages-upgrade-body"}>
        <h6 className={"fw-600"}>{translateV2("LABEL.PACKAGE.UPGRADE_CURRENT.UPGRADE_TO_VERIFIED")}</h6>
        <p className={"mb-0"}>{translateV2("LABEL.PACKAGE.UPGRADE_CURRENT.CTA_TEXT")}</p>
      </div>

      <span className={"fibre-packages-upgrade-arrow"}></span>

    </div>

  </>

}


export default FooterPackage
