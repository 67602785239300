import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPONENT_INTERACTION, COMPONENT_SEARCH_QUERIES, PAGING_DATA, QUERIES,} from "@src/types";
import {APP_ROUTES_NAME} from "@src/routes";
import {DEFAULT_VARIABLES} from "@src/variables";
import _ from "lodash-es";
import {PROCESS_API} from "@api/Process";

const initialState: COMPONENT_INTERACTION = {
    name: APP_ROUTES_NAME.COMPANY,
    search: {
        ...DEFAULT_VARIABLES.SEARCH_SETTINGS,
        queries: {
            ...DEFAULT_VARIABLES.SEARCH_SETTINGS.queries,
            sort: 'asc',
            sort_by: 'name',
        }
    },
    loading: false,
    data: [] as Array<any>
}

export const fetchCompanyProcessAsync = createAsyncThunk(
    'process/fetch',
    async (searchQueries: COMPONENT_SEARCH_QUERIES, {getState}) => {
        try {
            const {queries, selected} = searchQueries;
            const r = await PROCESS_API.getBatch(_.omitBy(queries, _.isNil));

            if (r) {
                let {data = [], meta, error} = r;
                if (data && !error) {
                    return {data, meta, queries, selected}
                }
            }

            return {data: [], meta: {}, queries, selected}
        } catch (e) {
            console.error(e)
            return {data: [], meta: {}, queries: searchQueries.queries, selected: searchQueries.selected}
        }
    }
);

export const processSlice = createSlice({
    name: 'process',
    initialState,
    reducers: {
        resetState: (state) => {
            state.search.queries = initialState.search.queries
        },
        setProcessState: (state, action: PayloadAction<COMPONENT_INTERACTION>) => {
            Object.assign(state, action.payload);
        },
        setProcessSearch: (state, action: PayloadAction<any>) => {
            state.search = action.payload
        },
        setProcessData: (state, action: PayloadAction<[]>) => {
            state.data = action.payload
        },
        setProcessQueries: (state, action: PayloadAction<QUERIES>) => {
            state.search.queries = action.payload
        },
        setProcessPaging: (state, action: PayloadAction<PAGING_DATA>) => {
            state.search.pagingData = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanyProcessAsync.pending, (state, action) => {
                state.loading = true
            })
            .addCase(fetchCompanyProcessAsync.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(fetchCompanyProcessAsync.fulfilled, (state, action) => {
                const {data, meta, queries, selected} = action.payload
                const total_pages = Math.ceil(meta.total / queries.number_per_page)

                if (!_.size(data) && queries.page > 1) {
                    queries.page = (meta.current_page - 1);
                }

                state.data = data
                state.search.queries = _.merge(state.search.queries, queries)
                state.search.pagingData = {...meta, total_pages}
                state.loading = false;
            })

        ;
    },
});

export const {
    setProcessData, setProcessSearch, setProcessQueries, setProcessPaging, setProcessState,
} = processSlice.actions;

export const processState = (state: RootState) => state.process;

export default processSlice.reducer;
