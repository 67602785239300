import React from 'react';
import ReactDOM from 'react-dom';
import '@src/styles/styles.scss';
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import App from '@src/App';
import * as serviceWorker from './serviceWorker';
import {persistor, store} from '@redux/hooks/store';
import RModal from 'react-modal';
import {AndroidInterface} from "@src/types";
import {PersistGate} from 'redux-persist/integration/react';


declare global {
    interface Window {
        AndroidInterface: AndroidInterface
        zESettings: any
        device: any
        setScanner(modelNumber?: any): void;
        setScannedData(result_age_last_tau_last_int: any): void;
        setScannedDataRaw(data: any): void;
        handleDisconnectScanner():void
    }
}

window.handleDisconnectScanner = async () => {
    try {
        console.info('Your scanner has timed out, please ensure the Scanner is Powered on and Connect it again to continue scanning!');
    } catch (e) {
        console.error(e)
    }
}

RModal.setAppElement('#root');

ReactDOM.render(

    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </BrowserRouter>,

    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
