import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "@redux/hooks/store";

export const routerSlice = createSlice({
    name: 'router',
    initialState: {
        previous: {
            path: '',
            search: ''
        },
        current: {
            path: '',
            search: ''
        }
    },
    reducers: {
        setPrevious: (state, action: PayloadAction<any>) => {
            console.log("set previous redirect url")
            state.previous = action.payload
        },
        setCurrent: (state, action: PayloadAction<any>) => {
            console.log("set current url")
            state.current = action.payload
        },
    },
})

export const routerState = (state:RootState) => state.router
export default routerSlice.reducer;