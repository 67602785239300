import {APP_ROUTES_NAME} from "@src/routes";
import {GuideMenuCollection} from "@redux/reducers/guideSlice";
import {UserIntroInterface} from "@redux/reducers/userSlice";

export interface ERROR_POPUP {
    error: false | any,
    message: string | any,
    type?: Message
}

export interface COMMON_POPUP {
    isShow: boolean
    title: string
    message: string
    type: Message
}

interface ISelect {
    id: number,
    value?: number,
    label?: string
}

export enum UserGroup {
    brand = 'brand',
    auditor = 'auditor',
    user = 'user'
}

export enum UserGroupCode {
    BRAND = '1',
    AUDITOR = '2',
    USER = '0'
}

export enum InputRules {
    REQUIRED = 'required',
    EMAIL = 'isEmail',
    EMAIL_CONFIRMATION = 'isEmailConfirmation',
    PASSWORD = 'isPassword',
    CONFIRM_PASSWORD = 'confirm_password',
    FIBRETRACE_ID = 'fibretrace_id',
    URL = 'isUrl',
    DOMAIN = 'isDomain',
    HTML = "html",
    SWITCHER = "switcher",
    ADDRESS = "address",
    PHONE = "phone",
    PHONE_OPTIONAL = "phone_optional",
    REQUIRED_FIBRETRACE_ID = "required_fibretrace_id",
    NUMBER_OF_AUDITS = "number_of_audits",
}

export enum EnergyTypes {
    SOLAR = 'SOLAR',
    WIND = 'WIND',
    UNKNOWN = 'UNKNOWN'
}

export enum Message {
    NEUTRAL = 'neutral',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

export enum OrderTabs {
    ACTIVE = '1',
    COMPLETED = '2',
    ALL = '0'
}

export enum OrderTabsNAME {
    ACTIVE = 'ACTIVE_ORDERS',
    COMPLETED = 'COMPLETED_ORDERS',
    ALL = 'ALL_ORDERS'
}

export enum StepTabs {
    UPCOMING = 1,
    UPDATED = 2,
    MISSED = 3,
    ALL = 0
}

export enum EOrderProcessStatus {
    SCANNED = 2,
    MISSED = 3
}


export enum AuditTabs {
    RECEIVED = "RECEIVED",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
    UPCOMING = 1,
    PREVIOUS = 2,
    PENDING = 3,
    MISSED = 4,
    CLAIMED   = 5
}

export enum AuditTabsV2 {
    PENDING_AUDITS = "PENDING_AUDITS",
    UPCOMING_AUDITS = "UPCOMING_AUDITS",
    PREVIOUS_AUDITS = "PREVIOUS_AUDITS",
    MISSED_AUDITS = "MISSED_AUDITS",
    RECEIVED_AUDITS = "RECEIVED_AUDITS",
    ACCEPTED_AUDITS = "ACCEPTED_AUDITS",
    REJECTED_AUDITS = "REJECTED_AUDITS",
    CLAIM_DISPUTE = "CLAIM_DISPUTE",
}

export enum AuditPages {
    MY_AUDIT = "MY_AUDIT",
    AUDIT_TEMPLATES = "AUDIT_TEMPLATES"
}

export enum Grade {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F'
}

export interface ITabs {
    value: string | number,
    display: string
}

export interface ITabsV1 {
    value: string | number,
    display: string,
    click: (any) => void
}

export interface IGaugeChartDetailData {
    reduce_increase: number
    grade: Grade | null,
    recorded: number | null
}

export interface IGaugeChartData {
    water: IGaugeChartDetailData
    carbon: IGaugeChartDetailData
    energy: IGaugeChartDetailData
}

export enum IGaugeChartKey {
    WATER = 'water',
    CARBON = 'carbon',
    ENERGY = 'energy'
}

export interface IProcessMaps {
    marker: {
        lat: number | any,
        lng: number | any
    },
    name: string
}


export enum Icon {
    FA = 'fa',
    IMG = 'img'
}

export interface IPOSMapProps {
    mapRef?: any;
    onMapMounted?: () => void
    orderCode: string
    data: any
}

export enum BlockchainType {
    BC_UNVERIFIED = 'unverified',
    BC_MATCHED = 'matched',
    BC_MISMATCHED = 'mismatched',
    BC_MISSED = 'missed'
}

export enum USER_ACTION {
    DEFAULT = 'DEFAULT',
    VIEW = 'VIEW',
    DELETE = 'DELETE',
    EDIT = "EDIT",
    CREATE = "CREATE",
    RESTORE = "RESTORE",
    ENABLE = "ACTIVE",
    DISABLE = "DISABLE",
    SET_AS_ADMIN = "SET_AS_ADMIN",
    REVOKE_ADMIN = "REVOKE_ADMIN",
    REVOKE_PARTNERSHIP = "REVOKE_PARTNERSHIP",
    BUY_SCANNER = "BUY_SCANNER",
    TRANSFER_SCANNER = "TRANSFER_SCANNER",
    INVITE_USERS = "INVITE_USERS",
    BUY_AUDIT_CREDITS = "BUY_AUDIT_CREDITS",
    INVITE_PARTNERS = "INVITE_PARTNERS",
    READ = "READ",
    UPDATE = "UPDATE",
    SHARE = "SHARE",
    AUDIT = "AUDIT",
    CLONE_ORDER = "CLONE_ORDER",
    ORDER_CLONE = "ORDER_CLONE",
    ORDER_MARK_COMPLETED = "ORDER_MARK_COMPLETED",
}

export enum CompanyTabs {
    INFORMATION = 'INFORMATION',
    SITES = 'SITES',
    PROCESSES = 'PROCESSES',
    USERS = 'USERS',
    PARTNERS = 'PARTNERS',
}

export enum ProductTabs {
    INFORMATION = 'INFORMATION',
}

export enum ProductPageTabs {
    PRODUCTS = 'PRODUCTS',
    INVENTORY = 'INVENTORY',
    TEMPLATES = 'TEMPLATES'
}

export enum AccountPageTabs {
    ACCOUNT = 'ACCOUNT',
    NOTIFICATIONS = 'NOTIFICATIONS',
    PACKAGE = 'PACKAGE',
    BILLING = 'BILLING',
    SERVICES = 'SERVICES'
}

export enum WebProtocols {
    HTTP = "http",
    HTTPS = "https"
}

export enum MEDIA_TYPE {
    COMPANY_LOGO = "COMPANY_LOGO",
    PRODUCT = "PRODUCT",
    PROCESS_IMAGE = "PROCESS_IMAGE",
    PROCESS_VIDEO = "PROCESS_VIDEO",
    PROCESS_CERTIFICATE = "PROCESS_CERTIFICATE",
    PRODUCTS_CSV = "PRODUCTS_CSV",
    TEMPLATE = "TEMPLATE",
    PARTNERS_IMPORT = "PARTNERS_IMPORT",
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD H:MM A';
export const MOMENT_DATETIME_FORMAT = 'YYYY-MM-DD hh:mm a';

export enum VIEW_MODE {
    LIST = "LIST",
    GRID = "GRID",
    TABLE = "TABLE",
    MAP = "MAP"
}

export interface IScanner {
    auditor: any,
    id: any,
    code: any,
    type: any,
    auditor_name: any,
    status: any,
    assigned_date: any,
    condition: any,
    name: any,
    characteristic: any,
    condition_id: any,
    service: any,
    status_id: any,
}

// Scanner settings
export const SCANNER_CONDITION = [
    {name: "HEALTHY", label: "HEALTHY", value: 1, id: 1},
    {name: "FAULTY", label: "FAULTY", value: 2, id: 2},
    {name: "DAMAGED", label: "DAMAGED", value: 3, id: 3},
    {name: "OTHER", label: "OTHER", value: 4, id: 4},
]

export const SCANNER_TYPES = [
    {name: "PROTOTYPE", label: "PROTOTYPE", value: 1, id: 1},
    {name: "BLUETOOTH", label: "BLUETOOTH", value: 2, id: 2},
    {name: "OFFLINE", label: "OFFLINE", value: 3, id: 3},
    {name: "USB", label: "USB", value: 4, id: 4},
    {name: "OTHER", label: "OTHER", value: 5, id: 5},
]

export const SCANNER_STATUS = [
    {name: "UNASSIGNED", label: "UNASSIGNED", value: 1, id: 1},
    {name: "ASSIGNED", label: "ASSIGNED", value: 2, id: 2},
    {name: "ASSIGNED DEFAULT", label: "ASSIGNED DEFAULT", value: 4, id: 4},
]


export interface SEARCH_QUERIES {
    selected?: string | any,
    queries: QUERIES,
    pagingData: PAGING_DATA,
}

export interface QUERIES {
    page: any,
    number_per_page: any,
    status: any,
    search: any,
    keyword: any,
    sort: any,
    sort_by: any,
    is_paginate?: boolean
    tags?: [] | any
    is_archived?: [] | any

    [key: string]: any
}

export interface QUERIES_FILTER {
    page?: any,
    number_per_page?: any,
    status?: any,
    search?: any,
    keyword?: any,
    sort?: any,
    sort_by?: any,
    is_paginate?: boolean
    tags?: [] | any
    is_archived?: [] | any
    [key: string]: any

}

export interface PAGING_DATA {
    current_page: any,
    from: any,
    last_page: any,
    path: any,
    per_page: any,
    to: any,
    total: any,
    total_pages: any
}

export enum QUESTION_TYPE {
    DECIMAL = "DECIMAL",
    INTEGER = "INTEGER",
    STRING = "STRING",
    DATE_PICKER = "DATE_PICKER",
    DATETIME_PICKER = "DATETIME_PICKER",
    FILE_UPLOAD = "FILE_UPLOAD",
    PERCENTAGE = "PERCENTAGE",
    DROPDOWNLIST = "DROPDOWNLIST",
    CALCULATION = "CALCULATION"
}

export enum QUESTION_TYPE_LABEL {
    DECIMAL = "DECIMAL",
    INTEGER = "NUMBER",
    STRING = "TEXT",
    DATE_PICKER = "DATE_PICKER",
    DATETIME_PICKER = "DATETIME_PICKER",
    FILE_UPLOAD = "FILE_UPLOAD",
    PERCENTAGE = "PERCENTAGE",
    DROPDOWNLIST = "DROP-DOWN LIST",
    CALCULATION = "CALCULATION"
}

export interface FORM_STATUS {
    isValid: boolean,
    processing: boolean,
    message: string,
    messageType: Message,
}

export interface PARENT_COMPONENT {
    isModalShow: false,
    action: USER_ACTION,
    formStatus: FORM_STATUS
    form: any,
    item: object | any,
}

export interface QUESTION {
    ref: any,
    id: string,
    name: string,
    tooltip: string,
    answer_type: string | QUESTION_TYPE,
    is_required: boolean,
    is_public: boolean,
    is_expand: boolean,
}

export interface AUDIT_RECEIVER {
    company: string
    email: string
    id: any
    name: string
    uid: string
}

export enum AUDIT_STATUS {
    PENDING = "PENDING",
    PROCESSED = "PROCESSED"
}

export interface PROCESS_AUDIT {
    auditor_name: string
    uid?: any
    process_name: any
    order_code: any
    audit_client: string
    audit_id: string
    audit_template_content: any
    bc_status?: BlockchainType
    can_be_sent: true
    can_be_transferred: false
    due_date: string
    fibretrace_id: string
    fibretrace_id_label?: string | null
    id: number | string
    input_products: any
    manufacturer_address: string
    manufacturer_lat: string
    manufacturer_location: string
    manufacturer_long: string
    manufacturer_name: string
    name: string
    need_bc_check: boolean
    order_id: string
    order_reference_id: string
    process_carbon: string
    process_code: string
    process_energy: string
    process_id?: any,
    process_position: number | string
    process_uid: string
    process_water: string
    product_image: string
    product_name: string
    quantity: string
    product_description: string | any
    receiver: AUDIT_RECEIVER | null
    requires_fibretrace_id_when_audit: number | string
    status?: AUDIT_STATUS | any
    scanned_times_and_scan_count: string
    unique_str: string
    can_be_audited: null | boolean,
    is_last_stage_audit?: boolean,
    previous_audit_has_been_sent: boolean | null
    terminology: string | null | ""
    product_uid: string | null | ""
    is_required_each_audit_scan?: null | boolean
    site: any
    site_previous: any
    process_name_previous: any
    order_owner_email: any
    documents: [] | null
    site_address: any
    site_latitude: any
    site_longitude: any
    requires_st_scanner_when_audit: boolean

}

export interface AUDIT_SUMMARY {
    next_process_id: null | string
    order_code: null | string
    prev_process_id: null | string
    process_due_date: null | string
    process_lat: null | string
    process_location: null | string
    process_long: null | string
    process_manufacturer_description: null | string
    process_manufacturer_email: null | string
    process_manufacturer_name: null | string
    process_manufacturer_phone: null | string
    process_manufacturer_url: null | string
    process_name: null | string
    process_pos_description: null | string
    process_position: null | string | number
    process_scanned_by: null | string
    process_scanned_date: null | string
    product_image: null | string | any
}

/**
 * For quick navigation
 */

export enum MENU_ITEM {
    profile = 'profile',
    company = 'company',
    products = 'products',
    orders = 'orders',
    audits = 'audits',
    analysis = 'analysis',
    scanners = 'scanners',
    contact = 'contact',
}

export interface QUICK_NAVIGATION_ITEM {
    path: string
    label: string
    pin: boolean | string
}

export interface QUICK_NAVIGATION {
    profile: QUICK_NAVIGATION_ITEM
    company: QUICK_NAVIGATION_ITEM
    products: QUICK_NAVIGATION_ITEM
    orders: QUICK_NAVIGATION_ITEM
    audits: QUICK_NAVIGATION_ITEM
    analysis: QUICK_NAVIGATION_ITEM
    scanners: QUICK_NAVIGATION_ITEM
    contact: QUICK_NAVIGATION_ITEM
}

export enum COMPONENT_STATUS {
    BEFORE_INITIALING = "BEFORE_INITIALING",
    INITIALING = "INITIALING",
    INITIALIZED = "INITIALIZED",
    NOT_INITIALIZED = "NOT_INITIALIZED",
    READY = "READY",
    FINISH = "FINISH"
}

export interface ORDER_SUMMARY {
    order_code: string
    order_date: string
    order_quantity: number
    order_image: string
    manufacturer_name: string
    manufacturer_location: string
    order_process_total: number
    order_process_scanned: number
    order_process_percentage: number
    manufacturer_long: string
    manufacturer_lat: string
    manufacturer_url: string
    order_b2c_url: string,
    new_order_b2c_url: string,
    qr_image: string,
    qr_image_name: string,
    is_completed: boolean,
    fibretrace_id: number | null
    product_name: string
    reference_id: string | null
    retail_date: string | null
    connected_orders: number
    product_tags: string[] | null
    alpha2Code: string
    uom: string
}

export interface FIBRETRACE_IDS {
    id: number | any,
    code: string | any,

    [key: string]: any
}

export interface UserInterface {
    uid?: any
    accept_marketing: boolean | number | null
    brand: string
    can_be_deleted: boolean | number | null
    can_be_disabled: boolean | number | null
    company: string
    deleted: boolean | number | null
    email: string
    first_login_and_first_user: boolean | number | null
    first_name: string
    id: number | null | string
    is_company_admin: boolean | number | null | undefined
    job_role: string | null
    job_role_id: number | null
    lang: string
    last_name: string
    manufacturer: string
    name: null | any
    need_2_fa: boolean
    phone: string
    receive_notify: number | boolean | null
    role: string
    settings: null | any | { guideMenu: GuideMenuCollection, intro: UserIntroInterface }
    status: number
    token: null | string
    notification_emails: object | null | any
    show_contact_details: boolean | null
    can_be_edited_name?: boolean
}

export interface CompanySocial {
    type: "LinkedIn" | "Twitter" | "YouTube" | "Facebook" | "Instagram" | "Snapchat" | "Pinterest" | "TikTok"
    social_url: string
}

export interface FibSelectInterface {
    isDisabled: boolean
    isLoading: boolean
    isClearable: boolean
    isSearchable: boolean
    list: [] | any
    selected: any
}


export interface COMPONENT_SETTINGS {
    status: COMPONENT_STATUS
    processing: boolean
    isValid: boolean
    messageType: Message
    message: any
    action: USER_ACTION | any
    form: Object
    object: Object
    extra?: any
    result?: any
}

export interface COMPONENT_INTERACTION {
    name: APP_ROUTES_NAME
    search: SEARCH_QUERIES
    action?: USER_ACTION
    loading: boolean
    data: Array<Object | any>
    selectedItem?: Object | any
}

export interface COMPONENT_SEARCH_QUERIES {
    selected: string | any,
    queries: QUERIES
}

export enum AUDIT_TERMINOLOGY {
    SEND = "send",
    DISABLE_SEND = "disable_send",
    RESEND = "resend",
    CROSS = "cross",
    EXCLAMATION = "exclamation",
    LIKE = "like"
}

export interface AndroidInterface {
    postAccessToken(token: string): void;

    appLogout(): void;

    requestBluetoothDevice(): void;

    getScannerData(): void;

    testScannerConnection(modelNumber: string): void;

    updateAuditsList(): void;
}

export enum INVOICE_STATUS {
    PAID = 'paid',
    PENDING = 'pending'
}

export enum CommercialInvoiceType {
    ORDER_SCANNER = "ORDER_SCANNER",
    BUY_CREDITS = "CREDITS",
    UPGRADE_PACKAGE = "UPGRADE_PACKAGE"
}

export enum E_CLAIM_COMPANY_CHOICE {
    EMPTY = "EMPTY",
    YES = "YES",
    NO = "NO"
}

export enum E_AUDIT_CLAIMED_STATUS {
    CLAIMED = "CLAIMED"
    , SELF_REPORTED = "SELF_REPORTED"
    , DISPUTE = "DISPUTE"
    , VERIFIED = "VERIFIED"
}