/**
 * An enumeration representing the metadata keys for a user.
 *
 * @typedef {Object} UserMetadata
 * @property {string} IS_FIRST_LOGIN - Indicates if the user has logged in for the first time.
 * @property {string} IS_DONE_COMPANY_SETUP - Indicates if the user has completed the company setup.
 * @property {string} IS_DONE_IMPORT_PARTNER - Indicates if the user has completed the partner import.
 * @property {string} IS_DONE_WIZARD_SETUP - Indicates if the user has completed the wizard setup.
 * @property {string} IS_DONE_INTRO - Indicates if the user has completed the introduction.
 */
export enum E_USER_METADATA {
    IS_FIRST_LOGIN = "is_first_login",
    IS_DONE_COMPANY_SETUP = "is_done_company_setup",
    IS_DONE_IMPORT_PARTNER = "is_done_import_partner",
    IS_DONE_WIZARD_SETUP = "is_done_wizard_setup",
    IS_DONE_INTRO = "is_done_intro",
}

export enum E_COMPANY_METADATA {
    IS_SETUP_DONE = "is_setup_done",
    IS_CLAIM_DISPUTE_REQUIRED = "is_claim_dispute_required",
}

/**
 * Represents the metadata of a user.
 * @interface
 */
export interface I_METADATA_GETTER {
    object: "USER" | "COMPANY"
    key?: Array<E_USER_METADATA | E_COMPANY_METADATA>
}

export interface I_METADATA_SETTER {
    object: "USER" | "COMPANY"
    key: E_USER_METADATA | E_COMPANY_METADATA,
    value: string | number
}

export interface I_METADATA_RESPONSE {
    data?: Array<{ key: string, value: string }>
    error?: string[] | string
    message?: string[] | string
}

export interface I_METADATA_ITEM_RESPONSE {
    data?: { key: string, value: string }
    error?: string[] | string
    message?: string[] | string
}


//
// example response:
// {
//     "data": [
//     {
//         "key": "is_done_company_setup",
//         "value": "1"
//     }
//     ]
// }