import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import _ from "lodash-es";

interface GuideMenuData {
    current: number
    total: number
    show: boolean
    page: "help" | "dashboard" | "company" | "products" | "orders" | "audits" | "analysis"
}

export interface GuideMenuCollection {
    help: GuideMenuData,
    dashboard: GuideMenuData
    company: GuideMenuData
    products: GuideMenuData
    orders: GuideMenuData
    audits: GuideMenuData
    analysis: GuideMenuData
}

export const initialGuideMenuState: GuideMenuCollection = {
    help: {
        current: 1,
        total: 4,
        show: !_.isNull(localStorage.getItem("guide_menu_help_visibility")) ? Boolean(JSON.parse(localStorage.getItem("guide_menu_help_visibility") + "")) : true,
        page: "help"
    },
    dashboard: {
        current: 1,
        total: 8,
        show: !_.isNull(localStorage.getItem("guide_menu_dashboard_visibility")) ? Boolean(JSON.parse(localStorage.getItem("guide_menu_dashboard_visibility") + "")) : true,
        page: "dashboard"
    },
    company: {
        current: 1,
        total: 6,
        show: !_.isNull(localStorage.getItem("guide_menu_company_visibility")) ? Boolean(JSON.parse(localStorage.getItem("guide_menu_company_visibility") + "")) : true,
        page: "company"
    },
    products: {
        current: 1,
        total: 4,
        show: !_.isNull(localStorage.getItem("guide_menu_products_visibility")) ? Boolean(JSON.parse(localStorage.getItem("guide_menu_products_visibility") + "")) : true,
        page: "products"
    },
    orders: {
        current: 1,
        total: 3,
        show: !_.isNull(localStorage.getItem("guide_menu_orders_visibility")) ? Boolean(JSON.parse(localStorage.getItem("guide_menu_orders_visibility") + "")) : true,
        page: "orders"
    },
    audits: {
        current: 1,
        total: 3,
        show: !_.isNull(localStorage.getItem("guide_menu_audits_visibility")) ? Boolean(JSON.parse(localStorage.getItem("guide_menu_audits_visibility") + "")) : true,
        page: "audits"
    },
    analysis: {
        current: 1,
        total: 3,
        show: !_.isNull(localStorage.getItem("guide_menu_analysis_visibility")) ? Boolean(JSON.parse(localStorage.getItem("guide_menu_analysis_visibility") + "")) : true,
        page: "analysis"
    },
}

export const GuideSlice = createSlice({
    name: 'router',
    initialState: initialGuideMenuState,
    reducers: {
        setGuideMenu: (state, action: PayloadAction<GuideMenuCollection>) => {
            state = {...action.payload}
            return state
        },
        setGuideMenuByPage: (state, action: PayloadAction<GuideMenuData>) => {
            let {page} = action.payload;
            localStorage.setItem(`guide_menu_${page}_visibility`, `${Number(action.payload.show)}`);
            state = {
                ...state,
                [page]: {...action.payload}
            }
            return state
        }
    },
});

export const {setGuideMenu, setGuideMenuByPage} = GuideSlice.actions;

export const guideMenuState = (state: RootState) => state.guide;

export default GuideSlice.reducer;
