import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";

export const NOTIFICATION_API = {

    BASE: "/user/notifications",

    getBatch: async (params: any = {}, cancelToken: any = {}) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params,
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return await axiosRequest(`${NOTIFICATION_API.BASE}`, options, true, false);
    },

    getOne: async (params: any = {}, cancelToken: any = {}) => {
        const {id} = params;
        if (id) {
            let options = {
                method: 'GET',
                headers: {...AUTH.getHeader()}
            } as any;
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return await axiosRequest(`${NOTIFICATION_API.BASE}/${id}`, options, true, false);
        }
        return false;
    },

    markAllAsRead: async (params: any = {}, cancelToken: any = {}) => {
        let options = {
            method: 'POST',
            headers: {...AUTH.getHeader()}
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return await axiosRequest(`${NOTIFICATION_API.BASE}/mark-all-as-read`, options, true, false);
    },

}