import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPANY_API} from "@api/Company";

export interface CompanyTypeItemInterface {
    id: number | string | null
    value?: number | string | null
    name: number | string | null
    label?: number | string | null
}

const initialState: Array<CompanyTypeItemInterface> = []

export const getCompanyTypeAsync = createAsyncThunk(
    'company/getTypes',
    async () => {
        const response = await COMPANY_API.getCompanyTypes();
        return response.data;
    }
);

export const companyTypeSlice = createSlice({
    name: 'types',
    initialState,
    reducers: {
        setTypes: (state, action: PayloadAction<Array<CompanyTypeItemInterface>>) => {
            let ns = [...action.payload].map((i: any) => {
                const {id: value, name: label} = i;
                return {...i, value, label}
            })
            state = [...ns]
            return state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyTypeAsync.fulfilled, (state, action) => {
                let ns = [...action.payload].map((i: any) => {
                    const {id: value, name: label} = i;
                    return {...i, value, label}
                })
                state = [...ns]
                return state
            })
    },
});

export const {} = companyTypeSlice.actions;

export const companyTypeState = (state: RootState) => state.companyType;

export default companyTypeSlice.reducer;
