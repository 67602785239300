import {fibLogger} from '@src/helpers';

export const AndroidInterfaceHelper = {

    postAccessToken: (token: string) => {
        try {
            if (window.AndroidInterface) {
                window.AndroidInterface.postAccessToken(token);
            }
            fibLogger("send access token to android")
            return token;
        } catch (e) {
            fibLogger(e.message, "error")
            return false
        }
    },

    appLogout: () => {
        try {
            if (window.AndroidInterface) {
                window.AndroidInterface.appLogout();
            }
            fibLogger("logout from webview")

            return true;
        } catch (e) {
            fibLogger(e.message, "error")
            return false
        }
    },

    requestBluetoothDevice: () => {
        try {
            if (window.AndroidInterface) {
                window.AndroidInterface.requestBluetoothDevice();
            }
            fibLogger("requestBluetoothDevice")
            return true;
        } catch (e) {
            fibLogger(e.message, "error")
        }
    },

    getScannerData: () => {
        try {
            if (window.AndroidInterface) {
                window.AndroidInterface.getScannerData();
            }
            fibLogger("getScannerData")
            return true;
        } catch (e) {
            fibLogger(e.message, "error")
        }
    },

    testScannerConnection: (modelNumber:any) => {
        try {
            if (window.AndroidInterface) {
                window.AndroidInterface.testScannerConnection(modelNumber);
            }
            fibLogger("testScannerConnection")
            return true;
        } catch (e) {
            fibLogger(e.message, "error")
        }
    },

    updateAuditsList: () => {
        try {
            if (window.AndroidInterface) {
                window.AndroidInterface.updateAuditsList()
            }
            fibLogger("updateAuditsList")
            return true;
        } catch (e) {
            fibLogger(e.message, "error")
        }
    },

}

