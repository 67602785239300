import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";
import axios from "axios";

export const ORDER_API = {
    getOrders: (params: any = {}, cancelToken: any) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params,
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/orders`, options);
    },

    getOrderSummary: (code: string) => {
        return axiosRequest(`/get-order-detail-summary?order_code=${code}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        });
    },

    getOrderDetail: (code: string) => {
        return axiosRequest(`/orders/${code}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        });
    },

    getOrderHistory: (code: string) => {
        return axiosRequest(`/get-order-detail-history?order_code=${code}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    getOrderChart: (code: string) => {
        if (code) {
            return axiosRequest(`/get-order-chart?order_code=${code}`, {
                method: 'GET',
                headers: {...AUTH.getHeader()},
            });
        }
        return false;
    },

    getProcessSummary: (code: string, position: string) => {
        if (code && position) {
            return axiosRequest(`/get-process-detail?order_code=${code}&process_position=${position}`, {
                method: 'GET',
                headers: {...AUTH.getHeader()},
            });
        }
        return false
    },

    getProcessPreview: (code: string, lang: string) => {
        return axiosRequest(`/admin-preview-manufacturer-process?code=${code}&lang=${lang}`, {
            method: 'GET'
        });
    },

    getProcessEco: (code: string, position: string) => {
        if (code && position) {
            return axiosRequest(`/get-process-eco?order_code=${code}&process_position=${position}`, {
                method: 'GET',
                headers: {...AUTH.getHeader()},
            });
        }
        return false;
    },

    getProcessMedia: (code: string, position: string) => {
        if (code && position) {
            return axiosRequest(`/get-process-media?order_code=${code}&process_position=${position}`, {
                method: 'GET',
                headers: {...AUTH.getHeader()},
            });
        }
        return false;
    },

    getAuditCSV: (data: { code: string }) => {
        return axiosRequest('/download-audits-data', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {...AUTH.getHeader()},
        });
    },

    getOrderFiles: (data: { code: string }) => {
        return axiosRequest('/download-order-completed-data', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {...AUTH.getHeader()},
        });
    },

    createOrder: (data: any) => {
        const {fibretrace_id} = data;
        if (fibretrace_id < 0) data.fibretrace_id = null;
        return axiosRequest('/orders', {
            method: 'POST',
            body: data,
            headers: {...AUTH.getHeader()},
        });
    },

    updateOrder: (code: string, data: any) => {
        return axiosRequest(`/orders/${code}`, {
            method: 'POST',
            body: data,
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    getOrderByCode: (code: string, cancelToken = {}) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken;
        return axiosRequest(`/orders/${code}`, options);
    },

    deleteOrderByCode: (code: string, cancelToken = {}) => {
        let options = {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/orders/${code}`, options, true, false);
    },

    createOrderProcess: (orderID: string, data: any) => {
        return axiosRequest(`/orders/${orderID}/processes`, {
            method: 'POST',
            body: data,
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    getProcessByID: (orderCode: string, id: string) => {
        if (orderCode && id) {
            return axiosRequest(`/orders/${orderCode}/processes/${id}`, {
                method: 'GET',
                headers: {...AUTH.getHeader()},
             },true,false);
        }
        return false;
    },

    updateOrderProcess: (orderCode: string, id: string, data: any) => {
        return axiosRequest(`/orders/${orderCode}/processes/${id}`, {
            method: 'POST',
            body: data,
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    deleteOrderProcess: (orderCode: string, processID: string) => {
        if (orderCode && processID) {
            return axiosRequest(`/orders/${orderCode}/processes/${processID}`, {
                method: 'DELETE',
                headers: {...AUTH.getHeader()},
            });
        }
        return false;
    },

    getOrderProductsInventory: (params: any = {}) => {
        const {orderCode, signal} = params;
        if (orderCode) {
            let options = {
                method: 'GET',
                headers: {...AUTH.getHeader()},
                params, signal,
            } as any;
            return axiosRequest(`/orders/${orderCode}/input-products`, options, true, false);
        }
        return false;
    },

    duplicateOrder: async (data: { product_id: number | string, order_code: string }, cancelToken = {}) => {
        let {order_code} = data;
        if (order_code) {
            let options = {
                method: 'POST',
                data,
                headers: {...AUTH.getHeader()},
            } as any
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return axiosRequest('/duplicate-order', options, true, false);
        }
        return false;
    },

    getOrderHistoryV2: ({order_code, status = null, isSort = null}: {
        order_code?: string,
        status?: null | string | undefined,
        isSort?: null | string | undefined | boolean
    }) => {
        return axiosRequest(`/get-order-detail-history`, {
            method: 'GET',
            params: _.omitBy({order_code, status, isSort}, _.isNil),
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    archiveOrder: async (data: any = {}) => {
        try {
            let {id, signal} = data;
            let options = _.omitBy({
                method: 'POST',
                signal,
                headers: {...AUTH.getHeader()},
            }, _.isNil) as any

            return axiosRequest(`/orders/${id}/archive`, options, false, false);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.error("cancel archiveOrder", error)
            } else {
                console.error("archiveOrder", error)
            }
        }
    },

    getOrderStats: (data: { order_code: string, signal?: AbortSignal }): Promise<I_OrderProgressResponse> => {
        const {order_code, signal} = data;
        return axiosRequest(`/order-stat/${order_code}`, {
            method: 'GET', signal,
            headers: {...AUTH.getHeader()},
        });
    },


    getConnectedOrders: (data: { process_id: number | string, signal?: AbortSignal }) => {
        const {process_id, signal} = data;
        return axiosRequest(`/connected-orders/${process_id}`, _.omitBy({
            method: 'GET', signal,
            headers: {...AUTH.getHeader()},
        }, _.isNil), true, false);
    },

    getProcessByIDV2: (data: { orderCode: string, id: string, signal?: AbortSignal }) => {
        const {orderCode, id, signal} = data;
        if (orderCode && id) {
            return axiosRequest(`/orders/${orderCode}/processes/${id}`, _.omitBy({
                method: 'GET', signal,
                headers: {...AUTH.getHeader()},
            }, _.isNil));
        }
        return false;
    },

    getOrderSummaryV2: ({code, signal}: { code: string, signal?: AbortSignal }) => {

        return axiosRequest(`/get-order-detail-summary?order_code=${code}`, _.omitBy({
            method: 'GET', signal,
            headers: {...AUTH.getHeader()},
        }, _.isNil));
    },

    getOrderDetailV2: ({code, signal}: { code: string, signal?: AbortSignal }) => {
        return axiosRequest(`/orders/${code}`, _.omitBy({
            method: 'GET', signal,
            headers: {...AUTH.getHeader()},
        }, _.isNil));
    },

}

export interface I_OrderProgress {
    order_process_total: number;
    order_process_scanned: number;
    order_process_percentage: number;
}

export interface I_OrderProgressResponse {
    data: I_OrderProgress
    error?: string | string[]
}