import React from 'react';

const LoadingRowV2 = ({style = {} as any, styleLoader = {} as any, fixed = false, className = ""}) => {
    return (
        <div style={{minHeight: "60px"}} className={`position-relative`}>
            <div style={style} className={`block-loading-container ${fixed ? " fixed " : ""} ${className}`}>
                <div style={styleLoader} className="block-loading"/>
            </div>
        </div>
    )
}

export default LoadingRowV2;
