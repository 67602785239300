import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {DEFAULT_MODAL} from "@src/variables";
import {ConfirmationMessageInterface} from "@src/types/modal";

export enum EImportPartnersSteps {
    CHOOSE_FILE = "CHOOSE_FILE",
    VALIDATE_FILE = "VALIDATE_FILE",
    PREVIEW = "PREVIEW",
    IMPORTING = "IMPORTING",
    IMPORT_DONE  = "IMPORT_DONE",

    INVITATION  = "INVITATION",
    INVITING  = "INVITING",
    INVITE_DONE  = "INVITE_DONE",
}

export interface ImportPartnersInterface extends ConfirmationMessageInterface {
}

export interface ImportSinglePartnerInterface {
    "company_name": string | any,
    "company_email": string | any,
    "supplier_phone_number": string | any,
    "supplier_hq_postal_address": string | any,
    "supplier_process_name": string | any,
    "supplier_process_postal_address": string | any,
    "process_description": string | any,
    "allow_import": boolean,
    "note": string | any,
}

const initialState: ImportPartnersInterface = {
    ...DEFAULT_MODAL, size: "lg",
    confirmAction: "",
    cancelAction: "",
    chainConfirmActions: [],
    chainCancelActions: [],
    confirmButton: {
        visible: true,
        variant: "success",
        label: "Yes"
    },
    cancelButton: {
        visible: true,
        variant: "outline-dark",
        label: "No"
    },
    children: null,
    // only for import partners
    // isShow: true,
}

export const importPartnersSlice = createSlice({
    name: 'importPartners',
    initialState,
    reducers: {
        setState: (state, action) => {
            Object.assign(state, action.payload)
        },
        setTitle: (state, action) => {
            state.title = action.payload.title
        },
        show: (state) => {
            state.isShow = true
            // state.step = EImportPartnersSteps.CHOOSE_FILE
        },
        hide: (state) => {
            state.isShow = false
        },
    },
});

export const importPartnersState = (state: RootState) => state.importPartners;
export default importPartnersSlice.reducer
