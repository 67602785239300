import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMMON_API} from "@api/Common";
import {appStaticRoutes} from "@src/variables";

/**
 * Related to SetRoute in App.tsx.
 * Unclear usage
 */
export interface RouteState {
    routes: Array<[] | unknown>
}

const initialState: RouteState = {
    routes: []
}

export const getStaticRoutesAsync = createAsyncThunk(
    'appRoutes/getStaticRoutes',
    async ({lang, signal}: { lang: string | "", signal?: AbortSignal }) => {
        try {
            const {data = []} = await COMMON_API.getStatics({lang, signal});
            return data
        } catch (e) {
            console.error(e)
            return appStaticRoutes;
        }
    }
);
export const appRoutesSlice = createSlice({
    name: 'appRoutes',
    initialState,
    reducers: {
        setRoute: (state, action: PayloadAction<RouteState>) => {
            state.routes = action.payload.routes
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStaticRoutesAsync.fulfilled, (state, action) => {
                state.routes = action.payload;
                state.routes.push({type: 'fe-not-found'})
            });
    },
});

export const {setRoute} = appRoutesSlice.actions;

export const appRoutesState = (state: RootState) => state.appRoutes;

export default appRoutesSlice.reducer;
