import React, {useEffect, useState} from 'react';
import "react-datetime/css/react-datetime.css";
import {NOTIFICATION_API} from "@api/Notification";
import _ from "lodash-es";
import Loading from "@components/Loading/Loading";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {readNotification} from "@redux/reducers/notificationSlice";

const NotificationDetail = ({
                                id = "" as (number | string),
                                data = {} as any,
                                onRead = {} as any,
                            }: any) => {
    const langData = useAppSelector(languageState);
    const dispatch = useAppDispatch();

    const [init, setInit] = useState(0);
    const [notification, setNotification] = useState({} as any)

    const handleNotification = {
        getOne: async () => {
            try {
                const {data, message, error} = await NOTIFICATION_API.getOne({id});
                if (!error && !_.isEmpty(data)) {
                    setNotification({...notification, ...data});
                    dispatch(readNotification({...notification, ...data}))
                    if (_.isFunction(onRead)) onRead(data);
                }
            } catch (e) {
                console.error(e.toString())
            }
        },
    }

    useEffect(() => {
        (async () => {
            if (!init) {
                if (id) await handleNotification.getOne();
                setInit(1);
            }
        })()
    }, [id])

    useEffect(() => {
        (async () => {
            if (!init) {
                if (!_.isEmpty(data)) setNotification({...notification, ...data})
                dispatch(readNotification({...notification, ...data}))
                setInit(1);
            }
        })()
    }, [data])

    return (
        <>
            {!init && <Loading/>}
            <div className={"email-body"} dangerouslySetInnerHTML={{__html: notification.message}}/>
        </>
    );
}

export default NotificationDetail;
