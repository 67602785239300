import React, {useState} from 'react';

interface ILanguage {
  name: string,
  code: string
}

const Language = ({curLang, handleChange, listLang = []}: any) => {
  const [isOpen, setOpen] = useState(false);
  const handleToggle = () => {
    if(!isOpen) {
      document.addEventListener('click', outsideClickListener);
    } else {
      document.removeEventListener('click', outsideClickListener);
    }
    setOpen(!isOpen);
  }

  const outsideClickListener = (event:any) => {
    const classList = Object.values(event.target.classList);
    const isClickOut = classList.find((item: any) => ['language_list', 'language_item'].includes(item));
    if(!isClickOut) {
      document.removeEventListener('click', outsideClickListener);
      setOpen(false);
    }
  }

  return (
    <div className={`language${isOpen ? ' open' : ''}`} >
      <div className="input-group language_btn" onClick={handleToggle}>
        <div className="input-group-prepend">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path className="prefix__earth" d="M17.9 17.39A2.006 2.006 0 0 0 16 16h-1v-3a1 1 0 0 0-1-1H8v-2h2a1 1 0 0 0 1-1V7h2a2 2 0 0 0 2-2v-.41a7.982 7.982 0 0 1 2.9 12.8M11 19.93a7.962 7.962 0 0 1-6.79-9.72L9 15v1a2 2 0 0 0 2 2m1-16a10 10 0 1 0 10 10A10 10 0 0 0 12 2z" transform="translate(-2 -2)"/>
          </svg>
        </div>
        <div className="form-control">{curLang}</div>
        <div className="input-group-append">
          { isOpen ?
            <svg xmlns="http://www.w3.org/2000/svg" width="10.582" height="5.569" viewBox="0 0 10.582 5.569">
                <path className="prefix__angle-up" d="M5.291 5.569L10.582 0H0z" transform="rotate(180 5.291 2.785)" />
            </svg> :
            <svg xmlns="http://www.w3.org/2000/svg" width="10.581" height="5.569" viewBox="0 0 10.581 5.569">
              <path className="prefix__angle-down" d="M5.291 0l5.291 5.569H0z" transform="rotate(180 5.29 2.785)" />
            </svg>
          }
        </div>
      </div>
      <ul className="language_list blist">
        {
          listLang.map((lang: ILanguage) => <li key={lang.code} className="language_item blist_item"><button className="blist_btn" onClick={() => handleChange(lang.code)} disabled={lang.code.toLowerCase() === (curLang && curLang.toLowerCase())}>{lang.name}</button></li>)
        }
      </ul>
    </div>
  );
}

export default Language;
