import React, {ReactNode, useEffect} from 'react';
import Footer from '@components//Footer/Footer';
import Header from '@components//Header/Header';
import LanguageHOC from '@components/HOCs/LanguageHOC/LanguageHOC';
import {useAppSelector} from '@redux/hooks/hooks';
import {languageState} from "@redux/reducers/languageSlice";
import {companyState} from "@redux/reducers/companySlice";

interface ITemplateSecondaryHOC {
    children: ReactNode,
    className?: string,
    mainClass?: string
}

const TemplatePrimaryHOC = ({children, className = '', mainClass = ''}: ITemplateSecondaryHOC) => {

    const langData = useAppSelector(languageState);
    const company = useAppSelector(companyState)

    const footer = document.querySelector("footer");
    const main = document.querySelector("main");

    useEffect(() => {

        const bodyEl = document.querySelector("body");
        if (bodyEl) {
            bodyEl.classList.add("body-primary");
        }

        return () => {
            if (bodyEl) {
                bodyEl.classList.remove("body-primary");
            }
        };
    }, [langData]);

    useEffect(() => {
        if (company.company_code) {
            document.documentElement.style.setProperty('--footer-height', `${footer?.clientHeight || 50}px`);
        }
    }, [company.company_code]);

    return (
        <>
            <LanguageHOC>

                <div className={`primary-page ${className}`}>
                    <Header/>

                    <main className={mainClass}>
                        {children}
                    </main>

                    <Footer/>
                </div>

            </LanguageHOC>

        </>
    )
}

export default TemplatePrimaryHOC;
