import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import _ from "lodash-es";
import {userLogoutAsync} from "@redux/reducers/userSlice";

export interface BlockingState {
    status: 'idle' | 'blocking' | 'failed', // for future use
    waiting: boolean,
    style: object
}

const initialState: BlockingState = {
    status: 'idle',
    waiting: false,
    style: {}
}

export const blockStateFunc = (state, action) => {
    document.getElementsByTagName("body")[0].classList.add('panel-open');
    state.status = "blocking";
    state.waiting = true;
    if (action) state.style = !_.isEmpty(action?.payload?.style) ? action?.payload?.style : {};
}

export const unblockStateFunc = (state, action) => {
    document.getElementsByTagName("body")[0].classList.remove('panel-open');
    state.status = "idle";
    state.waiting = false;
    if (action) state.style = !_.isEmpty(action?.payload?.style) ? action?.payload?.style : {};
}

export const blockSlice = createSlice({
    name: 'panel',
    initialState,
    reducers: {
        block: (state, action?) => {
            blockStateFunc(state, action);
        },
        unblock: (state, action?) => {
            unblockStateFunc(state, action);
        }
    },
    extraReducers: (builder) => {
        builder
            // .addCase(fetchUserCompanyAsync.pending, (state, action?) => {
            //     blockStateFunc(state, action);
            // })
            // .addCase(fetchUserCompanyAsync.rejected, (state, action?) => {
            //     unblockStateFunc(state, action);
            //
            // })
            // .addCase(fetchUserCompanyAsync.fulfilled, (state, action?) => {
            //     unblockStateFunc(state, action);
            // })
        // log out
            .addCase(userLogoutAsync.pending, (state, action?) => {
                blockStateFunc(state, action);
            })
            .addCase(userLogoutAsync.rejected, (state, action?) => {
                unblockStateFunc(state, action);
            })
            .addCase(userLogoutAsync.fulfilled, (state, action?) => {
                // unblockStateFunc(state, action);
            })
        ;
    },
});

export const {block, unblock} = blockSlice.actions;

export const blockStore = (state: RootState) => state.block;
export const blockState = (state: RootState) => state.block.waiting;

export default blockSlice.reducer;
