import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {DEFAULT_PANEL_SETTINGS} from "@src/variables";

export enum EComponents {
    ORDER_FORM = "ORDER_FORM",
    PRODUCT_DETAIL = "PRODUCT_DETAIL",
    PARTNER_INVITATION = "PARTNER_INVITATION",
    USER_INVITATION = "USER_INVITATION",
    ORDER_PROCESS = "ORDER_PROCESS",
}

export interface PanelState {
    loading: boolean,
    show: boolean,
    header: string,
    component?: EComponents,
    componentProps?: Object,
    meta: object | any
    settings: any
    afterClose: "" | {} | Object[]
}

const initialState: PanelState = {
    loading: true,
    show: false,
    header: "",
    componentProps: {},
    meta: {},
    settings: {...DEFAULT_PANEL_SETTINGS},
    afterClose: ""
}

export const submitClosePanelAsync = createAsyncThunk(
    'panel/submitCloseAsync',
    async (arg: { callback?: () => Promise<any> }, {dispatch}) => {
        try {
            let callbackResult = null;

            if (arg.callback) {
                callbackResult = await arg.callback();
            }

            return {panelClosed: true, callbackResult};
        } catch (e) {
            console.error(e);
            return {panelClosed: false, callbackResult: null};
        }
    }
);

export const panelSlice = createSlice({
    name: 'panel',
    initialState,
    reducers: {
        init: (state, action) => {
            Object.assign(state, action.payload)
            state.loading = true;
            state.show = true;
        },
        show: (state, action) => {
            Object.assign(state, action.payload)
            state.loading = false;
            state.show = true;
        },
        hide: (state) => {
            state.loading = false;
            state.show = false;
            state.component = undefined;
        },
        hideWithAction: (state, action) => {
            Object.assign(state, action.payload)
            state.loading = false;
            state.show = false;
            state.component = undefined;
        },
        reset: (state) => {
            state = initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitClosePanelAsync.pending, (state, action) => {
                state.loading = true

            })
            .addCase(submitClosePanelAsync.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(submitClosePanelAsync.fulfilled, (state, action) => {
                state.loading = false
                state.show = false
                state.component = undefined;
            })

        ;
    },
});

export const {init, show, hide} = panelSlice.actions;

export const panelState = (state: RootState) => state.panel;

export default panelSlice.reducer;
