import {ENV} from "../environments/environment";
import {InputRules, Message, WebProtocols} from '@src/types';
import axios from 'axios';
import _ from "lodash-es";
import {AUTH} from "@src/api/Auth";
import {APP_ROUTES_NAME, APP_ROUTES_PATH} from "./routes";
import {store} from '@redux/hooks/store';
import {blockSlice} from "@redux/reducers/blockSlice";
import {handleFooter} from "@components/Footer/helper";
import {fetchNotificationAsync, notificationSlice} from "@redux/reducers/notificationSlice";
import {DEFAULT_APP_CONFIG} from "@src/variables";
import {USER_API} from "@api/User";
import {SITE_API} from "@api/Site";
import {PROCESS_API} from "@api/Process";
import {PARTNER_API} from "@api/Partner";
import {PRODUCTS_API} from "@api/Product";
import {PRODUCT_TEMPLATE_API} from "@api/ProductTemplate";
import {ORDER_API} from "@api/Order";
import {AUDIT_TEMPLATE_API} from "@api/AuditTemplate";
import {AUDIT_API} from "@api/Audit";
import {SCANNER_API} from "@api/Scanner";
import {getAnalysisList, getDraftList} from "@api/Analysis";
import {NOTIFICATION_API} from "@api/Notification";
import {ApiRequest} from "@src/types/api";
import {sessionExpiredSlice} from "@redux/reducers/sessionExpiredSlice";
import {redirect, redirectDocument} from "react-router-dom";
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';


export const validateEmail = (email: string) => /^[a-zA-Z0-9]+([.+_-]?[a-zA-Z0-9]+)*@([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,63}$/.test(email);
export const invalidAddress = (address: string) => /[\\:*?!"+=_|]/.test(address)
export const validatePhone = (phone: string) => /^(?!\+\d*\+)[+]?\d{8,}$/.test(phone)
export const validatePhoneOptional = (phone: string) => /^(?:(?!\+\d*\+)[+]?\d{8,})?$/.test(phone)
export const labelPattern = /^[A-Z_]+(\.[A-Z0-9_]+)*$/

export const validate = (value: any, rules: string[], options: any = {}) => {
    let isValid = true;
    let error = '';

    if (_.isArray(rules) && _.size(rules) > 0) {
        rules.forEach((rule: string) => {
            switch (rule) {
                case InputRules.REQUIRED: {
                    isValid = !_.isBoolean(value) ? Boolean(value) : value;
                    if (!isValid) {
                        error = 'ERROR.FIELD_REQUIRED';
                    }
                    break;
                }

                case InputRules.EMAIL: {
                    // if(isValid){
                    value = _.escape(_.trim(value))
                    isValid = validateEmail(value);
                    if (!isValid) {
                        error = 'ERROR.EMAIL_DESCRIPTION';
                    }
                    // }

                    break;
                }

                case InputRules.EMAIL_CONFIRMATION: {
                    value = _.escape(_.trim(value))
                    if (isValid) {
                        if (value !== options.email) {
                            isValid = false;
                            error = 'ERROR.EMAIL_NOT_MATCH';
                        }
                    }

                    break;
                }

                case InputRules.PASSWORD: {
                    const lowerCaseLetters = /[a-z]/g;
                    const upperCaseLetters = /[A-Z]/g;
                    const numbers = /[0-9]/g;
                    if (isValid) {
                        if (value.length < 6) {
                            isValid = false;
                            error = 'ERROR.PASSWORD_LENGTH';
                        } else if (!(value.match(lowerCaseLetters))) {
                            isValid = false;
                            error = 'ERROR.PASSWORD_CHARACTER';
                        } else if (!(value.match(numbers))) {
                            isValid = false;
                            error = 'ERROR.PASSWORD_NUMBER';
                        } else if (!(value.match(upperCaseLetters))) {
                            isValid = false;
                            error = 'ERROR.PASSWORD_CAPITAL';
                        }
                    }
                    break;
                }

                case InputRules.CONFIRM_PASSWORD: {
                    if (isValid) {
                        if (value !== options.pass) {
                            isValid = false;
                            error = 'ERROR.PASSWORD_NOT_MATCH';
                        }
                    }
                    break;
                }

                case InputRules.FIBRETRACE_ID: {
                    if (isValid) {
                        if (options.fib_id === 'ERROR.FID_INVALID') {
                            isValid = false;
                            error = options.fib_id;
                        }
                    }
                    break;
                }

                case InputRules.URL: {
                    if (isValid) {
                        if (value && !isValidURL(value)) {
                            isValid = false;
                            error = 'ERROR.URL_WRONG_FORMAT';
                        }
                    }
                    break;
                }

                case InputRules.DOMAIN: {
                    value = stripProtocol(value)
                    if (isValid) {
                        if (value && !isValidURL(value)) {
                            isValid = false;
                            error = 'ERROR.URL_WRONG_FORMAT';
                        }
                    }
                    break;
                }

                case InputRules.HTML: {
                    if (isValid) {

                    }
                    break;
                }

                case InputRules.SWITCHER: {
                    isValid = true
                    value = !!value;
                    break;
                }

                case InputRules.ADDRESS: {
                    if (isValid) {
                        isValid = !invalidAddress(value);
                        if (!isValid) {
                            error = 'ERROR.ADDRESS_CONTAINS_SPECIAL_CHARACTERS'
                        }
                    }
                    break;
                }

                case InputRules.PHONE: {

                    if (isValid) {
                        isValid = validatePhone(value);
                        console.log('value', value, isValid)

                        if (!isValid) {
                            error = 'ERROR.VALIDATE.PHONE_NUMBER_ONLY_CAN_CONTAINS_PLUS_SIGN_AND_NUMBER'
                        }
                    }
                    break;
                }

                case InputRules.PHONE_OPTIONAL: {

                    if (isValid) {
                        isValid = validatePhoneOptional(value);

                        if (!isValid) {
                            error = 'ERROR.VALIDATE.PHONE_NUMBER_ONLY_CAN_CONTAINS_PLUS_SIGN_AND_NUMBER'
                        }
                    }
                    break;
                }

                case InputRules.REQUIRED_FIBRETRACE_ID: {
                    isValid = !_.isBoolean(value) ? Boolean(value) : value;
                    if (!isValid) {
                        error = 'ERROR.REQUIRED_FIBRETRACE_ID';
                    }
                    break;
                }

                case InputRules.NUMBER_OF_AUDITS: {
                    isValid = Number(value) > 0;
                    if (!isValid) {
                        error = 'ERROR.NUMBER_OF_AUDITS_MIN';
                    }
                    break;
                }
            }
        });
    }

    return {
        isValid,
        error,
        value
    };
};

export const formatParams = (obj: any) => {
    return Object.keys(obj).map(key => _.escape(key) + '=' + _.escape(obj[key])).join('&');
}

export const clean = (obj: any) => {
    _.forIn(obj, function (value, key) {
        if (_.isNil(value)) delete obj[key];
    });
}

export const translate = (bundle: any, code: any, defaultText: string = code, variables: any = {}) => {
    try {
        const {search} = window.location;
        if (/debug/.test(search)) return code;
        if (bundle && code) {
            // console.log('translate bundle', bundle);
            let value: any;

            if (_.isString(code)) {
                if (_.has(bundle, code?.toUpperCase())) {
                    value = bundle[code?.toUpperCase()];
                } else {
                    addMissingLabel(code)
                    value = code;
                }
            }
            if (_.isArray(code)) {
                value = _.join(_.map(code, (v, k) => {
                    if (!_.has(bundle, v?.toUpperCase())) {
                        addMissingLabel(v?.toUpperCase())
                    }
                    return "<span class='error'>" + (bundle[v?.toUpperCase()] || v) + "&#46;</span>";
                }), "");
            }

            if (_.isUndefined(value)) {
                return defaultText;
            } else {
                if (_.isEmpty(variables)) {
                    return value;
                } else {
                    let output = value;
                    _.forIn(variables, function (varValue, varKey) {
                        output = output.replace(new RegExp(`\\{${varKey}\\}`, 'g'), varValue);
                    });
                    return output;
                }
            }
        }
        return code;
    } catch (e) {
        console.error(e, code, typeof code, bundle);
        return code;
    }
};

export const translateV2 = (code: any, defaultText: string = code, variables: any = {}) => {
    try {

        const gStore = store.getState();
        const bundle = gStore.language.localize;
        const userMetadata = gStore.userMetadata;
        const companyMetaStore = gStore.companyMeta;

        // if (!userMetadata.initialized || !companyMetaStore.initialized) return "";
        if (!bundle) return code;

        const {search} = window.location;
        if (/debug/.test(search)) return code;
        if (bundle && code) {
            let preValue: any, value: any;

            if (_.isString(code)) {
                if (_.has(bundle, code?.toUpperCase())) {
                    value = bundle[code?.toUpperCase()];
                } else {

                    addMissingLabel(code)

                    value = code;
                }
                preValue = value;
            }
            if (_.isArray(code)) {

                preValue = _.map(code, (v, k) => {
                    if (!_.has(bundle, v?.toUpperCase())) {
                        addMissingLabel(v?.toUpperCase())
                    }
                    return {code: v, text: "<span class='error'>" + (bundle[v?.toUpperCase()] || v) + "&#46;</span>"}
                })

                value = _.join(_.map(code, (v, k) => {
                    return "<span class='error'>" + (bundle[v?.toUpperCase()] || v) + "&#46;</span>";
                }), "");
            }

            if (_.isUndefined(value)) {
                return defaultText;
            } else {
                if (_.isEmpty(variables) && !_.isFunction(variables)) {
                    return value;
                } else {
                    let output = value;

                    if (_.isObject(variables)) _.forIn(variables, function (varValue, varKey) {
                        output = output.replace(new RegExp(`\\{${varKey}\\}`, 'g'), varValue);
                    });

                    if (_.isArray(preValue) && _.isFunction(variables)) {
                        let msg = "";
                        for (const t of preValue) {
                            msg += variables(t);
                        }
                        return msg;
                    }

                    return output;
                }
            }
        }
        return code;
    } catch (e) {
        return code;
    }
};

export const translateV2Plain = (code: any, defaultText: string = code, variables: any = {}) => {
    try {
        const gStore = store.getState();
        const bundle = gStore.language.localize;
        if (!bundle) return code;

        const {search} = window.location;
        if (/debug/.test(search)) return code;
        if (bundle && code) {
            let preValue: any, value: any;

            if (_.isString(code)) {
                if (_.has(bundle, code?.toUpperCase())) {
                    value = bundle[code?.toUpperCase()];
                } else {
                    value = code;
                }
                preValue = value;
            }
            if (_.isArray(code)) {

                preValue = _.map(code, (v, k) => {
                    return {code: v, text: (bundle[v?.toUpperCase()] || v) + "."}
                })

                value = _.join(_.map(code, (v, k) => {
                    return (bundle[v?.toUpperCase()] || v) + ".";
                }), "");
            }

            if (_.isUndefined(value)) {
                return defaultText;
            } else {
                if (_.isEmpty(variables) && !_.isFunction(variables)) {
                    return value;
                } else {
                    let output = value;

                    if (_.isObject(variables)) _.forIn(variables, function (varValue, varKey) {
                        output = output.replace(new RegExp(`\\{${varKey}\\}`, 'g'), varValue);
                    });

                    if (_.isArray(preValue) && _.isFunction(variables)) {
                        let msg = "";
                        for (const t of preValue) {
                            msg += variables(t);
                        }
                        return msg;
                    }

                    return output;
                }
            }
        }
        return code;
    } catch (e) {
        return code;
    }
};

export const translateMessage = (bundle: any, mes: any, defaultText: string = "") => {
    if (typeof mes === "object" && mes.length !== undefined) { // mes is array
        const arrMes = mes.map((m: string) => translate(bundle, m.toUpperCase(), defaultText));
        return arrMes.join(', ');
    } else if (typeof mes === "string") {
        return translate(bundle, mes.toUpperCase(), defaultText);
    } else {
        return mes;
    }
}

interface IImg {
    image_position: number,
    image_url: string
}

export const formatImgUrl = (arrImg: IImg[], position: number) => {
    const imgObj = arrImg.find((img: IImg) => img.image_position === position)
    if (imgObj) {
        return imgObj.image_url
    }

    return '';
}

export const getLengthDescription = (str: string) => {
    try {
        if (!str) return 0;
        const noHTML = str.replace(/<\/?[^>]+(>|$)/g, "");
        const arrWord = noHTML.split(" ");
        return arrWord.length;
    } catch (e) {
        console.error(e)
        return 0;
    }
}

export const formatShortDescription = (str: string, number: number = 100) => {
    try {
        if (!str) return "";
        const noHTML = str.replace(/<\/?[^>]+(>|$)/g, "");
        const arrWord = noHTML.split(" ");
        arrWord.length = number;
        return arrWord.join(" ");
    } catch (e) {
        console.error(e)
        return "";
    }
}

export function getParams(urlParams: string = '') {
    let url;
    let location;
    let queryString;
    if (!urlParams) {
        if (window && window.location) {
            location = window.location;
            url = location.pathname + location.search;
        }
    } else {
        url = urlParams;
    }
    // get query string from url (optional) or window
    if (location && location.search) {
        queryString = url ? url.split('?')[1] : location.search.slice(1);
    }

    // we'll store the parameters here
    const obj: any = {};

    // if query string exists
    if (queryString) {
        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        const arr = queryString.split('&');

        arr.forEach(item => {
            // separate the keys and the values
            const a = item.split('=');

            // in case params look like: list[]=thing1&list[]=thing2
            let paramNum;
            const paramName = a[0].replace(/\[\d*\]/, v => {
                paramNum = v.slice(1, -1);
                return '';
            });

            // set parameter value (use 'true' if empty)
            const paramValue = typeof a[1] === 'undefined' ? true : a[1];

            // (optional) keep case consistent
            // paramName = paramName.toLowerCase();
            // paramValue = paramValue.toLowerCase();

            // if parameter name already exists
            if (obj[paramName]) {
                // convert value to array (if still string)
                if (typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]];
                }
                // if no array index number specified...
                if (typeof paramNum === 'undefined') {
                    // put the value on the end of the array
                    obj[paramName].push(paramValue);
                } else {
                    // if array index number specified...
                    // put the value at that index number
                    obj[paramName][paramNum] = paramValue;
                }
            } else {
                // if param name doesn't exist yet, set it
                obj[paramName] = paramValue;
            }
        });
    }

    return obj;
}

export const parseJSON = (response: any) => {
    return Promise.resolve({
        status: response.status,
        statusText: response.statusText,
        json: response.data,
        headers: response.headers
    });
};

export const parseJwt = token => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const axiosRequest: any = async (url: string, options: any, withBearer: boolean = true, errorRedirect: boolean = true) => {
    return await new Promise((resolve, reject) => {
        axios(
            _.omitBy({
                url: `${ENV.REACT_APP_API_URL}${url}`,
                ...(options.body ? {data: options.body} : {}),
                ...options
            }, _.isNil))
            .then(parseJSON)
            .then((response: any) => {
                const data = response.json;

                if (typeof data === "object") {
                    if (response?.status) {
                        // data.status = response.status;
                    }

                    if (withBearer && data && data.error && (data.error === "token_expired" || data.error === 'token_invalid')) {
                        AUTH.deleteToken();
                        window.open('/', '_self');
                    }
                }

                resolve(data);
            })
            .catch(error => {
                const {response} = error;
                if (response) {
                    const {data, status} = response;

                    switch (status) {
                        case 401 : {
                            if (errorRedirect) {
                                AUTH.deleteToken();
                                window.open('/', '_self');
                            }
                            break;
                        }
                        case 403 : {
                            if (errorRedirect) window.open('/forbidden', '_self');
                            break;
                        }
                        case 417 : {
                            store.dispatch(sessionExpiredSlice.actions.showMessage({
                                isShow: true,
                                title: "LABEL.PLEASE_LOGIN",
                                message: "LABEL.TOKEN_EXPIRED.PLEASE_LOGIN",
                                type: Message.NEUTRAL
                            }))
                            break;
                        }
                        case 500 : {
                            if (errorRedirect) window.open('/page-error', '_self');
                            break;
                        }
                        case 503 : {
                            // window.open('/maintenance', '_self');
                            break;
                        }
                    }
                    if ([403, 404, 405, 500, 503].indexOf(status) === -1 && data) {
                        resolve(data);
                    } else {
                        reject(error)
                    }
                } else {
                    reject(error)
                    // window.open('/maintenance', '_self');
                }
            });
    });
}

export const CookieService = {
    set(cookieName: string, cookieValue: string, expirationTimeInHours: number) {
        const d = new Date();
        d.setTime(d.getTime() + expirationTimeInHours * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
    },
    get(cookieName: string) {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + cookieName + '=');
        if (parts.length === 2) {
            const lastPart = parts.pop();
            if (lastPart) {
                return lastPart.split(';').shift();
            }
        }

        return null;
    },
    delete(cookieName: string) {
        const d = new Date();
        d.setTime(d.getTime() - 1000 * 60 * 60 * 24);
        const expires = `expires=${d.toUTCString()}`;
        window.document.cookie = `${cookieName}=;${expires}`;
    },
    getCookies: () => {
        let pairs = document.cookie.split(";");
        let cookies = {};
        for (let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split("=");
            cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='));
        }
        return cookies;
    },
    deleteCookiesByPrefix: (cookiePrefix = "_lr_", path = "/") => {
        let cookieNames = document.cookie.split(/=[^;]*(?:;\s*|$)/);

        for (let i = 0; i < cookieNames.length; i++) {
            if (cookieNames[i].indexOf(cookiePrefix) !== -1) {
                document.cookie = cookieNames[i] + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=' + path;
            }
        }
        return document.cookie.split(/=[^;]*(?:;\s*|$)/);
    },
    deleteAllCookies: (wildcardDomain = false, primaryDomain = true, path = null) => {
        let pathSegment = path ? '; path=' + path : ''
        let expSegment = "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
        document.cookie.split(';').forEach(
            function (c) {
                primaryDomain && (document.cookie = c.replace(/^ +/, "").replace(/=.*/, expSegment + pathSegment))
                wildcardDomain && (document.cookie = c.replace(/^ +/, "").replace(/=.*/, expSegment + pathSegment + '; domain=' + document.domain))
            }
        )
        return document.cookie
    }
};

export const isValidURL = (str: string) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
}

export const getProtocol = (protocol: WebProtocols) => {
    let s = "";
    switch (protocol) {
        case WebProtocols.HTTPS:
            s = "https://"
            break;
        case WebProtocols.HTTP:
            s = "http://"
            break;
        default:
            s = "http://"
            break;
    }
    return s;
}

export const stripProtocol = (str: string) => {
    let t = str;
    if (isValidURL(str)) {
        t = str.replace(/^https?:\/\//, '');
    }
    return t;
}

export const objectToFormData = (data: any = {}) => {
    const form_data = new FormData();

    for (const key in data) {
        form_data.append(key, data[key]);
    }

    return form_data;
}

export const isEmptyValues = (value) => {
    const type = typeof value;
    if ((value !== null && type === 'object') || type === 'function') {
        const properties: any = Object.keys(value);
        if (((properties.length === 0 || properties.size === 0) && !(value instanceof File)) || (value instanceof File && value.name && value.size)) {
            return true;
        }
    }

    if (_.isArray(value) && _.some(value, (b) => _.isEmpty(b))) {
        return true;
    }

    if (type === "boolean") {
        return false;
    }

    return !value;
}

export const redirectByQuery = (dqsParams = {}) => {
    try {
        let qsParams = !_.isEmpty(dqsParams) ? dqsParams : getParams(),
            r: string | APP_ROUTES_NAME,
            t: string | APP_ROUTES_NAME, redirect = false, path = "",
            {pathname, search} = window.location
        ;

        r = _.toUpper(qsParams.r);
        t = _.toUpper(qsParams.t);

        if (pathname === '/' && AUTH.isLogin()) {
            redirect = true;
            path = APP_ROUTES_PATH.DASHBOARD;
        }

        if (r) {
            redirect = true;

            switch (t) {
                case APP_ROUTES_NAME.PREVIOUS_AUDITS:
                    r = APP_ROUTES_NAME.MY_AUDITS;
                    // search = "?t=PREVIOUS_AUDITS";
                    search = buildSearchQuery({t: "PREVIOUS_AUDITS"})
                    break;
                case APP_ROUTES_NAME.PENDING_AUDITS:
                    r = APP_ROUTES_NAME.MY_AUDITS;
                    // search = "?t=PENDING_AUDITS";
                    search = buildSearchQuery({t: "PENDING_AUDITS"})
                    break;
                case APP_ROUTES_NAME.UPCOMING_AUDITS:
                    r = APP_ROUTES_NAME.MY_AUDITS;
                    // search = "?t=UPCOMING_AUDITS";
                    search = buildSearchQuery({t: "UPCOMING_AUDITS"})
                    break;
            }
            path = APP_ROUTES_PATH[r] || APP_ROUTES_PATH.DASHBOARD;
        }

        return {redirect, path, search}
    } catch (e) {
        console.error(e)
        return {redirect: false, path: "/", search: window.location.search}
    }
}

export const blockPage = (type = true as boolean, options = {}) => {
    try {
        // console.log(store.getState());
        if (type) store.dispatch(blockSlice.actions.block(options));
        else store.dispatch(blockSlice.actions.unblock(options));

        setTimeout(() => {
            store.dispatch(blockSlice.actions.unblock(options));
        }, 3000)

        handleFooter();

        return type
    } catch (e) {
        console.error(e)
    }
}

export const autoFetchNotification = async () => {
    try {

        let {config: {notification_refresh_interval}} = store.getState();

        let t = setInterval(() => {
                store.dispatch(fetchNotificationAsync({}));
            },
            notification_refresh_interval || DEFAULT_APP_CONFIG.notification_refresh_interval
            // 3000
        )

        store.dispatch(notificationSlice.actions.setNotificationInterval({intervalId: t}))
        return t;
    } catch (e) {
        console.error(e)
    }
}

export const logoutRedirect = () => {
    try {
        // window.location.replace(APP_ROUTES_PATH.LOGIN)
        // redirectDocument(APP_ROUTES_PATH.LOGIN)
        redirect(APP_ROUTES_PATH.LOGIN)
    } catch (e) {
        console.error(e)
    }
}

export const fibLogger = (
    message: any = "",
    type: "info" | "log" | "warn" | "error" | "debug" = "log"
) => {
    try {

        if (ENV.MODE !== "development") return false;

        switch (type) {
            case "info":
                console.info(message);
                break;
            case "log":
                console.log(message);
                break;
            case "warn":
                console.warn(message);
                break;
            case "error":
                console.error(message);
                break;
            case "debug":
                console.debug(message);
                break;
        }

        return message;
    } catch (e) {
        console.error(e)
    }
}

export const toLowerCaseNonAccentVietnamese = (str) => {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
    str = str.replace(/\u02C6|\u0306|\u031B/g, "");
    return str;
}

export const toNonAccentVietnamese = (str) => {
    str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/đ/g, "d");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
    str = str.replace(/\u02C6|\u0306|\u031B/g, "");
    return str;
}

export const getPrintData = async (dataType) => {
    try {
        /**
         * @todo: Fix all cases other than "sites"
         * Now Nia temporary fix case "sites" for deploying.
         */

        const abortController = new AbortController();

        blockPage();
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        let query = {
            page: 1,
            number_per_page: 999999999,
            search: null,
            sort: null,
            sort_by: null,
            status: null
        };
        let dataReq;
        switch (dataType) {
            case 'users':
                dataReq = await USER_API.getUsers(_.omitBy(query, _.isNil), source.token);
                break;
            case 'sites':
                dataReq = await SITE_API.getSites(_.omitBy({...query, signal: abortController.signal}, _.isNil));
                break;
            case 'processes':
                dataReq = await PROCESS_API.getBatch(_.omitBy(query, _.isNil), source.token);
                break;
            case 'partners':
                dataReq = await PARTNER_API.getPartners(_.omitBy({...query, signal: abortController.signal}, _.isNil));
                break;
            case 'products':
                dataReq = await PRODUCTS_API.getProducts(_.omitBy(query, _.isNil), source.token);
                break;
            case 'productTemplates':
                dataReq = await PRODUCT_TEMPLATE_API.getTemplates(_.omitBy(query, _.isNil), source.token);
                break;
            case 'scanners':
                dataReq = await SCANNER_API.getScanners({
                    params: _.omitBy(query, _.isNil),
                });
                break;
            case 'orders0':
            case 'orders1':
            case 'orders2':
                query.status = dataType.replace('orders', '')
                dataReq = await ORDER_API.getOrders(_.omitBy(query, _.isNil), source.token);
                break;
            case 'audits1':
            case 'audits2':
            case 'audits3':
                query.status = dataType.replace('audits', '')
                dataReq = await AUDIT_API.getOrderProcessList({
                    params: _.omitBy(query, _.isNil),
                })
                break;
            case 'analyses0':
            case 'analyses1':
            case 'analyses3':
                query.status = dataType.replace('analyses', '')
                dataReq = await getAnalysisList(query)
                break;
            case 'auditTemplates':
                query.status = dataType.replace('orders', '')
                dataReq = await AUDIT_TEMPLATE_API.getTemplates(_.omitBy(query, _.isNil), source.token);
                break;
            case 'draftAnalyses':
                dataReq = await getDraftList(query);
                break;
            case 'notifications':
                dataReq = await NOTIFICATION_API.getBatch(_.omitBy(query, _.isNil), source.token);
                break;
            case 'productInventory':
                dataReq = await PRODUCTS_API.getInventory(_.omitBy(query, _.isNil), source.token);
                break;
            default:
            // code block
        }
        if (dataReq) {
            delay(500).then(() => blockPage(false))

            const {data, error} = dataReq;
            if (data && !error) {
                return data;
            }
        }

        delay(1500).then(() => blockPage(false))

    } catch (e) {
        console.error(e)
    }
}

export const delay = t => new Promise(resolve => setTimeout(resolve, t));

export const validateEmails = (emails: string) => {
    let emailsList: Array<string>;
    emailsList = emails.split(",").map((v: string) => {
        return _.escape(_.trim(v))
    });
    const t = _.every(emailsList, validateEmail);
    return t;
}

export const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
}

export const checkCache = (name = "") => {
    try {
        return Boolean(JSON.parse(localStorage.getItem(name) + ""))
    } catch (e) {
        console.error(e)
    }
}

/**
 * Creates a search query string from the given query object.
 *
 * @param {Object} queryObject - The query object from which to create the search query.
 * @returns {string} - The created search query string.
 */
export const createSearchQuery = (queryObject = {}) => {
    try {
        const qs = {}
        let query = new URLSearchParams(qs);
        _.forIn(queryObject, (value, key) => {
            if (query.has(key)) {
                query.set(key, _.escape(value));
            } else {
                query.append(_.escape(key), _.escape(value));
            }
        })
        return query.toString();
    } catch (e) {
        console.error(e)
        const qs = getParams();
        let query = new URLSearchParams(qs);
        return query.toString();
    }
}


/**
 * Get current query params and builds a search query string by appending from the provided query object
 *
 * @param {Object} queryObject - The query object containing key-value pairs.
 * @returns {string} - The constructed search query string.
 */
export const buildSearchQuery = (queryObject = {}) => {
    try {
        const qs = getParams();
        let query = new URLSearchParams(qs);
        _.forIn(queryObject, (value, key) => {
            if (query.has(key)) {
                query.set(key, _.escape(value));
            } else {
                query.append(_.escape(key), _.escape(value));
            }
        })
        return query.toString();
    } catch (e) {
        console.error(e)
        const qs = getParams();
        let query = new URLSearchParams(qs);
        return query.toString();
    }
}

export const removeSearchQuery = (keys: Array<string> = []) => {
    try {
        const qs = getParams();
        let query = new URLSearchParams(qs);
        _.map(keys, (key) => {
            if (query.has(key)) {
                query.delete(key)
            }
        })
        return query.toString();
    } catch (e) {
        console.error(e)
        const qs = getParams();
        let query = new URLSearchParams(qs);
        return query.toString();
    }
}

export const axiosRequestAlt: any = async (url: string, options: any, withBearer: boolean = true, errorRedirect: boolean = true) => {

    const {API_URL_ALT, API_VERSION_ALT} = ENV;

    const axiosOptions = {
        url: `${API_URL_ALT}${API_VERSION_ALT}${url}`,
        ...(options.body ? {data: options.body} : {}),
        ...options
    }

    return await new Promise((resolve, reject) => {
        axios(axiosOptions)
            .then(parseJSON)
            .then((response: any) => {
                const {json, status} = response;

                let data: any = {};
                if (_.isObject(json)) data = {...data, ...json};

                if (data) {
                    if (status) data.status = status;

                    const {error} = data;

                    if (withBearer && error && (error === "token_expired" || error === 'token_invalid')) {
                        AUTH.deleteToken();
                        window.open('/', '_self');
                    }
                }

                resolve(data);
            })
            .catch(error => {
                const {response} = error;
                if (response) {
                    const {data, status} = response;

                    switch (status) {
                        case 401 : {
                            if (errorRedirect) {
                                AUTH.deleteToken();
                                window.open('/', '_self');
                            }
                            break;
                        }
                        case 403 : {
                            if (errorRedirect) window.open('/forbidden', '_self');
                            break;
                        }
                        case 417 : {
                            store.dispatch(sessionExpiredSlice.actions.showMessage({
                                isShow: true,
                                title: "LABEL.PLEASE_LOGIN",
                                message: "LABEL.TOKEN_EXPIRED.PLEASE_LOGIN",
                                type: Message.NEUTRAL
                            }))
                            break;
                        }
                        case 500 : {
                            if (errorRedirect) window.open('/page-error', '_self');
                            break;
                        }
                        case 503 : {
                            // window.open('/maintenance', '_self');
                            break;
                        }
                    }
                    if ([403, 404, 500, 503].indexOf(status) === -1 && data) {
                        resolve(data);
                    } else {
                        reject(error)
                    }
                } else {
                    reject(error)
                    // window.open('/maintenance', '_self');
                }
            });
    });
}

export const axiosRequestCustom: any = async ({
                                                  api_url = ENV.API_URL,
                                                  api_version = ENV.API_VERSION,
                                                  url,
                                                  options = {},
                                                  withBearer = true,
                                                  errorRedirect = false
                                              }: ApiRequest) => {

    const axiosOptions = {
        url: `${api_url}${api_version}${url}`,
        options
    }

    return await new Promise((resolve, reject) => {
        axios(axiosOptions)
            .then((response: any) => {
                return Promise.resolve({
                    ...response,
                    json: response.data,
                });
            })
            .then((response: any) => {
                const {json, status} = response;
                let data: any = {};
                if (_.isObject(json)) data = {...data, ...json};

                // Cloudfront-Viewer-City:
                // Cloudfront-Viewer-Country:
                // Cloudfront-Viewer-Country-Name:
                // Cloudfront-Viewer-Country-Region:
                // Cloudfront-Viewer-Latitude:
                // Cloudfront-Viewer-Longitude:

                if (data) {
                    if (status) data.status = status;

                    const {error} = data;

                    if (withBearer && error && (error === "token_expired" || error === 'token_invalid')) {
                        AUTH.deleteToken();
                        window.open('/', '_self');
                    }
                }

                resolve({...response, ...data});
            })
            .catch(error => {
                const {response} = error;
                if (response) {
                    const {data, status} = response;

                    switch (status) {
                        case 401 : {
                            if (errorRedirect) {
                                AUTH.deleteToken();
                                window.open('/', '_self');
                            }
                            break;
                        }
                        case 403 : {
                            if (errorRedirect) {
                                window.open('/forbidden', '_self');
                            }
                            break;
                        }
                        case 417 : {
                            store.dispatch(sessionExpiredSlice.actions.showMessage({
                                isShow: true,
                                title: "LABEL.PLEASE_LOGIN",
                                message: "LABEL.TOKEN_EXPIRED.PLEASE_LOGIN",
                                type: Message.NEUTRAL
                            }))
                            break;
                        }
                        case 500 : {
                            if (errorRedirect) {
                                window.open('/page-error', '_self');
                            }
                            break;
                        }
                        case 503 : {
                            // window.open('/maintenance', '_self');
                            break;
                        }
                    }
                    if ([403, 404, 500, 503].indexOf(status) === -1 && data) {
                        resolve(error);
                    } else {
                        reject(error)
                    }
                } else {
                    reject(error)
                }
            });
    });
}

/**
 * Eg console.log(formatNumber(1000000)); // "1,000,000.00"
 * console.log(formatNumber(1234.5678)); // "1,234.57"
 * @param number
 * @constructor
 */
export const NumberToLocaleString = (number) => {
    return Number(number).toLocaleString('en-US', {minimumFractionDigits: 4, maximumFractionDigits: 4});
}
export const LocaleStringToNumber = (numberLocale) => {
    return parseFloat((numberLocale + "").replace(/,/g, ''));
}

export const isSignedInteger = (number) => {
    return Number.isInteger(number) && number < 0;
}

/**
 * Quick get first_login_and_first_user to use
 */
export const getUserStore = () => {
    try {
        const gStore = store.getState();
        let user;
        if (gStore.user) user = gStore.user;
        return user
    } catch (e) {
        console.error(e)
        return null
    }
}
export const getFirstLoginFirstUser = () => {
    try {
        let user = getUserStore();
        const {first_login_and_first_user = false} = user;
        return first_login_and_first_user
    } catch (e) {
        console.error(e)
        return false
    }
}

/**
 * Clear localStorage and Cookie when logOut
 */
export const logoutCleaner = () => {
    const keys = [
        'administrator_enable',
        'b2c_enable',
        'b2clang',
        'eco_score_enable',
        'fim_enable',
        'transaction_certificate_enable',
        'just_logged_in',
        'fipt',
        '_lr_id_',
        'fuemail'
    ];
    sessionStorage.clear();
    CookieService.deleteCookiesByPrefix("_lr_");
    CookieService.deleteCookiesByPrefix("fipt");

    keys.map((k) => {
        localStorage.removeItem(k)
        return k
    })
}

export const addMissingLabel = async (label: string) => {
    const v = labelPattern.test(label)
    let labels: string[] = []

    if (v) {
        const k = "labelsMissing";
        let labelsMissing = CookieService.get(k) || "[]";
        let parsedLabels = safelyParseJSON(labelsMissing);
        if (parsedLabels) {
            labels = parsedLabels;
        }

        if (!labels.includes(label)) {
            labels.push(label);

            CookieService.set(k, JSON.stringify(labels), 1)
        }
    }

    return labels
}

export const navigateUrlStr = ({pathname, search = null}: {
    pathname: string,
    search?: string | Record<string, any> | unknown
}) => {
    let searchParams = '';

    if (typeof search === 'object' && search !== null) {
        const params = new URLSearchParams(search as Record<string, any>);
        searchParams = params.toString();
    } else if (typeof search === 'string') {
        searchParams = search;
    }

    return `${pathname}${searchParams ? '?' + searchParams : ''}`;
}

export const safelyParseJSON = (json) => {
    try {
        return JSON.parse(json);
    } catch (e) {
        console.log('Given value was not valid JSON', e);
        return null;  // or return the error, or whatever you want
    }
}

/**
 * FIP-3011 Add Text URL added to a label description field
 */
interface TranslateOptions {
    allowHtml?: boolean;
    sanitize?: boolean;
}

export const translateV3 = (
  code: any,
  defaultText: string = code,
  variables: any = {},
  options: TranslateOptions = {allowHtml: true, sanitize: true}
) => {
    try {
        const gStore = store.getState();
        const bundle = gStore.language.localize;
        const userMetadata = gStore.userMetadata;
        const companyMetaStore = gStore.companyMeta;

        if (!bundle) return code;

        const {search} = window.location;
        if (/debug/.test(search)) return code;

        if (bundle && code) {
            let preValue: any, value: any;

            if (_.isString(code)) {
                if (_.has(bundle, code?.toUpperCase())) {
                    value = bundle[code?.toUpperCase()];
                } else {
                    addMissingLabel(code);
                    value = code;
                }
                preValue = value;
            }

            if (_.isArray(code)) {
                preValue = _.map(code, (v, k) => {
                    if (!_.has(bundle, v?.toUpperCase())) {
                        addMissingLabel(v?.toUpperCase());
                    }
                    return {
                        code: v,
                        text: `<span class='error'>${bundle[v?.toUpperCase()] || v}&#46;</span>`
                    };
                });

                value = _.join(
                  _.map(code, (v, k) => `<span class='error'>${bundle[v?.toUpperCase()] || v}&#46;</span>`),
                  ''
                );
            }

            if (_.isUndefined(value)) {
                return defaultText;
            } else {
                if (_.isEmpty(variables) && !_.isFunction(variables)) {
                    return processOutput(value, options);
                } else {
                    let output = value;

                    if (_.isObject(variables)) {
                        _.forIn(variables, function (varValue, varKey) {
                            // Handle HTML content in variables
                            const processedVarValue = options.allowHtml ? varValue : _.escape(varValue);
                            output = output.replace(
                              new RegExp(`\\{${varKey}\\}`, 'g'),
                              processedVarValue
                            );
                        });
                    }

                    if (_.isArray(preValue) && _.isFunction(variables)) {
                        let msg = '';
                        for (const t of preValue) {
                            msg += variables(t);
                        }
                        return processOutput(msg, options);
                    }

                    return processOutput(output, options);
                }
            }
        }
        return code;
    } catch (e) {
        console.error('Translation error:', e);
        return code;
    }
};

const processOutput = (content: string, options: TranslateOptions) => {
    if (!options.allowHtml) {
        return _.escape(content);
    }

    if (options.sanitize) {
        content = DOMPurify.sanitize(content);
    }

    return parse(content);
};
