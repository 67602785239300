import {useAppSelector} from "@redux/hooks/hooks";
import {appProviderState} from "@redux/reducers/appProviderSlice";
import {useSocket} from "@context/useSocket";

const SocketProvider = () => {
  const {isLoggedIn} = useAppSelector(appProviderState);

  useSocket(isLoggedIn);

  return null;
};

export default SocketProvider;
