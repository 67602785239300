import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPONENT_INTERACTION, PAGING_DATA, QUERIES} from "@src/types";
import {APP_ROUTES_NAME} from "@src/routes";
import {DEFAULT_MODAL, DEFAULT_VARIABLES} from "@src/variables";
import {PRODUCTS_API} from "@api/Product";
import _ from "lodash-es";
import {TAGS_API} from "@api/Tag";

const initialState: COMPONENT_INTERACTION = {
    name: APP_ROUTES_NAME.PRODUCTS,
    search: {
        ...DEFAULT_VARIABLES.SEARCH_SETTINGS,
        queries: {
            ...DEFAULT_VARIABLES.SEARCH_SETTINGS.queries,
            sort: 'asc',
            sort_by: 'name',
            number_per_page: 5
        }
    },
    loading: false,
    data: []
}

export const fetchProductsAsync = createAsyncThunk(
    'product/fetch',
    async (queries: QUERIES & { [key: string]: any }) => {
        try {
            const r = await PRODUCTS_API.getProducts(_.omitBy(queries, _.isNil), {});
            if (r) {
                const {data, meta, error} = r;
                if (data && !error) {
                    _.map(data, (d) => {
                        d.selected = false;
                        return d;
                    })
                    return {data, meta, queries}
                }
            }
            return {data: [], meta: {}, queries}
        } catch (e) {
            console.error(e)
            return {data: [], meta: {}, queries}
        }
    }
);

export const updateProductAsync = createAsyncThunk(
    'product/updateOne',
    async ({itemIndex, product}: any) => {
        const {data, error, message} = await PRODUCTS_API.updateProductDetail({
            ...product
        })
        return {data, error, message, itemIndex};
    }
);

export const archiveProductAsync = createAsyncThunk(
    'product/archive',
    async ({itemIndex, product}: any) => {
        const {data, error, message} = await PRODUCTS_API.archiveProduct(product);

        return {data, error, message, itemIndex, product};
    }
);

export const unarchiveProductAsync = createAsyncThunk(
    'product/unarchive',
    async ({itemIndex, product}: any) => {
        const {data, error, message} = await PRODUCTS_API.unarchiveProduct(product);

        return {data, error, message, itemIndex, product};
    }
);

export const multipleTagsUpdateProductAsync = createAsyncThunk(
    'product/multipleTagsUpdate',
    async ({products, tags}: any, {rejectWithValue}) => {
        try {

            if (_.isEmpty(tags)) {
                return {
                    error: "LABEL.TAGS.EMPTY", products,
                    tags,
                }
            }

            const detachMultipleTags = async () => {
                return _.reduce(_.filter(products, (p: any) => _.size(p.tags) > 0), async (result: Promise<Array<any>>, product: any) => {
                    let collection = await result;

                    const deTags = await TAGS_API.detachTags({
                        model_id: product.id,
                        tags: _.map(product.tags, (p: any) => p.id),
                        type: "product",
                    })

                    collection.push({
                        ...product,
                        ...deTags
                    })

                    return collection;
                }, Promise.resolve([]))
            }

            const attachMultipleTags = async () => {
                return _.reduce(products, async (result: Promise<Array<any>>, product: any) => {
                    let collection = await result;

                    const attTags = await TAGS_API.attachTags({
                        model_id: product.id,
                        tags: _.map(tags, (p: any) => p.id),
                        type: "product",
                    })

                    collection.push({
                        ...product,
                        ...attTags
                    })

                    return collection;
                }, Promise.resolve([]))
            }

            const detachTags = await detachMultipleTags();
            const attachTags = await attachMultipleTags();

            return {
                products,
                tags,
                detachTags,
                attachTags
            }

        } catch (error) {
            console.error(error)
            return {error}
        }
    }
);

export const multipleProductArchiveAsync = createAsyncThunk(
    'product/multipleProductArchive',
    async ({products}: any, {rejectWithValue}) => {
        try {

            const archiveProductsMethod = async () => {
                return _.reduce(_.filter(products, (p: any) => p.can_be_archived), async (result: Promise<Array<any>>, product: any) => {
                    let collection = await result;

                    if (product.deleted) {
                        const {
                            error
                        } = await PRODUCTS_API.restoreProduct(product);
                        if (!error) {
                            const {message, error} = await PRODUCTS_API.archiveProduct(product);
                            if (!error) collection.push(product)
                        }
                    } else {
                        const {message, error} = await PRODUCTS_API.archiveProduct(product);
                        if (!error) collection.push(product)
                    }

                    return collection
                }, Promise.resolve([]))
            }

            const archiveProducts = await archiveProductsMethod();

            return {
                products,
                archiveProducts,
            }

        } catch (error) {
            console.error(error)
            return {error}
        }
    }
);

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProductState: (state, action: PayloadAction<COMPONENT_INTERACTION>) => {
            _.merge(state, action.payload)
        },
        setProductData: (state, action: PayloadAction<[]>) => {
            state.data = action.payload
        },
        setProductQueries: (state, action: PayloadAction<QUERIES>) => {
            state.search.queries = action.payload
        },
        setProductPaging: (state, action: PayloadAction<PAGING_DATA>) => {
            state.search.pagingData = action.payload
        },
        setProductSingle: (state, action) => {
            const {itemIndex, product} = action.payload

            if (product && itemIndex >= 0 && itemIndex < state.data.length) {
                state.data[itemIndex] = {...state.data[itemIndex], ...product};
            }
        },
        toggleSingle: (state, action) => {
            const {itemIndex, product} = action.payload
            if (product && itemIndex >= 0 && itemIndex < state.data.length) {
                state.data[itemIndex].checked = !state.data[itemIndex].checked;
            }
        },
        toggleAll: (state) => {
            let isCheck = _.every(state.data, pd => pd.checked);
            _.forEach(state.data, (p: { checked?: boolean, [key: string]: any }) => {
                p.checked = !isCheck
                return p
            })
        },
        toggleRange: (state, action) => {
            const {itemIndex, product} = action.payload

            state.data[itemIndex].checked = true

            let firstChecked = _.findIndex(state.data, _.find(state.data, pd => pd.checked));
            let lastChecked = _.findIndex(state.data, _.findLast(state.data, pd => pd.checked));

            _.forEach(state.data, (p: { checked?: boolean, [key: string]: any }, index) => {
                p.checked = index >= firstChecked && index <= lastChecked;
                return p
            })
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductsAsync.pending, (state, action) => {
                state.loading = true
            })
            .addCase(fetchProductsAsync.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(fetchProductsAsync.fulfilled, (state, action) => {
                const {data, meta, queries} = action.payload
                const total_pages = Math.ceil(meta.total / queries.number_per_page)

                if (!_.size(data) && queries.page > 1) {
                    queries.page = (meta.current_page - 1);
                }

                /**
                 * @todo
                 * comment this line before building to dev
                 */
                // state.data = _.forEach(data, (p) => p.checked = true)

                state.data = _.forEach(data, (p) => p.checked = false)
                state.search.queries = _.merge(state.search.queries, queries)
                state.search.pagingData = {...meta, total_pages}
                state.loading = false;
            })
            .addCase(updateProductAsync.fulfilled, (state, action) => {
                const {data: product, error, message, itemIndex} = action.payload
                if (!error && product && itemIndex >= 0 && itemIndex < state.data.length) {
                    state.data[itemIndex] = {...state.data[itemIndex], ...product};
                }
            })

            .addCase(archiveProductAsync.pending, (state, action) => {
                state.loading = true
            })
            .addCase(archiveProductAsync.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(archiveProductAsync.fulfilled, (state, action) => {
                const {itemIndex, product, data, error, message} = action.payload
                if (state.search.queries.is_archived === 1) {

                } else {
                    if (product && itemIndex >= 0 && itemIndex < state.data.length) {
                        let idx = _.findIndex(state.data, (p: any) => p.id === product.id)
                        if (idx !== -1) {
                            state.data.splice(idx, 1);
                        }
                    }
                }

                state.loading = false
            })

            .addCase(unarchiveProductAsync.pending, (state, action) => {
                state.loading = true
            })
            .addCase(unarchiveProductAsync.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(unarchiveProductAsync.fulfilled, (state, action) => {
                const {itemIndex, product} = action.payload

                if (state.search.queries.is_archived === 1) {
                    if (product && itemIndex >= 0 && itemIndex < state.data.length) {
                        let idx = _.findIndex(state.data, (p: any) => p.id === product.id)
                        if (idx !== -1) {
                            state.data.splice(idx, 1);
                        }
                    }
                } else {

                }

                state.loading = false
            })


            .addCase(multipleTagsUpdateProductAsync.pending, (state, action) => {
            })
            .addCase(multipleTagsUpdateProductAsync.rejected, (state, action) => {
            })
            .addCase(multipleTagsUpdateProductAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    const {
                        products,
                        tags,
                        detachTags,
                        attachTags
                    } = action.payload

                    const productIds = _.map(products, p => p.id)
                    console.log(productIds)

                    _.forEach(state.data, (p: { checked?: boolean, [key: string]: any }) => {
                        if (_.indexOf(productIds, p.id) !== -1) {
                            p.checked = false;
                            p.tags = tags;
                        }
                    })
                }

            })


            .addCase(multipleProductArchiveAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    const {
                        products,
                        archiveProducts
                    } = action.payload

                    const productIds = _.map(archiveProducts, p => p.id)

                    _.remove(state.data, (p: { checked?: boolean, [key: string]: any }) => {
                        return _.indexOf(productIds, p.id) !== -1
                    })
                }

            })

        ;
    },
});

export const productImportModalSlice = createSlice({
    name: 'productImportModal',
    initialState: {
        ...DEFAULT_MODAL
    },
    reducers: {
        show: (state) => {
            state.isShow = true;
        },
        hide: (state) => {
            Object.assign(state, DEFAULT_MODAL)
        },

    }
})

export const {
    setProductData, setProductQueries, setProductPaging
    , setProductState, setProductSingle
} = productSlice.actions;

export const productState = (state: RootState) => state.product;
export const productImportModalState = (state: RootState) => state.productImportModal;

export default productSlice.reducer;
