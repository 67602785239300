import React from 'react';

const LoadingV2 = ({style = {} as any, styleLoader = {} as any, fixed = false, className = ""}) => {
    return (
        <div style={style} className={`block-loading-container ${fixed ? " fixed " : ""} ${className}`}>
            <div style={styleLoader} className="block-loading"/>
        </div>
    );
}

export default LoadingV2;
