import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';

export interface HeaderInterface {
    show: boolean
}

const initialState: HeaderInterface = {
    show: false
}

export const HeaderSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        showHeader: (state,) => {
            state.show = true
            return state
        },
        hideHeader: (state,) => {
            state.show = false
            return state
        },
        toggleHeader: (state,) => {
            state.show = !state.show;
            return state
        },
    },
});

export const {showHeader, hideHeader, toggleHeader} = HeaderSlice.actions;

export const headerState = (state: RootState) => state.header;

export default HeaderSlice.reducer;
