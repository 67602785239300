import decode from "jwt-decode";
import {axiosRequest, logoutCleaner} from "@src/helpers";
import {LANGUAGE} from "@constants/variables";
import {store} from "@redux/hooks/store";
import {getTranslateAsync} from "@redux/reducers/languageSlice";
import {appProviderSlice} from "@redux/reducers/appProviderSlice";

export const AUTH = {

    activate: async (code: any) => {
        return await axiosRequest(`/activate?activation_code=${code}`, {
            method: 'GET'
        }, false);
    },

    reActivate: async (code: string) => {
        return await axiosRequest(`/re-activate?activation_code=${code}`, {
            method: 'GET'
        }, false);
    },

    getTempToken: (params: { code: string }) => {
        return axiosRequest('/login-as', {
            method: 'GET',
            params
        });
    },

    getToken: () => {
        return sessionStorage.getItem("fipt") || localStorage.getItem('fipt');
    },

    getBearer: () => {
        const token = AUTH.getToken();
        return 'Bearer ' + token;
    },

    getBearerAuth: (tmpToken = "") => {
        const token = tmpToken || AUTH.getToken();
        return 'Bearer ' + token;
    },

    getHeader: () => {
        return {
            'Content-Type': 'application/json',
            'Authorization': AUTH.getBearer()
        }
    },

    getFormHeader: () => {
        return {
            'Content-Type': 'multipart/form-data',
            'Authorization': AUTH.getBearer()
        }
    },

    getHeaderWithAuth: (tmpToken = "") => {
        return {
            'Content-Type': 'application/json',
            'Authorization': AUTH.getBearerAuth(tmpToken)
        }
    },
    isTokenExpired: (token: string) => {
        try {
            const decoded: any = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
                AUTH.deleteToken();
                return true;
            }

            return false;
        } catch (err) {
            return true;
        }
    },
    isLogin: () => {
        const token = AUTH.getToken();
        return Boolean(token && !AUTH.isTokenExpired(token));
    },
    setUserEmail: (email: string) => {
        localStorage.setItem('fuemail', email);
    },
    getUserEmail: () => {
        return localStorage.getItem('fuemail');
    },
    setToken: (token: string, remember: boolean = true) => {
        if (remember) {
            localStorage.setItem('fipt', token);
        } else {
            sessionStorage['fipt'] = token;
        }
        store.dispatch(appProviderSlice.actions.setState({token}));
    },
    deleteToken: () => {
        sessionStorage.removeItem('fipt');
        localStorage.removeItem('fipt');
    },
    refreshToken: (token: string) => {
        if (sessionStorage.fipt) {
            sessionStorage.fipt = token;
        }

        if (localStorage.getItem('fipt')) {
            localStorage.setItem('fipt', token);
        }
    },
    setLocalLang: (lang: string) => {
        localStorage.setItem("fiplang", lang);
    },
    getLocalLang: () => {
        return localStorage.getItem('fiplang') || LANGUAGE.EN;
    },

    setLang: async (code: string) => {
        store.dispatch(getTranslateAsync({code, userLang: code}))
        AUTH.setLocalLang(code);
    },
    login: async (data: any) => {
        AUTH.deleteToken();
        return await axiosRequest('/login', {
            method: 'POST',
            headers: {'Accept': 'application/json',},
            data,
        }, false);
    },
    logout: async () => {
        try {
            if (AUTH.getToken()) {
                const {message, status} = await axiosRequest('/logout', {
                    method: 'GET',
                    headers: {...AUTH.getHeader()},
                }, true);

                logoutCleaner();

                return message && status
            }

            return false;
        } catch (e) {
            console.error(e)

            logoutCleaner();

            return false
        }

    },
    userLogout: async () => {
        try {
            const {message, status} = await axiosRequest('/logout', {
                method: 'GET',
                headers: {...AUTH.getHeader()},
            }, true);

            return message && status
        } catch (e) {
            console.error(e)
        }finally {

        }

    },
}
