import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";
import axios from "axios";

export const AUDIT_TEMPLATE_API = {

    BASE: "/company/audit-template",

    getTemplates: (params: any = {}, cancelToken: any) => {
        const {is_paginate = true} = params;

        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params: {...params, is_paginate},
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`${AUDIT_TEMPLATE_API.BASE}`, options, true, false);
    },
    getOne: ({uid}) => {
        return axiosRequest(`${AUDIT_TEMPLATE_API.BASE}/${uid}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },
    createOne: (data = {}) => {
        return axiosRequest(`${AUDIT_TEMPLATE_API.BASE}`, {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, true, false);
    },
    updateOne: (data: any) => {
        const {uid} = data;
        if (uid) {
            return axiosRequest(`${AUDIT_TEMPLATE_API.BASE}/${uid}`, {
                method: 'PUT',
                data,
                headers: {...AUTH.getHeader()},
            }, true, false);
        }
    },
    deleteOne: ({uid}: any) => {
        return axiosRequest(`${AUDIT_TEMPLATE_API.BASE}/${uid}`, {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },
    calculateFields: (data = {}) => {
        return axiosRequest(`${AUDIT_TEMPLATE_API.BASE}/calculate`, {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, true, false);
    },
    duplicateAuditTemplate: async (data: { uid: number | string, signal?: AbortSignal }) => {
        try {
            let {uid, signal} = data;
            if (uid) {
                let options = _.omitBy({
                    method: 'POST',
                    data: _.unset({...data}, "signal"), signal,
                    headers: {...AUTH.getHeader()},
                }, _.isNil) as any
                return axiosRequest(`/company/duplicate-audit-template/${uid}`, options, false);
            }
            return false;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.error('Request canceled: duplicateAuditTemplate', error.message);
            } else {
                console.error(error.message)
            }
        }

    },

    getTemplatesV2: (data: any) => {
        const {signal, is_paginate = true, ...params} = data;

        let options = {
            method: 'GET', signal,
            headers: {...AUTH.getHeader()},
            params: {...params, is_paginate},
        } as any;
        return axiosRequest(`${AUDIT_TEMPLATE_API.BASE}`, options, true, false);
    },
}
