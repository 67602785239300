import React from "react";

const ProcessDateTimeComponent = ({dateTime}) => {
    const [date, time] = dateTime.split(' ');

    return (
        <>
            {date}<br/>{time}
        </>
    );
}
export default ProcessDateTimeComponent;