import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPONENT_INTERACTION, PAGING_DATA, QUERIES} from "@src/types";
import {APP_ROUTES_NAME} from "@src/routes";
import {DEFAULT_VARIABLES} from "@src/variables";
import _ from "lodash-es";
import {PRODUCT_TEMPLATE_API} from "@api/ProductTemplate";

const initialState: COMPONENT_INTERACTION = {
    name: APP_ROUTES_NAME.PRODUCTS,
    search: {...DEFAULT_VARIABLES.SEARCH_SETTINGS},
    loading: false,
    data: []
}

export const fetchProductTemplatesAsync = createAsyncThunk(
    'productTemplate/fetch',
    async (queries: QUERIES) => {
        try {
            const r = await PRODUCT_TEMPLATE_API.getTemplates(_.omitBy(queries, _.isNil), {});
            if (r) {
                const {data, meta, error} = r;
                if (data && !error) {
                    return {data, meta, queries}
                }
            }
            return {data: [], meta: {}, queries}
        } catch (e) {
            console.error(e)
            return {data: [], meta: {}, queries}
        }
    }
);

export const productTemplateSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProductTemplateState: (state, action: PayloadAction<COMPONENT_INTERACTION>) => {
            state = {...action.payload}
            return state
        },
        setProductTemplateData: (state, action: PayloadAction<[]>) => {
            state = {
                ...state,
                data: [...action.payload]
            }
            return state
        },
        setProductTemplateQueries: (state, action: PayloadAction<QUERIES>) => {
            state = {
                ...state,
                search: {
                    ...state.search,
                    queries: {...action.payload}
                }
            }
            return state
        },
        setProductTemplatePaging: (state, action: PayloadAction<PAGING_DATA>) => {
            state = {
                ...state,
                search: {
                    ...state.search,
                    pagingData: {...action.payload}
                }
            }
            return state
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductTemplatesAsync.pending, (state, action) => {
                state.loading = true
            })
            .addCase(fetchProductTemplatesAsync.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(fetchProductTemplatesAsync.fulfilled, (state, action) => {
                const {data, meta, queries} = action.payload
                const total_pages = Math.ceil(meta.total / queries.number_per_page)

                if (!_.size(data) && queries.page > 1) {
                    queries.page = (meta.current_page - 1);
                }

                state.data = data
                state.search.queries = _.merge(state.search.queries, queries)
                state.search.pagingData = {...meta, total_pages}
                state.loading = false;
            });
    },
});

export const {setProductTemplateData, setProductTemplateQueries, setProductTemplatePaging, setProductTemplateState} = productTemplateSlice.actions;

export const productTemplateState = (state: RootState) => state.productTemplate;

export default productTemplateSlice.reducer;
