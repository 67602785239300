import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {
  BlockchainType,
  COMPONENT_INTERACTION,
  COMPONENT_SEARCH_QUERIES,
  PAGING_DATA,
  PROCESS_AUDIT,
  QUERIES,
} from "@src/types";
import {APP_ROUTES_NAME} from "@src/routes";
import {DEFAULT_VARIABLES} from "@src/variables";
import _ from "lodash-es";
import {AUDIT_API} from "@api/Audit";

interface UPDATE_PROCESS_BC_STATUS {
    process: PROCESS_AUDIT
    bc_status: BlockchainType
}

const initialState: COMPONENT_INTERACTION = {
    name: APP_ROUTES_NAME.MY_AUDITS,
    search: {
        ...DEFAULT_VARIABLES.SEARCH_SETTINGS,
        queries: {
            ...DEFAULT_VARIABLES.SEARCH_SETTINGS.queries,
            status: 2
        }
    },
    loading: false,
    data: [] as Array<PROCESS_AUDIT>
}

export const fetchAuditsAsync = createAsyncThunk(
    'audit/fetch',
    async (searchQueries: COMPONENT_SEARCH_QUERIES, {getState}) => {
        try {
            const {queries, selected} = searchQueries;
            const r = await AUDIT_API.getOrderProcessList({
                params: _.omitBy(queries, _.isNil),
            });

            if (r) {
                let {data = [], meta, error} = r;
                if (data && !error) {
                    _.map(data, async (process: PROCESS_AUDIT, index) => {
                        const {need_bc_check, audit_id, unique_str} = process
                        let tmpProcess = {...process}
                        if (audit_id && need_bc_check && unique_str) {
                            let {data: tmpData} = await AUDIT_API.getAuditBCStatus({code: audit_id});
                            const idxProcess = _.findIndex(data, (tProcess: PROCESS_AUDIT) => tProcess.unique_str === unique_str);

                            if (idxProcess !== -1) {
                                tmpProcess.need_bc_check = false;
                                if (tmpData) {
                                    const {bc_status} = tmpData
                                    if (bc_status) tmpProcess.bc_status = bc_status;
                                }
                            }
                        }
                        return tmpProcess;
                    })
                    return {data, meta, queries, selected}
                }
            }

            return {data: [], meta: {}, queries, selected}
        } catch (e) {
            console.error(e)
            return {data: [], meta: {}, queries: searchQueries.queries, selected: searchQueries.selected}
        }
    }
);

export const auditSlice = createSlice({
    name: 'audit',
    initialState,
    reducers: {
        setAuditState: (state, action: PayloadAction<COMPONENT_INTERACTION>) => {
            state = {...action.payload}
            return state
        },
        setAuditSearch: (state, action: PayloadAction<any>) => {
            state = {
                ...state,
                search: {...action.payload}
            }
            return state
        },
        setAuditData: (state, action: PayloadAction<[]>) => {
            state = {
                ...state,
                data: [...action.payload]
            }
            return state
        },
        setAuditQueries: (state, action: PayloadAction<QUERIES>) => {
            state = {
                ...state,
                search: {
                    ...state.search,
                    queries: {...action.payload}
                }
            }
            return state
        },
        setAuditPaging: (state, action: PayloadAction<PAGING_DATA>) => {
            state = {
                ...state,
                search: {
                    ...state.search,
                    pagingData: {...action.payload}
                }
            }
            return state
        },
        updateProcessBlockChain: (state, action: PayloadAction<UPDATE_PROCESS_BC_STATUS>) => {
            let {process, bc_status} = action.payload, tmp = [...state.data]
            const idxProcess = _.findIndex(tmp, (tProcess: PROCESS_AUDIT) => process.unique_str === tProcess.unique_str);
            if (idxProcess !== -1) {
                tmp[idxProcess].bc_status = bc_status;
                tmp[idxProcess].need_bc_check = false;
            }
            state = {...state, data: [...tmp]}
            return state
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAuditsAsync.pending, (state, action) => {
                state.loading = true
            })
            .addCase(fetchAuditsAsync.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(fetchAuditsAsync.fulfilled, (state, action) => {
                const {data, meta, queries, selected} = action.payload
                const total_pages = Math.ceil(meta.total / queries.number_per_page)

                if (!_.size(data) && queries.page > 1) {
                    queries.page = (meta.current_page - 1);
                }
                // state = {
                //     ...state, data,
                //     search: {
                //         selected,
                //         queries: {
                //             ...state.search.queries,
                //             ...queries
                //         },
                //         pagingData: {
                //             ...state.search.pagingData,
                //             ...meta,
                //             total_pages
                //         }
                //     },
                // }
                state.data = data
                state.search.selected = selected
                state.search.queries = _.merge(state.search.queries, queries)
                state.search.pagingData = {...meta, total_pages}
                state.loading = false;

            })

        ;
    },
});

export const {
    setAuditData, setAuditSearch, setAuditQueries, setAuditPaging, setAuditState, updateProcessBlockChain
} = auditSlice.actions;

export const auditState = (state: RootState) => state.audit;

export default auditSlice.reducer;
