import {Image, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ASSETS} from "@assets/Assets";
import {APP_ROUTES_PATH} from "@src/routes";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "@redux/hooks/hooks";
import {companyState} from "@redux/reducers/companySlice";
import _ from "lodash-es";
import {createSearchQuery, navigateUrlStr, translateV2} from "@src/helpers";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";

const HeaderPackage = () => {

  const company = useAppSelector(companyState)
  const history = useNavigate();
  const [packageImage, setPackageImage] = useState(ASSETS.PackageMapped);
  const isVerifiedPackage = _.includes(_.toUpper(company.current_subscription.name), "VERIFIED")

  const renderTooltip = (props) => (
    <Tooltip id="package-info"
             {...props}>
      {translateV2("LABEL.PACKAGE.UPGRADE_CURRENT.UNLOCK_MORE")}
    </Tooltip>
  );

  const renderPackageImage = () => (
    <Image
      className={"ml-2 fibre-packages-label"}
      height={10}
      src={packageImage}
      alt={"Fibretrace Package"}
    />
  )

  const navigateToPackagePage = () => {
    history(navigateUrlStr({
      pathname: APP_ROUTES_PATH.PROFILE,
      search: createSearchQuery({t: "PACKAGE"})
    }), {replace: true})
  }

  useEffect(() => {
    if (isVerifiedPackage) {
      /**
       * use different image based on package
       */
      setPackageImage(ASSETS.PackageVerified)
    }
  }, [company.current_subscription]);

  return <>

    {
      !isVerifiedPackage ?
        <OverlayTrigger
          placement={isMobile ? "auto" : "right"}
          overlay={renderTooltip({bsPrefix: "fibre-packages-tooltip tooltip"})}>
          {renderPackageImage()}
        </OverlayTrigger>
        : renderPackageImage()
    }

  </>

}


export default HeaderPackage
