import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {FibSelectInterface} from "@src/types";
import {DEFAULT_SELECT} from "@src/variables";
import {USER_API} from "@api/User";
import _ from "lodash-es";

const initialState: FibSelectInterface = {
    ...DEFAULT_SELECT
}

export const getJobsList = createAsyncThunk(
    'job/getJobRoles',
    async () => {
        const response = await USER_API.getJobRoles()
        return response.data;
    }
);

export const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        setSelectedJobByValue: (state, action: PayloadAction<{ value: any }>) => {
            let idx = _.findIndex(state.list, (v: any) => v.value === action.payload.value)
            if (idx !== -1) {
                state = {
                    ...state,
                    selected: state.list[idx]
                }
            }
            return state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getJobsList.fulfilled, (state: FibSelectInterface, action) => {
                state = {
                    ...state,
                    isLoading: false,
                    isDisabled: !(action.payload && _.size(action.payload) > 0),
                    list: [
                        ...state.list,
                        ...action.payload.map((i: any) => {
                            const {id: value, name: label} = i;
                            return {...i, value, label}
                        })
                    ]
                }
                return state
            });
    },
});

export const {setSelectedJobByValue} = jobSlice.actions;

export const jobState = (state: RootState) => state.job;

export default jobSlice.reducer;