import React, {useEffect, useState} from 'react';
import {GoogleMap, Marker, Polyline} from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import markerIcon from '@assets/images/icons/icon-location.svg';
import markerIconGreen from '@assets/images/icons/icon-location-green.svg';
import dotIcon from '@assets/images/icons/icon-dot.png';
import {Navigate} from 'react-router-dom';
import {drawDashedCurve} from '@components/POSMap/helper';
import GoogleMapHOC from '@components/HOCs/GoogleMapHOC/GoogleMapHOC';
import {translateV2} from "@src/helpers";
import _ from "lodash-es";

export interface IMap {
    process_position: number,
    process_long: string,
    process_lat: string,
    process_name: string
}

const POSMapV2 = GoogleMapHOC(({orderCode, mapRef, onMapMounted, data: dataMap}: any) => {
        const [locHover, setLocHover] = useState(0);
        const [locClick, setLocClick] = useState(0);
        const [locRedirect, setLocRedirect] = useState(0);
        const curveMap = [] as any;
        const streets = [] as any;

        const center = {
            lat: Number(dataMap[0].process_lat),
            lng: Number(dataMap[0].process_long),
        }
        const newData = dataMap.map((location: IMap) => ({
            ...location,
            id: `${location.process_lat}-${location.process_long}`
        }))

        const ar = newData.reduce((prev: any[], curVal: any) => {
            const isExist = prev.findIndex((location: any) => location.id === `${curVal.process_lat}-${curVal.process_long}`);
            if (isExist !== -1) {
                prev[isExist] = {
                    ...prev[isExist],
                    process_position: curVal.process_position,
                    name: curVal.name,
                    list: prev[isExist].list.concat(curVal)
                }
            } else {
                const newLocation = {
                    ...curVal,
                    list: [curVal]
                }
                prev.push(newLocation);
            }
            return prev;
        }, [] as any[]);

        if (dataMap.length > 1) {
            for (let j = 0; j < dataMap.length - 1; j++) {
                const lat1 = Number(dataMap[j].process_lat);
                const lng1 = Number(dataMap[j].process_long);

                const lat2 = Number(dataMap[j + 1].process_lat);
                const lng2 = Number(dataMap[j + 1].process_long);

                if (!(lat1 === lat2 && lng1 === lng2)) {
                    const isDraw = streets.findIndex((street: any) => street[0].indexOf(`${lat1}-${lng1}`) !== -1 && street[0].indexOf(`${lat2}-${lng2}`) !== -1);
                    if (isDraw === -1) {
                        const curve = drawDashedCurve(new google.maps.LatLng(lat1, lng1), new google.maps.LatLng(lat2, lng2));
                        curveMap.push(curve)
                        streets.push([`${lat1}-${lng1}_${lat2}-${lng2}`, 0])
                    } else {
                        if (streets[isDraw][1] === 0) {
                            if (streets[isDraw][0].indexOf(`${lat1}-${lng1}`) === 0) {
                                const curve = drawDashedCurve(new google.maps.LatLng(lat1, lng1), new google.maps.LatLng(lat2, lng2), 1);
                                curveMap.push(curve);
                            } else {
                                const curve = drawDashedCurve(new google.maps.LatLng(lat1, lng1), new google.maps.LatLng(lat2, lng2));
                                curveMap.push(curve);
                            }
                            streets[isDraw][1] = streets[isDraw][1] + 1;
                        } else {
                            const middleLatLng = new google.maps.LatLng((lat1 + lat2) / 2, (lng1 + lng2) / 2);
                            curveMap.push({
                                line: {
                                    path: [
                                        new google.maps.LatLng(lat1, lng1),
                                        new google.maps.LatLng(lat2, lng2)
                                    ],
                                    geodesic: true,
                                    strokeOpacity: 0.0,
                                    icons: [{
                                        icon: {
                                            path: 'M 0,-1 0,1',
                                            strokeOpacity: 1,
                                            scale: 3
                                        },
                                        offset: '0',
                                        repeat: '14px'
                                    }],
                                    strokeColor: '#015b9e'
                                },
                                arrow: {
                                    position: middleLatLng,
                                    icon: {
                                        path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                                        strokeColor: '#015b9e',
                                        scale: 3,
                                        rotation: google.maps.geometry.spherical.computeHeading(middleLatLng, new google.maps.LatLng(lat2, lng2))
                                    }
                                }
                            })
                        }
                    }
                }
            }
        }

        useEffect(() => {
            if (mapRef && dataMap.length > 1) {
                const bounds = new google.maps.LatLngBounds();
                dataMap.forEach((marker: any) => {
                    bounds.extend(
                        new google.maps.LatLng(Number(marker.process_lat), Number(marker.process_long))
                    );
                });
                mapRef.fitBounds(bounds);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [mapRef]);

        return locRedirect ? <Navigate to={`/orders/${orderCode}/${locRedirect}`}/> : (
            <GoogleMap
                defaultZoom={16}
                defaultCenter={center}
                ref={onMapMounted}
                mapTypeId={google.maps.MapTypeId.ROADMAP}
            >
                {
                    curveMap.length > 0 && curveMap.map((curv: any, idx: number) =>
                        <div key={idx}>
                            <Polyline
                                options={{...curv.line}}
                            />

                            <Marker {...curv.arrow}/>
                        </div>
                    )
                }
                {
                    dataMap.length > 0 && dataMap.map((location: any, idx: number) => {
                        const zIndex = 300;
                        const opt = {} as any;
                        let contentHover: any;
                        let contenClick: any;
                        opt.position = {
                            lat: parseFloat(location.process_lat),
                            lng: parseFloat(location.process_long)
                        };

                        const isDrawMarker = ar.find((loc: any) => loc.process_position === location.process_position);

                        if (isDrawMarker) {
                            opt.icon = !location.scanner ? markerIcon : markerIconGreen;
                            opt.label = {text: String(location.process_position), color: "white"}
                            opt.zIndex = zIndex + idx;
                            contentHover = isDrawMarker.list.map(
                                (location: any) => <li className={"fib-process-markers"} key={location.process_position}>
                                    <span className={"mb-1"} style={{whiteSpace: "nowrap"}}
                                          dangerouslySetInnerHTML={{__html: location.process_name}}/>.
                                    <span
                                        className={"text-green fw-600 mb-1"}>{location.scanner ? _.capitalize(translateV2("LABEL.VERIFIED_BY_FIBRETRACE", "", {"0": location.scanner.name})) : ""}</span>
                                </li>
                            )

                            opt.onMouseOver = () => {
                                if (location.process_position !== locClick) setLocHover(location.process_position);
                            };
                            opt.onMouseOut = () => setLocHover(0);
                            if (isDrawMarker.list.length === 1) {
                                opt.onClick = () => setLocRedirect(location.process_position);
                            } else {
                                contenClick = isDrawMarker.list.map(
                                    (location: any) => <li key={location.process_position}
                                                           onClick={() => setLocRedirect(location.process_position)}
                                                           className="mlm-ifo">
                                        <span className="mlm-location"><img
                                            src={!location.scanner ? markerIcon : markerIconGreen}
                                            alt=""/> <span>{location.process_position}</span></span>
                                        <span dangerouslySetInnerHTML={{__html: location.process_name}}/>
                                    </li>
                                )
                                opt.onClick = () => {
                                    setLocHover(0);
                                    setLocClick(location.process_position);
                                }
                            }
                        }

                        return <Marker key={idx} icon={dotIcon} {...opt}>
                            {
                                isDrawMarker && locHover === location.process_position &&
                              <InfoBox options={{boxClass: 'map-list-manufacturer'}}>
                                <>
                                  <ul>
                                      {contentHover}
                                  </ul>
                                </>
                              </InfoBox>
                            }
                            {
                                isDrawMarker && locClick === location.process_position &&
                              <InfoBox zIndex={999} onCloseClick={() => setLocClick(0)}
                                       options={{boxClass: 'map-list-click-manufacturer', enableEventPropagation: true}}>
                                <>
                                  <ul>
                                      {contenClick}
                                  </ul>
                                </>
                              </InfoBox>
                            }
                        </Marker>
                    })
                }
            </GoogleMap>
        );
    }
);

export default POSMapV2;
