import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {EImportPartnersSteps} from "@redux/reducers/importPartnersSlice";
import {PARTNER_API} from "@api/Partner";
import _ from "lodash-es";


export interface ImportSinglePartnerInterface {
    "company_name": string | any,
    "company_email": string | any,
    "supplier_phone_number": string | any,
    "supplier_hq_postal_address": string | any,
    "supplier_process_name": string | any,
    "supplier_process_postal_address": string | any,
    "process_description": string | any,
    "allow_import": boolean,
    "note": string | any,
    "send_invitation"?: boolean
    "email_list"?: []
}

interface importPartnersProgressInterface {
    loading: boolean,
    step: EImportPartnersSteps
    message: string | string[]
    stepData: any,
    messageData: string | string[] | object[] | object

    [key: string]: any
}


interface ReturnTypeValidateFile {
    data: [],
    import_partner_data: number,
    error: string | string[]
}

interface ReturnTypeImportingPartner {
    message: string | string[]
    error: string | string[]
}

export const validateImportPartnersFileAsync = createAsyncThunk<
    ReturnTypeValidateFile,
    { partner_list: unknown }
>(
    'importPartnersProgress/validateImportPartnersFileAsync',
    async ({partner_list}: { partner_list: unknown },{rejectWithValue}) => {
        try {
            const {data = [], error = "", import_partner_data} = await PARTNER_API.validateImportPartners({
                partner_list
            })
            if(error){
                return rejectWithValue({data, error, import_partner_data})
            }

            return {data, error, import_partner_data}
        } catch (e) {
            return rejectWithValue({data: [], error: "", import_partner_data: 0})
        }
    }
);

export const beginImportPartnersFileAsync = createAsyncThunk<
    ReturnTypeImportingPartner,
    { partner_list: unknown, import_partner_data: number }
>(
    'importPartnersProgress/beginImportPartnersFileAsync',
    async ({partner_list, import_partner_data}: { partner_list: unknown, import_partner_data: number }) => {
        try {
            const {message = "", error = ""} = await PARTNER_API.importPartners({partner_list, import_partner_data})
            return {message, error}
        } catch (e) {
            console.error(e)
            return {message: "", error: ""}
        }
    }
);

interface ReturnTypeInvitingPartner {
    message: string | string[]
    error: string | string[]
    data: any
}

export const invitePartnersAsync = createAsyncThunk<
    ReturnTypeInvitingPartner,
    { emails: unknown, message: unknown }
>(
    'importPartnersProgress/invitePartnersAsync',
    async ({emails, message}: { emails: unknown, message: unknown }) => {
        try {
            const {error, data, message: messageData} = await PARTNER_API.invitePartner({
                emails,
                message
            });
            return {error, message: messageData, data}
        } catch (e) {
            console.error(e)
            return {message: "", error: "", data: ""}
        }
    }
);

export enum ImportStatusEnum {
    UNKNOWN = "UNKNOWN",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED"
}

export enum InvitationStatusEnum {
    UNKNOWN = "UNKNOWN",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED"
}

const initialState: importPartnersProgressInterface = {
    loading: false,
    step: EImportPartnersSteps.CHOOSE_FILE,
    // step: EImportPartnersSteps.CHOOSE_FILE,
    message: "",
    messageData: {},
    stepData: {
        choose_file: {
            file: {},
        },
        validate_file: {
            partner_list: [],
            import_partner_data: 0
        },
        preview: {
            checked: true,
            partner_list_formatted: []
        },
        importing: {
            progress: 0,
        },
        import_done: {
            partner_list_invitation: []
        },
        invitation: {
            partner_list_invitation: [],
            emails: []
        },
        inviting: {
            progress: 0,
        },
        invitation_done: {
            message: "",
            messageData: []
        }
    },
    statusImport: ImportStatusEnum.UNKNOWN,
    statusInvitation: InvitationStatusEnum.UNKNOWN,

}
export const importPartnersProgressSlice = createSlice({
    name: 'importPartnersProgress',
    initialState,
    reducers: {
        reset: (state) => {
            Object.assign(state, initialState)
        },
        setLoading: (state, action) => {
            state.loading = action.payload.loading
        },
        setState: (state, action) => {
            Object.assign(state, action.payload)
        },
        setStep: (state, action) => {
            state.step = action.payload.step
        },
        setErrorMessage: (state, action) => {
            state.message = action.payload.message
        },
        chooseFile: (state, action) => {
            state.stepData.choose_file.file = action.payload.file
        },
        setPartnersList: (state, action) => {
            state.stepData.preview = action.payload.partner_list_formatted
        },
        setImportFailed: (state, action) => {
            state.statusImport = action.payload.statusImport
        },
        setImportSuccess: (state, action) => {
            state.statusImport = action.payload.statusImport
        },
        setSingleFormattedPartner: (state, action) => {
            const {itemIndex, partner} = action.payload
            if (partner && itemIndex >= 0 && itemIndex < state.stepData.preview.partner_list_formatted.length) {
                state.stepData.preview.partner_list_formatted[itemIndex] = {...state.stepData.preview.partner_list_formatted[itemIndex], ...partner};
            }
        },
        setAllFormattedPartner: (state, action) => {
            if (!_.isEmpty(state.stepData.preview.partner_list_formatted)) {
                state.stepData.preview.checked = action.payload.checked;
                _.forEach(state.stepData.preview.partner_list_formatted, (p: any) => {
                    if (p.allow_import || p.send_invitation) {
                        p.checked = action.payload.checked
                    }
                    return p
                })
            }
        },
        // for inviting partners
        setSingleFormattedInvitePartner: (state, action) => {
            // importPartnersProgress.stepData.invitation.partner_list_invitation
            const {itemIndex, partner} = action.payload
            if (partner && itemIndex >= 0 && itemIndex < state.stepData.invitation.partner_list_invitation.length) {
                state.stepData.invitation.partner_list_invitation[itemIndex] = {...state.stepData.invitation.partner_list_invitation[itemIndex], ...partner};
            }
        },
        setAllFormattedInvitePartner: (state, action) => {
            if (!_.isEmpty(state.stepData.invitation.partner_list_invitation)) {
                state.stepData.preview.checked = action.payload.checked;
                _.forEach(state.stepData.invitation.partner_list_invitation, (p: any) => {
                    p.checked = action.payload.checked
                    return p
                })
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(validateImportPartnersFileAsync.pending, (state, action) => {
                // state.loading = true
                state.step = EImportPartnersSteps.VALIDATE_FILE
                state.stepData.choose_file.file = null
            })
            .addCase(validateImportPartnersFileAsync.fulfilled, (state, action: PayloadAction<ReturnTypeValidateFile>) => {
                state.loading = false

                if (!action?.payload?.error) {
                    state.step = EImportPartnersSteps.PREVIEW
                } else {
                    state.message = action?.payload?.error
                }

                if (action?.payload?.data) {
                    state.stepData.validate_file.partner_list = action?.payload?.data
                    state.stepData.preview.partner_list_formatted = _.map(action?.payload?.data, (p: any) => {
                        p.checked = p.allow_import || p.send_invitation;
                        return p;
                    })

                    state.stepData.invitation.partner_list_invitation = _.filter(action?.payload?.data, (p: any) => p.send_invitation)
                    console.log("action", _.filter(action?.payload?.data, (p: any) => p.send_invitation))
                }
                if (action?.payload?.import_partner_data) {
                    state.stepData.validate_file.import_partner_data = action?.payload?.import_partner_data
                }

            })
            .addCase(validateImportPartnersFileAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.message = action.payload?.error
            })

            // importing
            .addCase(beginImportPartnersFileAsync.pending, (state, action) => {
                state.loading = false
                state.step = EImportPartnersSteps.IMPORTING
                state.statusImport = ImportStatusEnum.SUCCESS
            })
            .addCase(beginImportPartnersFileAsync.fulfilled, (state, action: PayloadAction<ReturnTypeImportingPartner>) => {
                state.loading = false
                state.stepData.importing.progress = 100;

                if (!action?.payload?.error) {
                    state.step = EImportPartnersSteps.IMPORT_DONE
                    state.statusImport = ImportStatusEnum.SUCCESS

                    if (action?.payload?.message) {
                        state.message = action?.payload?.message
                    }
                } else {
                    state.statusImport = ImportStatusEnum.FAILED
                    state.message = action?.payload?.error
                }

            })
            .addCase(beginImportPartnersFileAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.message = action.payload?.error
                state.stepData.importing.progress = 100;
            })

            // invite partners
            .addCase(invitePartnersAsync.pending, (state, action) => {
                state.loading = false
                state.step = EImportPartnersSteps.INVITING
                state.statusInvitation = InvitationStatusEnum.SUCCESS

            })
            .addCase(invitePartnersAsync.fulfilled, (state, action: PayloadAction<ReturnTypeInvitingPartner>) => {

                let {message, error} = action.payload;

                state.loading = false
                state.stepData.inviting.progress = 100;
                state.step = EImportPartnersSteps.INVITE_DONE


                if (!error) {
                    state.statusInvitation = InvitationStatusEnum.SUCCESS
                } else {
                    state.statusInvitation = InvitationStatusEnum.FAILED
                    state.message = action?.payload?.error

                    state.message = message ? message
                        : ((_.isArray(error) && _.every(error, (a: any) => !_.isObject(a)))
                            ? error
                            : _.flatten(_.map(error, (obj,) => _.keys(obj))))

                    state.messageData = !_.isArray(error) ? "" : _.transform(_.flatten(
                        _.map(error, (obj,) => {
                            return _.values(obj)
                        })), (result, value, key) => {
                        result[key] = value;
                    }, {})
                }

            })
            .addCase(invitePartnersAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.message = action.payload?.error
                state.stepData.inviting.progress = 100;
            })
    },
});

export const importPartnersProgressState = (state: RootState) => state.importPartnersProgress;
export default importPartnersProgressSlice.reducer
