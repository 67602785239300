import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {LANGUAGE_API} from "@api/Languge";
import _ from "lodash-es";
import languages from "@src/locales/languages.json"

const staticTranslation = () => {
  let localize = {};

  localize["LABEL.PLEASE_LOGIN"] = "Please login"
  localize["LABEL.TOKEN_EXPIRED.PLEASE_LOGIN"] = "Your session has timed out. Please log back in."
  localize["LABEL.SCAN_STRENGTH_NOTIFICATION"] = "Scan strength notification"
  localize["LABEL.INTENSITY_VARIATION_ALERT.REASON_NOTE"] = "Since the last process, the material has been ..."
  localize["LABEL.SCAN_STRENGTH_NOTIFICATION.NOTE"] = "Used to alert you if we detect large variations in the FibreTrace ID signal through your supply chain."
  localize["LABEL.INTENSITY_VARIATION_ALERT.DESCRIPTION"] = 'We have detected a change to the signal strength between the {site} {process_name} and the previous {data_previous} {site_previous} {process_name_previous}. Please confirm the probable cause from the list below and we will to advise the order owner {order_owner_email}.'
  localize["LABEL.INTENSITY_VARIATION_ALERT.DESCRIPTION.FIRST_PROCESS"] = 'We have detected a change to the signal strength of the {site} {process_name}. Please confirm the probable cause from the list below and we will to advise the Order Owner {order_owner_email}.'
  localize["ERROR.TOO_MANY_ATTEMPTS_INVITATION"] = "Please wait for {0} minutes before you can send more invitations."
  localize["ERROR.EMAIL_INVITE_LENGTH"] = "Max {length} characters"
  localize["LABEL.TOOLTIP.AUDIT_ASSISTANT_ACTION_FOR_ADMIN"] = "This audit is assigned to your {auditor_name}. If your colleague, {auditor_name} is unable to perform the audit you can transfer it to yourself or a different colleague."
  localize["LABEL.INTENSITY_CHART.HEADING"] = "Intensity Check"
  localize["LABEL.INTENSITY_CHART.LINE.TARGET_BASELINE"] = "Target Baseline"
  localize["LABEL.INTENSITY_CHART.LINE.HIGH"] = "High"
  localize["LABEL.INTENSITY_CHART.LINE.LOW"] = "Low"
  localize["LABEL.INTENSITY_CHART.LINE.AVERAGE"] = "Average"
  localize["LABEL.SEND_AUDIT.MODAL.TITLE"] = "Order Successfully sent to {receiver}"
  localize["LABEL.SEND_AUDIT.MODAL.SEND_SUCCESS"] = "Your order {order_code} was successfully sent to {receiver} at {company}. {receiver} will be asked to accept the order. If they choose to reject the order you will be notified."
  localize["LABEL.COMMERCIAL_INVOICE.TITLE"] = "If you prefer to pay offline"
  localize["LABEL.COMMERCIAL_INVOICE.ENTER_FIELD_PO_NUMBER"] = "Enter purchase order"
  localize["LABEL.COMMERCIAL_INVOICE.FIELD_PO_NUMBER"] = "Purchase order"
  localize["LABEL.COMMERCIAL_INVOICE.FIELD_PO_NUMBER.NOTE"] = "This makes it easier for the administrator to track the payment."
  localize["LABEL.COMMERCIAL_INVOICE.RAISE_INVOICE"] = "Raise Commercial Invoice"
  localize["LABEL.COMMERCIAL_INVOICE.RAISE_INVOICE.HEADQUARTER_REQUIRED"] = "Please add a Headquarter site {open}here{close}."
  localize["LABEL.INVOICE.CONFIRM_PAYMENT_NOT_PAYPAL"] = "Confirmation of Payment Made Outside PayPal"
  localize["LABEL.INVOICE.CONFIRM_PAYMENT_NOT_PAYPAL.DESCRIPTION"] = "Inform administrators that the recent payment for FibreTrace has been successfully completed. Please note that this transaction was processed outside of the PayPal system."
  localize["LABEL.INVOICE.MARK_AS_BANK_TRANSFER_SEND"] = "Please check if this invoice has already been paid."
  localize["LABEL.TRANSACTION_ID"] = "Transaction ID"
  localize["LABEL.INVOICE.TRANSACTION_ID.NOTE"] = "This makes it easier for the administrator to track the payment."
  localize["LABEL.REGISTER.COMPANY.CLAIM"] = "Your company name is similar to a Company {company} listed on FibreTrace, do you wish to <b>Claim this company?</b>"
  localize["LABEL.REGISTER.COMPANY.USE_ANOTHER"] = "Please use different company name"
  localize["LABEL.COMPANY.CLAIM.APPROVAL_PENDING.TITLE"] = "Claim approval pending"
  localize["LABEL.COMPANY.CLAIM.APPROVAL_PENDING.DESCRIPTION"] = "Please wait while we confirm your company.<br/>You can contact support@fibretrace.io if we you have not heard from us in 5 days.<br/>We will email you when your claim has been approved."
  localize["LABEL.PARTNER.IMPORT.TERMS_OF_USE"] = "Please note that by importing partners you agree to <a class='text-green' href='/terms-conditions'>FibreTrace's Terms of use </a> and understand the risks in using self-reported data."
  localize["LABEL.CLAIMED"] = "Claimed"
  localize["LABEL.VERIFIED"] = "Verified"
  localize["LABEL.UNCLAIMED"] = "Unclaimed"
  localize["LABEL.NEW"] = "New"
  localize["LABEL.PARTNER.ADD"] = "Add partners"
  localize["LABEL.PARTNER.IMPORT.CHOOSE_FILE"] = "Importing your partners to start tracing your product"
  localize["LABEL.PARTNER.IMPORT.VALIDATE_FILE"] = "Validating"
  localize["LABEL.PARTNER.IMPORT.PREVIEW"] = "Preview partner information"
  localize["LABEL.PARTNER.IMPORT.PREVIEW.WARNING"] = "Partner could not be imported. Please check company name, email address and postal address is correct in import file."
  localize["LABEL.PARTNER.IMPORT.PREVIEW.REFER_ERROR_ICON"] = "Refer to the error icons for each supplier"
  localize["LABEL.PARTNER.IMPORT.IMPORTING"] = "Partner import in progress"
  localize["LABEL.PARTNER.IMPORT.IMPORTING.WAITING"] = "Please wait while we import your partner records."
  localize["LABEL.PARTNER.IMPORT.IMPORT_DONE"] = "Import Successful"
  localize["LABEL.PARTNER.IMPORT.IMPORT_DONE.TOTAL_SUCCESS"] = "<b>{total}</b> suppliers successfully imported."
  localize["LABEL.PARTNER.IMPORT.INVITATION"] = "Connect to existing suppliers?"
  localize["LABEL.PARTNER.IMPORT.INVITATION.WAIT"] = "Please wait while we invite your partners."
  localize["LABEL.PARTNER.IMPORT.INVITING"] = "Inviting suppliers"
  localize["LABEL.PARTNER.IMPORT.DOWNLOAD_SAMPLE_CSV"] = "Download sample CSV"
  localize["LABEL.PARTNER.IMPORT.REPLACE_FILE"] = "Replace file"
  localize["LABEL.PARTNER.IMPORT.UPLOAD_PREVIEW"] = "Upload and preview"
  localize["LABEL.PARTNER.IMPORT.SHOW_ME_THE_WAY"] = "Show me how to do this"
  localize["LABEL.PARTNER.IMPORT.SHOW_ME_THE_WAY.YOUTUBE_ID"] = "R3D2ufaCcqQ"

  /**
   * FIP-2307
   */
  localize["LABEL.PARTNER.IMPORT.VALIDATE_FILE.HEADER"] = "Validating"
  localize["LABEL.DROP_ZONE.FILE_UPLOAD"] = "Upload file"
  localize["LABEL.PARTNER.IMPORT.VALIDATE_FILE.WAITING"] = "Please wait while we validate your partner records."
  localize["LABEL.PARTNER.IMPORT.VALIDATE_FILE.GENERAL_ERROR"] = "The file you have uploaded appears to be corrupted or unreadable. Please check the file and try uploading again."
  localize["LABEL.PARTNER.IMPORT.INVITATION.TOTAL_PREVIEW"] = "<b>{total}</b> suppliers successfully uploaded. Please select partners to import."
  localize["LABEL.PARTNER.IMPORT.INVITATION.DONE"] = "<b>{total}</b> suppliers successfully invited."
  localize["LABEL.PARTNER.IMPORT.INVITATION.TOTAL_INVITE"] = "<b>{total}</b> of your imported suppliers already exist on FibreTrace. Do you wish to send an invitation to connect to them?"
  localize["LABEL.PARTNER.IMPORT.INVITATION.RESEND_INVITE"] = "Resend invite"
  localize["LABEL.PARTNER.IMPORT.INVITATION.INVITATION_SEND_SUCCESSFULLY"] = "Partner invitation sent successfully"
  localize["LABEL.PARTNER.IMPORT.INVITATION.INVITATION_SEND_UNSUCCESSFULLY"] = "Partner invitation sent unsuccessfully"
  localize["LABEL.PARTNER.IMPORT.IMPORT_FAILED"] = "Import Failed"
  localize["LABEL.PARTNER.IMPORT.INVITATION.SEND_INVITE"] = "Send invite"
  localize["LABEL.PARTNER.IMPORT.INVITE_DONE"] = "Import Successful"
  localize["LABEL.CANCEL"] = "Cancel"
  localize["LABEL.PARTNER.PREVIEW.RECORD.CANNOT_BE_IMPORTED"] = "This recorded wont be imported at this time. If you want to import this record, please press back. correct the data in the CSV and upload it again."


  /**
   * https://fibretrace.atlassian.net/browse/FIP-2475
   */
  localize["LABEL.AUDIT.CLAIM_DISPUTE.DISPUTE"] = "Dispute these audits?"
  localize["LABEL.AUDIT.CLAIM_DISPUTE.DISPUTE.CTA"] = "Dispute"
  localize["LABEL.AUDIT.CLAIM_DISPUTE.DISPUTE.DESCRIPTION"] = "The order information is incorrect, either it never occurred, or the information recorded was inaccurate. Disputing it will remove it from public view and notify the original order owner “Company Name” so they can amend it."
  localize["SUCCESS.PROCESSES_MARKED_AS_DISPUTE"] = "{total} audits have been successfully disputed"

  localize["LABEL.AUDIT.CLAIM_DISPUTE.CLAIMED"] = "Claim these audits?"
  localize["LABEL.AUDIT.CLAIM_DISPUTE.CLAIMED.CTA"] = "Claim"
  localize["LABEL.AUDIT.CLAIM_DISPUTE.CLAIMED.DESCRIPTION"] = "This audit was performed by me or one of my colleagues and I would like to claim it."
  localize["SUCCESS.PROCESSES_MARKED_AS_CLAIMED"] = "{total} audits have been successfully claimed"

  localize["LABEL.AUDIT.CLAIM_DISPUTE.STATUS.DISPUTE"] = "Disputed"
  localize["LABEL.AUDIT.CLAIM_DISPUTE.STATUS.CLAIMED"] = "Claimed"
  localize["LABEL.AUDIT.CLAIM_DISPUTE.STATUS.VERIFIED"] = "Verified"
  localize["LABEL.AUDIT.CLAIM_DISPUTE.STATUS.SELF_REPORT"] = "Self-Reported"
  localize["LABEL.AUDIT.CLAIM_DISPUTE.STATUS.SELF_REPORTED"] = "Self-Reported"

  localize["LABEL.NO_MISSED_AUDITS"] = "You do not have any missed audits."
  localize["LABEL.NO_CLAIMED_DISPUTE_AUDITS"] = "You do not have any claim/dispute audits."
  localize["LABEL.ERROR.VALIDATE.CREDIT_BALANCE_NOT_ENOUGH.CTA"] = "If you wish to purchase more credits, please click here or navigate to <b>Account > Services.</b>"
  localize["LABEL.CONFIRMATION_SUBMIT_AUDITS_FOR_UNCLAIMED_COMPANY"] = "Unclaimed company"
  localize["LABEL.CONFIRMATION_SUBMIT_AUDITS_FOR_UNCLAIMED_COMPANY.NOTE"] = "Are you sure you want to submit this audit for an unclaimed company? The audit will be automatically posted to the blockchain and you won't be able to make any edits."

  localize["LABEL.COMMERCIAL_INVOICE.RAISE_INVOICE.HEADQUARTER_REQUIRED"] = "Please add a Headquarter site {open}here{close}."

  /**
   * FIP-2655
   */
  localize["LABEL.ADD_PRODUCTS"] = "Add products"
  localize["LABEL.SHOW_ARCHIVED_PRODUCTS"] = "Show Archived Products"
  localize["LABEL.SHOW_PRODUCTS"] = "Show Products"
  localize["LABEL.NO_ARCHIVED_PRODUCTS"] = "No archived Product(s)"

  /**
   * FIP-2725
   */
  localize["LABEL.REQUIRE_SPIN_AND_TURN_SCANNER"] = "Requires Spin & Turn Scanner when auditing"
  localize["LABEL.SPIN_AND_TURN_SCANNER"] = "Spin & Turn Scanner"
  localize["LABEL.SPIN_AND_TURN_SCANNER.SCAN_VALUE"] = "Spin&Turn Code"
  localize["LABEL.SPIN_AND_TURN_SCANNER.SCAN_VALUE.CTA"] = "Please enter the value appear on your device and verify."
  localize["LABEL.SPIN_AND_TURN_SCANNER.SCAN_VALUE.VERIFY"] = "Verify"
  localize["LABEL.SPIN_AND_TURN_SCANNER.SCAN_VALUE.ENTER_PREV_VALUE"] = "Please do not use previous value"
  localize["LABEL.REQUIRE_FIBRETRACE_SCANNER.TOOLTIP"] = "Detection of generic and individual FibreTrace pigments.  Ideal for yarns/gins and finished garments that are lighter in colour."
  localize["LABEL.REQUIRE_SPIN_AND_TURN_SCANNER.TOOLTIP"] = "Identification in dark fabrics. This scanner is used to verify the presence of FibreTrace pigment in dark or black fabrics, however it cannot identify unique custom pigments or blending, only that FibreTrace is present."

  /**
   * FIP-2864
   */
  localize["LABEL.VIEW_ALL"] = "View all users"
  localize["LABEL.INVITE_A_COLLEAGUE"] = "Invite a colleague"
  localize["LABEL.VIEW_PENDING_COLLEAGUE_INVITES"] = "View pending colleague invites"
  localize["LABEL.VIEW_PENDING_REQUESTS_TO_JOIN"] = "View pending requests to join"

  /**
   * FIP-2642
   */
  localize["LABEL.ORDER_DETAIL.CONNECTED_ORDER.PREVIEW"] = "This order has <b>{total}</b> connected orders. You can view the details of these below."

  /**
   * FIP-2642
   */
  localize["LABEL.ORDER_PROCESS.AUDITS"] = "Audits"
  localize["LABEL.ORDER_PROCESS.LOCATION"] = "Location"
  localize["LABEL.ORDER_PROCESS.SCAN_TYPE"] = "Scan type"
  localize["LABEL.ORDER_PROCESS.CONNECTED_ORDERS"] = "Connected orders"
  localize["LABEL.ORDER_PROCESS.CONNECTED_ORDERS_NUMBER"] = `{total} connected orders`
  localize["LABEL.SELF_REPORTED"] = `Self-reported`
  localize["LABEL.ORDER_PROCESS.NO_CONNECTED_ORDERS"] = `This process has no connected orders`
  localize["LABEL.ORDER_PROCESS.SOME_MORE"] = `{total} more`
  localize["LABEL.ORDER_PROCESS.PRODUCT_MATERIALS"] = `Product materials`

  /**
   * FIP-2532
   */
  localize["LABEL.PLEASE_LOGIN"] = "Please login"
  localize["LABEL.TOKEN_EXPIRED.PLEASE_LOGIN"] = "Your session has timed out. Please log back in."

  /**
   * FIP-2566
   */
  localize["LABEL.ARCHIVE_ORDER"] = "Archive"
  localize["LABEL.CONFIRMATION_ARCHIVE_GENERAL.ORDER.TITLE"] = "Archive order"
  localize["LABEL.CONFIRMATION_ARCHIVE_GENERAL.ORDER"] = "Are you sure you want to archive this order? This action cannot be undone.{0}"

  /**
   * FIP-2725
   */
  localize["LABEL.REQUIRE_SPIN_AND_TURN_SCANNER"] = "Requires Spin & Turn Scanner when auditing"
  localize["LABEL.REQUIRE_SPIN_AND_TURN_SCANNER.TOOLTIP"] = "Requires Spin & Turn Scanner when auditing"
  localize["LABEL.SPIN_AND_TURN_SCANNER"] = "Spin & Turn Scanner"
  localize["LABEL.SPIN_AND_TURN_SCANNER.SCAN_VALUE"] = "Spin&Turn Code"
  localize["LABEL.SPIN_AND_TURN_SCANNER.SCAN_VALUE.CTA"] = "Please enter the value appear on your device and verify."
  localize["LABEL.SPIN_AND_TURN_SCANNER.SCAN_VALUE.VERIFY"] = "Verify"
  localize["LABEL.SPIN_AND_TURN_SCANNER.SCAN_VALUE.ENTER_PREV_VALUE"] = "Please do not use previous value"

  /**
   * FIP-2686
   */
  localize["LABEL.LAST_LOGIN"] = "Last login"

  /**
   * FIP-2873
   */
  localize["LABEL.DOWNLOAD.DOWNLOAD_VIA_EMAIL.TITLE"] = "Download"
  localize["LABEL.DOWNLOAD.DOWNLOAD_VIA_EMAIL.DESCRIPTION"] = "You have tried to download a file for more than 100 products. This is a lot of information and it will take some time. Instead of the file being downloaded to your browser, we will send it to your registered email address."
  localize["LABEL.DOWNLOAD.PRODUCTS_CSV_EXPORT.DOWNLOAD_VIA_EMAIL"] = "You have tried to print a lots of products. This is a lot of information and it will take some time. Instead of the file being downloaded to your browser, we will send it to your registered email address."

  /**
   * FIP-2843
   *
   */
  localize["LABEL.ORDER.USE_MULTIPLE_FIBRETRACE_ID"] = "Use Multiple Fibretrace"
  localize["LABEL.ORDER.USE_MULTIPLE_FIBRETRACE_ID.TOOLTIP"] = "Use Multiple Fibretrace"

  /**
   * FIP-2654
   */
  localize["LABEL.TAGS.CURRENT"] = `Current tags`
  localize["LABEL.TAGS.UPDATE"] = `Updated tags`
  localize["LABEL.PRODUCT.TAGS.PREVIEW"] = `Product tags preview`
  localize["LABEL.PRODUCT.TAGS.PLEASE_SELECT"] = `Select tags for these products`
  localize["LABEL.TAGS.SAVE"] = `Save`
  localize["LABEL.TAGS.UPDATE_MULTIPLE"] = "Update tags "
  localize["LABEL.TAGS.EDIT_MULTIPLE"] = "Edit tags"

  localize["LABEL.PRODUCTS.ARCHIVE.EDIT_MULTIPLE"] = "Archive multiple products"
  localize["LABEL.PRODUCTS.ARCHIVE.DESCRIPTION"] = `Products with upcoming audits cannot be archived`
  localize["LABEL.PRODUCTS.ARCHIVE.PREVIEW"] = `Products preview`
  localize["LABEL.PRODUCTS.ARCHIVE.CAN_BE_ARCHIVED"] = `Can be archived`
  localize["LABEL.PRODUCTS.ARCHIVE.ARCHIVE_ALL"] = "Archive"

  /**
   * FIP-2969
   */
  localize["LABEL.AUDITS_CSV.DOWNLOAD_ALL"] = "Are you sure you want to download all data associated with this order? {0}";
  localize["LABEL.AUDITS_CSV.DOWNLOAD_ALL.NOTE"] = "If you click \"Yes\", a csv (comma separated value) file will be download now and other relevant information (transaction certificate, process certificate, etc.)"

  /**
   * FIP-2988
   */
  localize["ERROR.VALIDATE.PLEASE_RESTORE_CORRESPONDING_SITE_BEFORE_RESTORE_PROCESS"] = "Before you can restore the process it is connected to a Site that has been deleted. Please restore that first, then return here and restore the related processes."

  /**
   * FIP-2979
   */
  localize["LABEL.ACCOUNT.PURCHASE_DISABLED"] = "Purchases are temporally suspended. Please contact us to completed your transaction"

  return localize;
}

export interface LanguageState {
  localize: object
  lang: "vi" | "en" | "fr" | string
  languages?: any
  missing: string[]
  listLanguages: { name: string, code: string }[]
}

const initialState: LanguageState = {
  lang: "en",
  localize: _.merge(languages["en"], staticTranslation()) || {},
  languages: languages,
  missing: [],
  listLanguages: [
    {name: "English", code: "en"}
  ]
}

export const getAllTranslateAsync = createAsyncThunk(
  'language/getAllTranslates', async ({code = 'all', userLang = 'en'}: { code?: string, userLang?: string }) => {
    try {
      const {data} = await LANGUAGE_API.getTranslate(code);

      return {data, code, userLang}
    } catch (e) {
      console.error(e)
      return {data: {}, code, userLang}
    }

  })
export const getTranslateAsync = createAsyncThunk(
  'language/getTranslates', async ({code, userLang = 'en'}: { code: string, userLang: string }) => {
    try {
      const {data} = await LANGUAGE_API.getTranslate(code);

      return {data, code, userLang}
    } catch (e) {
      console.error(e)
      return {data: {}, code, userLang}
    }

  })

export const getListLanguages = createAsyncThunk(
  'language/getListLanguages', async ({signal}: { signal?: AbortSignal }) => {
    try {
      const {data} = await LANGUAGE_API.getListLang({signal});

      return {data}
    } catch (e) {
      console.error(e)
      return {data: []}
    }

  })

export const LanguageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setCurrentLang: (state, action: PayloadAction<LanguageState>) => {
      let localize = action.payload.localize;
      state.lang = action.payload.lang
      state.localize = localize
    },
    addItem: (state, action: PayloadAction<string>) => {
      if (!state.missing.includes(action.payload)) {
        state.missing.push(action.payload);
      }
    },
    removeItem: (state, action: PayloadAction<string>) => {
      const index = state.missing.indexOf(action.payload);
      if (index !== -1) {
        state.missing.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTranslateAsync.fulfilled, (state, action) => {
        const {data, userLang} = action.payload;
        if (userLang) state.lang = userLang
        if (data && userLang) {
          if (_.has(data, userLang)) {
            state.localize = _.merge(state.localize, data[userLang]) || {};
            // for local dev
            // state.localize = _.merge(data[userLang], state.localize) || {};
          }
        }
      })
      .addCase(getAllTranslateAsync.fulfilled, (state, action) => {
        const {data, userLang} = action.payload;
        if (userLang) state.lang = userLang
        if (data && userLang) {
          state.languages = data
          if (_.has(data, userLang)) {
            state.localize = _.merge(state.localize, data[userLang]) || {};
          }
        }
      })

      .addCase(getListLanguages.pending, (state, action) => {

      })
      .addCase(getListLanguages.rejected, (state, action) => {

      })
      .addCase(getListLanguages.fulfilled, (state, action) => {
        state.listLanguages = action.payload.data
      })

    ;
  },
});

export const {setCurrentLang} = LanguageSlice.actions;

export const languageState = (state: RootState) => state.language;
export const LOCALIZE = (state: RootState) => state.language.localize;

export default LanguageSlice.reducer;
