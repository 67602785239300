import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AccountPageTabs, ITabs} from "@src/types";
import {RootState} from "@redux/hooks/store";
import {SUBSCRIPTION_API} from "@api/Subscription";
import {ACCOUNT_API} from "@api/Account";


const dataTabs: ITabs[] = [
    {
        value: AccountPageTabs.ACCOUNT,
        display: "LABEL.TAB.ACCOUNT"
    },
    {
        value: AccountPageTabs.NOTIFICATIONS,
        display: "LABEL.TAB.NOTIFICATIONS"
    }
];

const dataTabsExtra: ITabs[] = [
    {
        value: AccountPageTabs.SERVICES,
        display: "LABEL.TAB.SERVICES"
    },
    {
        value: AccountPageTabs.PACKAGE,
        display: "LABEL.TAB.PACKAGE"
    },
    {
        value: AccountPageTabs.BILLING,
        display: "LABEL.TAB.BILLING"
    },
];

export const profilePageSlice = createSlice({
    name: 'profilePage',
    initialState: {
        selected: "",
        canNavigate: true,
        tabs: dataTabs,
        showExtraTabs: false
    },
    reducers: {
        setTab: (state, action: PayloadAction<any>) => {
            state.selected = action.payload;
        },
        setCanNavigate: (state, action: PayloadAction<any>) => {
            state.canNavigate = action.payload;
        },
        setProfilePage: (state, action: PayloadAction<any>) => {
            state.selected = action.payload.selected;
            state.canNavigate = action.payload.canNavigate;
        },
        addExtraTab: (state) => {
            state.tabs = [...dataTabs, ...dataTabsExtra]
            state.showExtraTabs = true
        },
    },
})


/**
 * Fetch data
 * @todo
 * remove axios wrapper to use signal in ThunkAPI
 * OR
 * write customer adaptor to handle use defined signal param
 */

export const fetchPackagesAsync = createAsyncThunk(
    'accountPackages/fetch',
    async ({signal}: any) => {
        try {
            return await SUBSCRIPTION_API.getPackages({signal});
        } catch (e) {
            throw e
        }
    });
export const fetchServicesAsync = createAsyncThunk(
    'accountServices/fetch',
    async ({signal}: any) => {
        try {
            return await ACCOUNT_API.getAccountServices({signal});
        } catch (error) {
            throw error;
        }
    }
);

export const accountServicesSlice = createSlice({
    name: 'accountServices',
    initialState: {
        loading: true,
        data: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchServicesAsync.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchServicesAsync.rejected, (state, action) => {
                state.loading = false;

            })
            .addCase(fetchServicesAsync.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload?.data) {
                    state.data = action.payload.data
                }
            })
    }
})


export const accountPackagesSlice = createSlice({
    name: 'accountPackages',
    initialState: {},
    reducers: {},
})

export const profilePageState = (state: RootState) => state.profilePage;
export const accountServicesState = (state: RootState) => state.accountServices;

export default profilePageSlice.reducer;
