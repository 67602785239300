import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";
import {serialize} from "object-to-formdata";

export const PARTNER_API = {

    getPartners: (data: any = {}) => {
        const {signal, ...params} = data;
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params, signal
        } as any;
        return axiosRequest(`/partners`, options, true, false);
    },

    invitePartner: (params: any = {}) => {
        return axiosRequest('/partners/invite', {
            method: 'POST',
            data: params,
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    acceptPartnership: ({uid}) => {
        return axiosRequest(`/partners/${uid}/accept`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    revokePartnership: ({uid}) => {
        return axiosRequest(`/partners/${uid}/remove`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    getPendingPartners: (params: any = {}, cancelToken: any) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params,
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/pending-partner-invitations`, options, true, false);
    },

    resendPendingPartner: ({uid}) => {
        return axiosRequest(`/pending-partner-invitations/${uid}/resend`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    deletePendingPartner: ({uid}) => {
        return axiosRequest(`/pending-partner-invitations/${uid}/delete`, {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    validateImportPartners: ({partner_list}) => {

        const token = AUTH.getToken();
        const form_data = serialize({partner_list});

        return axiosRequest(`/validate-import-partners`, {
            method: 'POST',
            body: form_data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            timeout: 120000,
        }, true, false);
    },

    importPartners: ({partner_list, import_partner_data}: { partner_list: unknown, import_partner_data: unknown }) => {
        return axiosRequest(`/import-partners`, {
            method: 'POST',
            data: {partner_list, import_partner_data},
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    getPartnersImportTemplate: (data: any = {}) => {
        const {signal, ...params} = data;

        let options = _.omitBy({
            method: 'GET',
            headers: {...AUTH.getHeader()},
            signal,
            params,
        }, _.isNil) as any;

        return axiosRequest(`/import-partners-template`, options, true, false);
    },


}
