import React, {ReactNode, useEffect} from 'react';
import Footer from '@components/Footer/Footer';
import fibtPoster from "@assets/images/ft_loop_background.png"
import fibtVideo from "@assets/videos/fibretrace_bg.mp4";

interface ITemplateSecondaryHOC {
    children: ReactNode,
    className?: string
}

const TemplateSecondaryHOC = ({children, className = ''}: ITemplateSecondaryHOC) => {

    useEffect(() => {
        const bodyEl = document.querySelector("body");
        if (bodyEl) bodyEl.classList.add("body-secondary");
        return () => {
            if (bodyEl) bodyEl.classList.remove("body-secondary");
        };
    }, []);
    return (
        <>
            <div className={`secondary-page ${className} video-page`}>

                <main>{children}</main>

                <div className={"video-bg-container "}>
                    <div className={"video-bg"}>
                        <video poster={fibtPoster} autoPlay playsInline muted loop>
                            <source src={fibtVideo} type="video/mp4"/>
                        </video>
                    </div>
                </div>

                <Footer/>

            </div>

        </>
    )
}

export default TemplateSecondaryHOC;
