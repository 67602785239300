import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPONENT_INTERACTION, PAGING_DATA, QUERIES} from "@src/types";
import {APP_ROUTES_NAME} from "@src/routes";
import {DEFAULT_VARIABLES} from "@src/variables";
import {PRODUCTS_API} from "@api/Product";
import _ from "lodash-es";

const initialState: COMPONENT_INTERACTION = {
    name: APP_ROUTES_NAME.PRODUCTS,
    search: {
        ...DEFAULT_VARIABLES.SEARCH_SETTINGS,
        queries: {
            ...DEFAULT_VARIABLES.SEARCH_SETTINGS.queries,
            sort: 'asc',
            sort_by: 'name'
        }
    },
    loading: false,
    data: []
}

export const fetchInventoryAsync = createAsyncThunk(
    'inventory/fetch',
    async (queries: QUERIES) => {
        try {
            const r = await PRODUCTS_API.getInventory(_.omitBy(queries, _.isNil), {});
            if (r) {
                const {data, meta, error} = r;
                if (data && !error) {
                    return {data, meta, queries}
                }
            }
            return {data: [], meta: {}, queries}
        } catch (e) {
            console.error(e)
            return {data: [], meta: {}, queries}
        }
    }
);

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        setInventoryState: (state, action: PayloadAction<COMPONENT_INTERACTION>) => {
            state = {...action.payload}
            return state
        },
        setInventoryData: (state, action: PayloadAction<[]>) => {
            state = {
                ...state,
                data: [...action.payload]
            }
            return state
        },
        setInventoryQueries: (state, action: PayloadAction<QUERIES>) => {
            state = {
                ...state,
                search: {
                    ...state.search,
                    queries: {...action.payload}
                }
            }
            return state
        },
        setInventoryPaging: (state, action: PayloadAction<PAGING_DATA>) => {
            state = {
                ...state,
                search: {
                    ...state.search,
                    pagingData: {...action.payload}
                }
            }
            return state
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInventoryAsync.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchInventoryAsync.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchInventoryAsync.fulfilled, (state, action) => {
                const {data, meta, queries} = action.payload
                const total_pages = Math.ceil(meta.total / queries.number_per_page)

                if (!_.size(data) && queries.page > 1) {
                    queries.page = (meta.current_page - 1);
                }

                state.data = data
                state.search.queries = _.merge(state.search.queries, queries)
                state.search.pagingData = {...meta, total_pages}
                state.loading = false;

            });
    },
});

export const {setInventoryData, setInventoryQueries, setInventoryPaging, setInventoryState} = inventorySlice.actions;

export const inventoryState = (state: RootState) => state.inventory;

export default inventorySlice.reducer;
