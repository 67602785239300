import {axiosRequest, axiosRequestAlt} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";
import {serialize} from "object-to-formdata";
import axios from "axios";

export const PRODUCTS_API = {
    getManufacturers: () => {
        return axiosRequest('/manufacturers', {
            method: 'GET',
            headers: {...AUTH.getHeader()}
        }, false);
    },

    getManufacturersV2: (data: { signal?: AbortSignal }) => {
        const {signal, ...params} = data;
        return axiosRequestAlt('/manufacturers', _.omitBy({
            method: 'GET',
            params, signal,
            headers: {...AUTH.getHeader()},
        }, _.isNil), false);
    },

    getProcessByManufacturer: (id: any, data: any = {}) => {
        try {
            if (id) {
                const {signal, ...params} = data;
                const token = AUTH.getToken();
                return axiosRequest(`/manufacturers/${id}/processes`, _.omitBy({
                    method: 'GET',
                    params, signal,
                    headers: {...AUTH.getHeader()}
                }, _.isNil), true, false);
            }
        } catch (e) {
            console.error(e)
        }
    },

    getProducts: async (data: object = {}, cancelToken = {}) => {
        let options = {
            method: 'GET',
            params: data,
            headers: {...AUTH.getHeader()}
        } as any
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest('/products', options, false);
    },

    createProduct: (data: any = {}) => {
        const token = AUTH.getToken();
        // const form_data = objectToFormData(data);
        return axiosRequest('/products', {
            method: 'POST',
            data: data,
            headers: {...AUTH.getHeader()}
        }, false);
    },

    getProductDetail: () => {
        const token = AUTH.getToken();
        return axiosRequest('/products', {
            method: 'GET',
            headers: {...AUTH.getHeader()}
        }, false);
    },

    updateProductDetail: (data: any = {}) => {
        let {attributeTemplateValue, id} = data

        if (id) {
            if (attributeTemplateValue && _.isObject(attributeTemplateValue)) {
                _.map(attributeTemplateValue, (template: any, k: any) => {
                    let {content} = template
                    _.unset(template, 'pivot')
                    _.unset(template, 'uid')
                    if (_.isString(content)) {
                        _.unset(content, 'ref')
                        _.unset(content, 'pivot')
                        template.content = JSON.parse(content || "")
                    }
                    return template;
                });

                data.attributeTemplateValue = JSON.stringify(attributeTemplateValue);
            }

            return axiosRequest(`/products/${id}`, {
                method: 'POST',
                headers: {...AUTH.getHeader()},
                data,
            }, true, false);
        }

        return false
    },

    deleteProduct: ({id}: any) => {
        const token = AUTH.getToken();
        return axiosRequest(`/products/${id}`, {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    restoreProduct: ({id}: any) => {
        return axiosRequest(`/products/${id}/restore`, {
            method: 'POST',
            headers: {
                ...AUTH.getHeader()
            },
        }, true, false);
    },

    getImportProductTemplates: (data = {} as any) => {
        const token = AUTH.getToken();
        const form_data = serialize(data);

        return axiosRequest(`/import-products/templates`, {
            method: 'POST',
            body: form_data,
            headers: {...AUTH.getFormHeader()},
        }, true, false);
    },

    importProducts: (data = {} as any) => {
        const token = AUTH.getToken();
        const form_data = serialize(data);

        return axiosRequest(`/import-products/submit`, {
            method: 'POST',
            body: form_data,
            headers: {...AUTH.getFormHeader()},
        }, true, false);
    },

    duplicateProduct: async (data: { product_id: number | string }, cancelToken = {}) => {
        let {product_id} = data;
        if (product_id) {
            let options = {
                method: 'POST',
                data,
                headers: {...AUTH.getHeader()},
            } as any
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return axiosRequest('/duplicate-product', options, false);
        }
        return false;
    },

    getInventory: async (data: object = {}, cancelToken = {}) => {
        let options = {
            method: 'GET',
            params: data,
            headers: {...AUTH.getHeader()},
        } as any
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest('/inventory', options, false);
    },

    returnInventory: async (data: { order_code: any | string }, cancelToken = {}) => {
        let {order_code} = data;
        if (order_code) {
            let options = {
                method: 'POST',
                data,
                headers: {...AUTH.getHeader()},
            } as any
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return axiosRequest('/return-inventory', options, false);
        }
        return false;
    },

    getProductItemDetail: async (data: any = {}, cancelToken = {}) => {
        let {uid} = data;
        let options = {
            method: 'GET',
            params: data,
            headers: {...AUTH.getHeader()},
        } as any
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/products/${uid}/get-one`, options, false, false);
    },

    deleteProductImage: ({id}: any) => {
        return axiosRequest(`/product-image`, {
            method: 'DELETE',
            headers: {...AUTH.getHeader()},
            data: {id},
        }, true, false);
    },

    archiveProduct: async (data: any = {}) => {
        try {
            let {id, signal} = data;
            let options = _.omitBy({
                method: 'POST',
                signal,
                headers: {...AUTH.getHeader()},
            }, _.isNil) as any

            return axiosRequest(`/products/${id}/archive`, options, false, false);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.error("archiveProduct", error)
            } else {
                console.error("archiveProduct", error)
            }
        }
    },

    unarchiveProduct: async (data: any = {}) => {
        try {
            let {id, signal} = data;
            let options = _.omitBy({
                method: 'POST',
                signal,
                headers: {...AUTH.getHeader()},
            }, _.isNil) as any

            return axiosRequest(`/products/${id}/unarchive`, options, false, false);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.error("archiveProduct", error)
            } else {
                console.error("archiveProduct", error)
            }
        }

    },

}

