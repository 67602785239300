import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {AccountPageTabs, AuditTabsV2, CompanyTabs, OrderTabs, ProductPageTabs} from "@src/types";
import {APP_ROUTES_NAME} from "@src/routes";

// type={searchSettings.selected === ProductPageTabs.PRODUCTS ? "products" : "productTemplates"}
// title={searchSettings.selected === ProductPageTabs.PRODUCTS ? "LABEL.PRODUCTS" : "LABEL.PRODUCT_TEMPLATES"}
// queries={searchSettings.queries}
// pagingData={searchSettings.pagingData}
// currentTab={searchSettings.selected}
// printRef={printRef}
// tabs={dataTabs}
// onTabChange={searchSettingsHandle.onTabChange}
// onChange={searchSettingsHandle.onChange}
// onAfterPrint={() => setDataForPrint([])}
// getData={() => getDataForPrint(

interface PAGE {
    title?: string | any
    page?: APP_ROUTES_NAME,
    tabs?: []
    selectedTab?: AccountPageTabs | CompanyTabs | ProductPageTabs | OrderTabs | AuditTabsV2 | any
}

const initialState: PAGE = {
    page: APP_ROUTES_NAME.DASHBOARD,
    selectedTab: ""
}

export const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<PAGE>) => {
            state = {...action.payload}
            return state
        },
    },
});

export const {setPage} = pageSlice.actions;

export const pageState = (state: RootState) => state.page;

export default pageSlice.reducer;
