export enum APP_ROUTES_NAME {
    LOGIN = "LOGIN",
    REGISTER = "REGISTER",
    PRODUCTS = "PRODUCTS",
    PROFILE = "PROFILE",
    FORGOT_PASSWORD = "FORGOT_PASSWORD",
    THANK_YOU = "THANK_YOU",
    CONTACT = "CONTACT",
    COMPANY = "COMPANY",
    MY_AUDITS = "MY_AUDITS",
    PENDING_AUDITS = "PENDING_AUDITS",
    UPCOMING_AUDITS = "UPCOMING_AUDITS",
    PREVIOUS_AUDITS = "PREVIOUS_AUDITS",
    RECEIVED_AUDITS = "RECEIVED_AUDITS",
    ACCEPTED_AUDITS = "ACCEPTED_AUDITS",
    REJECTED_AUDITS = "REJECTED_AUDITS",
    MY_SCANNERS = "MY_SCANNERS",
    ORDERS = "ORDERS",
    ANALYSIS = "ANALYSIS",
    AUDIT_TEMPLATES = "AUDIT_TEMPLATES",
    PAGE_ERROR = "PAGE_ERROR",
    MAINTENANCE = "MAINTENANCE",
    AFTER_CHANGE_PASSWORD = "AFTER_CHANGE_PASSWORD",
    FORBIDDEN = "FORBIDDEN",
    FAQ = "FAQ",
    NOT_FOUND = "NOT_FOUND",
    SHARE_MY_AUDITS = "SHARE_MY_AUDITS",
    ABOUT_FIBRETRACE = "ABOUT_FIBRETRACE",
    DASHBOARD = "DASHBOARD",
    WELCOME = "WELCOME",
    NOTIFICATION = "NOTIFICATION",
    DEMO = "DEMO",
    HELP = "HELP",
    REQUEST_TO_JOIN = "REQUEST_TO_JOIN",
    COOKIE_POLICY = "COOKIE_POLICY"
}

export enum APP_ROUTES_PATH {
    LOGIN = "/login",
    REGISTER = "/register",
    PRODUCTS = "/products",
    PROFILE = "/profile",
    FORGOT_PASSWORD = "/forgot-password",
    THANK_YOU = "/thank-you",
    CONTACT = "/contact",
    COMPANY = "/company",
    MY_AUDITS = "/my-audits",
    PENDING_AUDITS = "/my-audits?t=PENDING_AUDITS",
    UPCOMING_AUDITS = "/my-audits?t=UPCOMING_AUDITS",
    PREVIOUS_AUDITS = "/my-audits?t=PREVIOUS_AUDITS",
    RECEIVED_AUDITS = "/received-audits",
    ACCEPTED_AUDITS = "/accepted-audits",
    REJECTED_AUDITS = "/rejected-audits",
    MY_SCANNERS = "/my-scanners",
    ORDERS = "/orders",
    ANALYSIS = "/analysis",
    AUDIT_TEMPLATES = "/audit-templates",
    PAGE_ERROR = "/page-error",
    MAINTENANCE = "/maintenance",
    AFTER_CHANGE_PASSWORD = "/after-change",
    FORBIDDEN = "/forbidden",
    FAQ = "/faqs",
    NOT_FOUND = "/not-found",
    SHARE_MY_AUDITS = "/my-audits",
    ABOUT_FIBRETRACE = "/about",
    DASHBOARD = "/dashboard",
    NOTIFICATION = "/notifications",
    DEMO = "/demo",
    HELP = "/help",
    REQUEST_TO_JOIN = "/request-to-join",
    COOKIE_POLICY = "/webapp-privacy-policy",
    SHOPIFY_AUTHENTICATE="/shopify/authenticate"

}
