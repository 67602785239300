import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";
import axios from "axios";

export const SUBSCRIPTION_API = {
    getPackages: async (data: any = {}) => {
        try {
            const {signal} = data;

            return await axiosRequest('/subscriptions', _.omitBy({
                    method: 'GET',
                    data, signal,
                    headers: {...AUTH.getHeader()},
                }, _.isNil),
                false);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled: getPackages', error.message);
            } else {
                throw error;
            }
        }
    },
    updateCompanySubscription: async (data: object) => {
        // http://admin.fibretrace.test/api/v1/company/subscription
        /**
         * data eg:
         * {
         *     "subscription_id": 4,
         *     "paypal_subscription_id": 5,
         *     "paypal_plan_id": 5,
         *     "paypal_billing_info": 5
         * }
         */
        const req = await axiosRequest('/company/subscription', {
            method: 'POST',
            data: {...data},
            headers: {...AUTH.getHeader()},
        }, false);

        return req;
    },

    downgradeCompanySubscription: async () => {
        const req = await axiosRequest('/company/subscription/downgrade', {
            method: 'PUT',
            headers: {...AUTH.getHeader()},
        }, false);

        return req;
    },

    getInvoices: async (data: any = {}, cancelToken: any = {}) => {
        try {
            const {signal} = data
            let options = _.omitBy({
                method: 'GET',
                params: data,
                signal,
                headers: {...AUTH.getHeader()}
            }, _.isNil) as any;
            try {
                return await axiosRequest('/company/invoices', options);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled: getInvoices', error.message);
                } else {
                    throw error;
                }
            }
        } catch (e) {
            console.error(e)
        }
    },

    getInvoiceDetail: async (data: any = {}, cancelToken: any = {}) => {
        try {
            try {
                const {code, signal} = data;

                if (code) {
                    let options = _.omitBy({
                        method: 'GET',
                        params: data, signal,
                        headers: {...AUTH.getHeader()}
                    }, _.isNil) as any;

                    return await axiosRequest(`/company/invoices/${code}`, options);
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled: getInvoices', error.message);
                } else {
                    throw error;
                }
            }

        } catch (e) {
            console.error(e)
        }
    },

    updateInvoice: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let options = {
                method: 'POST',
                headers: {...AUTH.getHeader()},
                data
            } as any;
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return await axiosRequest('/company/invoices', options);
        } catch (e) {
            console.error(e)
        }
    },

    cancelPaypalOrder: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let {paypal_order_id} = data;
            if (paypal_order_id) {
                let options = {
                    method: 'POST',
                    headers: {...AUTH.getHeader()},
                    data
                } as any;
                if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
                return await axiosRequest(`/company/invoices/${paypal_order_id}/cancel`, options);
            }
            return false
        } catch (e) {
            console.error(e)
        }
    },

    createPaypalOrderTransactionCredit: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let {quantity} = data;
            if (quantity) {
                let options = {
                    method: 'POST',
                    headers: {...AUTH.getHeader()},
                    data
                } as any;
                if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
                return await axiosRequest('/company/invoices/order-transaction-credit', options);
            }
            return false
        } catch (e) {
            console.error(e)
        }
    },

    createPaypalOrderScanner: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let {quantity} = data;
            if (quantity) {
                let options = {
                    method: 'POST',
                    headers: {...AUTH.getHeader()},
                    data
                } as any;
                if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
                return await axiosRequest('/company/invoices/order-scanner', options);
            }
            return false
        } catch (e) {
            console.error(e)
        }
    },

    createPaypalOrder: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let {invoice_code} = data;
            if (invoice_code) {
                let options = {
                    method: 'POST',
                    headers: {...AUTH.getHeader()},
                    data
                } as any;
                if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
                return await axiosRequest('/company/invoices/create', options);
            }
            return false
        } catch (e) {
            console.error(e)
        }
    },

    updatePaypalOrder: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let {orderId} = data;
            if (orderId) {
                let options = {
                    method: 'POST',
                    headers: {...AUTH.getHeader()},
                    data
                } as any;
                if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
                return await axiosRequest(`/company/invoices/${orderId}/capture`, options);
            }
            return false
        } catch (e) {
            console.error(e)
        }
    },

    updateTransactionCreditOrder: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let {orderId} = data;
            if (orderId) {
                let options = {
                    method: 'POST',
                    headers: {...AUTH.getHeader()},
                    data
                } as any;
                if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
                return await axiosRequest(`/company/transaction-credit-invoices/${orderId}/capture`, options);
            }
            return false
        } catch (e) {
            console.error(e)
        }
    },

    exportAllBillings: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let options = {
                method: 'POST',
                headers: {...AUTH.getHeader()},
                data
            } as any;
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return await axiosRequest(`/company/invoices/csv`, options);
        } catch (e) {
            console.error(e)
        }
    },

    markAsBankTransferSent: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let {invoiceID} = data;

            let options = {
                method: 'POST',
                headers: {...AUTH.getHeader()},
                data
            } as any;

            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken

            return await axiosRequest(`/company/invoices/${invoiceID}/markAsBankTransferSent`, options);

        } catch (e) {
            console.error(e)
        }
    },

    createOrderScannerSubscription: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let {quantity, is_commercial_invoice, po_number} = data;
            if (quantity) {
                let options = {
                    method: 'POST',
                    headers: {...AUTH.getHeader()},
                    data
                } as any;
                if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
                return await axiosRequest('/company/invoices/order-scanner', options);
            }
            return false
        } catch (e) {
            console.error(e)
        }
    },

    createOrderCreditSubscription: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let {quantity, is_commercial_invoice, po_number} = data;
            if (quantity) {
                let options = {
                    method: 'POST',
                    headers: {...AUTH.getHeader()},
                    data
                } as any;
                if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
                return await axiosRequest('/company/invoices/order-transaction-credit', options);
            }
            return false
        } catch (e) {
            console.error(e)
        }
    },

    createOrderSubscription: async (data: any = {}, cancelToken: any = {}) => {
        try {
            let {plan_id, is_commercial_invoice, po_number} = data;
            if (plan_id) {
                let options = {
                    method: 'POST',
                    headers: {...AUTH.getHeader()},
                    data
                } as any;
                if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
                return await axiosRequest('/company/invoices/order-subscription', options);
            }
            return false
        } catch (e) {
            console.error(e)
        }
    },


}