import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {DEFAULT_MODAL} from "@src/variables";
import {MessageInterface} from "@redux/reducers/messageSlice";

interface ILinkedOrdersModal extends MessageInterface {
  data: any
}

const initialState: ILinkedOrdersModal = {
  ...DEFAULT_MODAL, size: "lg", cancelButton: {
    visible: true,
    variant: "outline-dark",
    label: "Close"
  },
  data: []
}

export const linkedOrdersMessageSlice = createSlice({
  name: 'linkedOrders',
  initialState,
  reducers: {
    showMessage: (state, action: PayloadAction<ILinkedOrdersModal>) => {
      Object.assign(state, action.payload, {isShow: true})
    },
    hideMessage: (state) => {
      state.isShow = false;
    }
  },
});

export const linkedOrdersState = (state: RootState) => state.linkedOrders;
export default linkedOrdersMessageSlice.reducer
