import React from "react";
import {translateV2} from "@src/helpers";
import {DEFAULT_MODAL} from "@src/variables";
import _ from "lodash-es";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const styles = {
  btnView: {
    lineHeight: "44px"
  }
}

const InputProducts = ({data = [], modal = {...DEFAULT_MODAL}}: any) => {

  if (_.isEmpty(data)) return (<></>);

  return (
    <table className="table-parent table-b2c-products">
      <thead>
      <tr>
        <th className={"text-center"}>{translateV2("LABEL.PRODUCT_IMAGE")}</th>
        <th>{translateV2("LABEL.PRODUCT_NAME")}</th>
        <th className="d-none d-sm-table-cell">{translateV2("LABEL.MANUFACTURER")}</th>
        <th className="d-none d-sm-table-cell">{translateV2("LABEL.CONNECTED_ORDERS")}</th>
        {/*<th>Order Code</th>*/}
      </tr>
      </thead>
      <tbody>
      {
        data && data.map((product: any, index: number) => {
          return <tr key={`b2c-p-${index}`}>
            <td className={"b2c-p-thumb"}>
                          {
                            product.image
                              ? <img src={product.image} width={150} alt={product.name}/>
                              : <FontAwesomeIcon icon={faImage}
                                                 style={{
                                                   fontSize: "12em",
                                                   marginTop: "-0.125em",
                                                   maxWidth: "100%"
                                                 }}/>
                          }
            </td>
            <td className={"b2c-p-name"}>
              <b>{product.name}</b>

              <div className={"d-block d-sm-none mt-3"}>
                <p>{translateV2("LABEL.QUANTITY")}: {product.quantity}</p>
                <p>{translateV2("LABEL.MANUFACTURER")}: {product?.company}</p>
              </div>

              <hr className={"d-block d-sm-none mb-1"}/>

              <table className="d-table d-sm-none table-child table-b2c-porders"
                     style={{width: 100 + '%'}}>
                <thead>
                <tr>
                  <th>{translateV2("LABEL.CONNECTED_ORDERS")}</th>
                  <th style={{width: "100px"}}
                      className={"text-center"}>{translateV2("LABEL.ORDER.ORDER_DATE")}</th>
                </tr>
                </thead>
                <tbody>
                {
                  product.orders && product.orders.map((order: any, oi: number) => {
                    return <React.Fragment key={`b2c-o-${oi}`}>
                      <tr>
                        <td className="d-none d-sm-table-cell">
                          <p>
                            {order.order_code}
                            <br/>{translateV2("LABEL.REFERENCE_ID")}: {order.reference_id}
                            <br/>{translateV2("LABEL.QUANTITY")}: {order.quantity}
                            <br/>{translateV2("LABEL.ORDER.ORDER_DATE")}: {order.order_date}
                          </p>
                        </td>
                        <td className={""}>
                          <a className={"d-none d-sm-block btn-green"} target="_blank"
                             rel={"noopener noreferrer"}
                             href={order.b2c_url}
                             title={`Order Code: ${order.order_code}`}>
                            {translateV2("LABEL.VIEW")}
                          </a>


                          <a className={"d-block d-sm-none btn-green text-center"} target="_blank"
                             rel={"noopener noreferrer"}
                             href={order.b2c_url}
                             title={`Order Code: ${order.order_code}`}>
                            {order.order_code}
                            {
                              order.reference_id
                              ? `(${order.reference_id})` : ""
                            }
                          </a>

                        </td>

                        <td className={"d-table-cell d-sm-none align-middle text-center"}>
                          {order.order_date}
                        </td>

                      </tr>

                      <tr>
                        <td colSpan={2}>
                          <b>{translateV2("LABEL.UOM.ABBRE")}</b>: {order.uom}
                        </td>
                      </tr>

                    </React.Fragment>
                  })
                }</tbody>
              </table>

            </td>

            <td className={"d-none d-sm-table-cell b2c-p-manu"}>
              {product?.company}
            </td>

            <td className={"b2c-p-action d-none d-sm-table-cell"}>

              <table className="table-child table-b2c-porders" style={{width: 100 + '%'}}>
                <tbody>
                {
                  product.orders && product.orders.map((order: any, oi: number) => {
                    return <tr key={`b2c-o-${oi}`}>
                      <td className="">
                        <p>
                          {order.order_code}
                          <br/>{translateV2("LABEL.REFERENCE_ID")}: {order.reference_id}
                          <br/>{translateV2("LABEL.QUANTITY")}: {order.quantity}
                          <br/>{translateV2("LABEL.ORDER.ORDER_DATE")}: {order.order_date}
                          <br/>{translateV2("LABEL.UOM.ABBRE")}: {order.uom}
                        </p>
                      </td>
                      <td>
                        <a className={"d-none d-sm-block btn-green text-center "} target="_blank"
                           rel={"noopener noreferrer"}
                           href={order.b2c_url} style={styles.btnView}
                           title={`Order Code: ${order.order_code}`}>
                          {_.upperFirst(translateV2("LABEL.VIEW").toLocaleLowerCase())}
                        </a>
                      </td>
                    </tr>
                  })
                }</tbody>
              </table>
            </td>

          </tr>
        })
      }
      </tbody>
    </table>
  );
}


export default InputProducts;
