import {axiosRequest, axiosRequestCustom} from '@src/helpers';
import {AUTH} from "@api/Auth";
import {ENV} from "../../environments/environment";
import _ from "lodash-es";
import axios from "axios";

export const COMMON_API = {
    getConfig: () => {
        return axiosRequest('/get-basic-config', {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, false, false);
    },

    getDefaultUOM: () => {
        return axiosRequest('/unit-of-measurements/default', {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        });
    },

    getServerYear: () => {
        return axiosRequest('/get-year', {
            method: 'GET',
        });
    },

    contactUs: (data: any) => {
        return axiosRequest('/contact', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {...AUTH.getHeader()},
        }, false);
    },


    getStatics: async (params?: {
        type?: string,
        domain?: string,
        lang?: string,
        signal?: AbortSignal | undefined
    }) => {
        try {
            const signal: AbortSignal | undefined = params?.signal
            return await axiosRequest('/static-pages', _.omitBy({
                method: 'GET',
                signal,
                cache: {
                    maxAge: 60 * 60 * 1000,
                    exclude: {query: false}
                },
                params: _.omit(_.omitBy(params, _.isNil), 'signal')
            }, _.isNil), false, false);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.log('Request canceled:', 'getStatics', e.message);
            } else {

            }
            return {data: [], error: 500}
        }

    },

    downloadQr: (order_code: string) => {
        return axiosRequest('/get-order-qr-image', {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params: {
                order_code
            }
        });
    },

    getTransaction: () => {
        return axiosRequest('/get-two-factor-transaction', {
            method: 'POST',
            headers: {...AUTH.getHeader()}
        });
    },

    getSubjectContact: (lang: string = 'en') => {
        return axiosRequest('/contact/subjects',
            {
                method: 'GET',
                params: {lang},
                headers: {...AUTH.getHeader()}
            },
            false, false
        );
    },

    getCountry: async () => {

        const req = await axiosRequest('/countries',
            {
                method: 'GET',
                headers: {...AUTH.getHeader()}
            },
            true, false
        );

        return req;
    },

    getUnitOfMeasurements: async () => {

        const req = await axiosRequest('/unit-of-measurements',
            {
                method: 'GET',
                headers: {...AUTH.getHeader()}
            },
            true, false
        );

        return req;
    },

    uploadFile: async (file: any) => {
        let formData = new FormData();
        formData.append('file', file);

        const response = await fetch(`${ENV.REACT_APP_API_URL}/upload-analysis-media`, {
            method: 'POST',
            headers: {...AUTH.getHeader()},
            body: formData
        });

        return await response.json();
    },

    getDemoContent: async () => {
        return axiosRequest('/user/demo-content', {
            method: 'GET',
            headers: {...AUTH.getHeader()}
        }, true, false);
    },

    getDemoContentAudit: async () => {
        return axiosRequest('/user/demo-content/audits', {
            method: 'GET',
            headers: {...AUTH.getHeader()}
        }, true, false);
    },

    auditDemoContentAudit: async (body = {} as any) => {
        return axiosRequest('/user/demo-content/audits', {
            method: 'POST',
            headers: {...AUTH.getHeader()},
            body: body
        }, true, false);
    },

    downloadTransactionCertificate: (code: string) => {
        return axiosRequest('/order/' + code + '/transaction-certificate', {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        });
    },

    getReferralQuestions: (lang: string = 'en') => {
        return axiosRequest(`/referral-questions`, {
            method: 'GET',
            params: {lang},
            headers: {...AUTH.getHeader()},
        });
    },

    getCFGeoData: async (lang: string = 'en') => {
        try {
            let req = await axiosRequestCustom({
                url: `/cloudfront-viewer`,
                options: {
                    method: 'GET',
                },
                withBearer: false,
                errorRedirect: false
            })
            return req;
        } catch (error) {
            return {headers:{}};
        }

    },

    reportMissingLabel: async (body = {} as any) => {
        return axiosRequest('/missing-label', {
            method: 'POST',
            headers: {...AUTH.getHeader()},
            body
        }, true, false);
    },

}
