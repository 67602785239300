import {translateV2} from "@src/helpers";
import {Image, OverlayTrigger, Tooltip} from "react-bootstrap";
import _ from "lodash-es";
import {ASSETS} from "@assets/Assets";
import React from "react";

const NoConnectedOrders = () => {

    return <div className={"d-flex justify-content-between"}>
        {translateV2("LABEL.ORDER_PROCESS.NO_CONNECTED_ORDERS")}
        <OverlayTrigger
            overlay={<Tooltip
                bsPrefix={"fib-bs-tooltip tooltip"}
                id={_.uniqueId('tooltip_')}>
                {translateV2("LABEL.ORDER_PROCESS.NO_CONNECTED_ORDERS")}
            </Tooltip>}>

            <Image
                className={"ml-2"}
                src={ASSETS.IconExclaimationBlackV2} alt={"order status"}
                width={18} height={18}
            />
        </OverlayTrigger>
    </div>
}

export default NoConnectedOrders;