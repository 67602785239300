import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "@redux/hooks/store";
import {COMPONENT_INTERACTION, E_AUDIT_CLAIMED_STATUS, ORDER_SUMMARY} from "@src/types";
import {ORDER_API} from "@api/Order";
import {canViewStep} from "@v2pages/OrderDetail/OrderStepsHistoryV2";
import {IMap} from "@v2components/PosMap/POSMapV2";
import {APP_ROUTES_NAME} from "@src/routes";
import {DEFAULT_ORDER_SUMMARY, DEFAULT_VARIABLES} from "@src/variables";
import _ from "lodash-es";

interface I_OrderDetailStats {
  order_process_percentage: number
  order_process_scanned: number
  order_process_total: number
}

interface I_OrderDetailSummary {
  summary: ORDER_SUMMARY & {
    loading: boolean,
    editable: boolean,
    orderDetail: any
  },
  histories: {
    loading: boolean,
    processes: IHistory[]
    processesMap: []
    currentHistory: number | string
  }
  formDetail: any,
}

const initialState: I_OrderDetailSummary = {
  summary: {
    loading: true,
    order_code: '',
    order_date: '',
    order_quantity: 0,
    order_image: '',
    manufacturer_name: '',
    manufacturer_location: '',
    order_process_total: 0,
    order_process_scanned: 0,
    order_process_percentage: 0,
    manufacturer_long: '',
    manufacturer_lat: '',
    manufacturer_url: '',
    order_b2c_url: '',
    new_order_b2c_url: '',
    qr_image: '',
    qr_image_name: '',
    is_completed: false,
    fibretrace_id: null,
    product_name: '',
    reference_id: '',
    retail_date: '',
    connected_orders: 0,
    product_tags: [],
    orderDetail: {},
    editable: false,
    alpha2Code: "",
    uom: "",
  },
  histories: {
    loading: true,
    processes: [],
    processesMap: [],
    currentHistory: -1
  },
  formDetail: {},
};

export interface IHistory {
  process_position: number,
  process_id: string | number,
  process_name: string,
  process_date: string,
  process_location: string,
  process_status: number,
  process_manufacturer_name: string,
  process_certificate_count: number,
  custom_scan_date: string,
  scanned_times_and_scan_count: string,
  can_be_deleted: boolean,
  input_orders_count: boolean,
  can_not_delete_reason: string
  input_products: {
    editable: boolean
    value: []
  },
  claimed_status: E_AUDIT_CLAIMED_STATUS
}

export const fetchOrderSummaryAsync = createAsyncThunk(
  'orderDetailPage/fetchSummary',
  async ({code, signal}: { code: string, signal?: AbortSignal }, {getState}) => {
    try {

      const [orderSummaryReq, orderDetailReq] = await Promise.all([
        ORDER_API.getOrderSummaryV2({code, signal}),
        ORDER_API.getOrderDetailV2({code, signal})
      ])

      const orderSummary = orderSummaryReq.data;
      const orderDetail = orderDetailReq.data;

      if (orderSummary && orderDetail) {
        let t = {
          ...DEFAULT_ORDER_SUMMARY,
          ...orderSummary.order,
          ...orderSummary.order_process_latest,
          ...orderSummary.order_process_summary,
          fibretrace_id: orderDetail.fibretrace_id.value || null,
          reference_id: orderDetail.reference_id.value,
          retail_date: orderDetail.retail_date.value,
        }

        _.forEach(orderDetail, (v, key) => {
          t[key] = v.value || null
        });


        /**
         * @todo:
         * orderDetail:t is used mostly in OrderProcessFormV2.
         * should refactor OrderProcessFormV2 to use state.summary in stead of state.summary.orderDetail
         */

        return {
          summary: {
            ...orderSummary.order,
            ...orderSummary.order_process_latest,
            ...orderSummary.order_process_summary,
            fibretrace_id: orderDetail.fibretrace_id.value || null,
            reference_id: orderDetail.reference_id.value,
            retail_date: orderDetail.retail_date.value,
            editable: orderDetail.editable,
            orderDetail: t
          }, formDetail: orderDetail
        }
      }

      return {summary: initialState.summary, formDetail: orderDetail}
    } catch (e) {
      console.error(e)
      return {summary: initialState.summary, formDetail: {}}
    }
  }
);

export const fetchOrderStatsAsync = createAsyncThunk<I_OrderDetailStats,
  {
    order_code: string,
    signal?: AbortSignal
  }
>(
  'orderDetailPage/fetchStats',
  async (
    {order_code, signal}: {
      order_code: string,
      signal?: AbortSignal
    }, {getState, rejectWithValue}) => {
    try {
      const {data, error} = await ORDER_API.getOrderStats({
        order_code,
        signal,
      })

      if (!error) {
        return data as I_OrderDetailStats
      }

      return rejectWithValue(error)

    } catch (e) {
      console.error(e)
      return {
        order_process_percentage: 0,
        order_process_scanned: 0,
        order_process_total: 0,
      }
    }
  }
);

export const fetchOrderHistoriesAsync = createAsyncThunk<any,
  {
    order_code?: string
    status?: null | string | undefined
    isSort?: null | string | undefined | boolean
    signal?: AbortSignal
  }
>(
  'orderProcesses/fetchHistories',
  async (
    {order_code, status = null, isSort = null, signal}: {
      order_code?: string
      status?: null | string | undefined
      isSort?: null | string | undefined | boolean
      signal?: AbortSignal
    }, {rejectWithValue}) => {
    try {

      const {data, error} = await ORDER_API.getOrderHistoryV2({order_code, status, isSort});

      if (data && !error) {
        const dataHisMap = data.filter((step: IHistory) => canViewStep(step.process_status, step.custom_scan_date));
        const curHis: IHistory | undefined = data.find((item: IHistory) => canViewStep(item.process_status, item.custom_scan_date));

        dataHisMap.sort((a: IMap, b: IMap) => a.process_position - b.process_position);

        return {
          queries: {order_code, status, isSort},
          processes: data,
          processesMap: dataHisMap || [],
          currentHistory: curHis?.process_position,
        }
      }

      return rejectWithValue(error)

    } catch (e) {
      console.error(e)
      return {
        processes: [],
        processesMap: [],
        currentHistory: -1
      }
    }
  }
);

export const orderDetailPageSlice = createSlice({
  name: 'orderDetailPage',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderSummaryAsync.pending, (state, action) => {
        state.summary.loading = true;
      })
      .addCase(fetchOrderSummaryAsync.rejected, (state, action) => {
        state.summary.loading = false;

      })
      .addCase(fetchOrderSummaryAsync.fulfilled, (state, action) => {
        state.summary.loading = false;
        state.summary = action.payload.summary
        state.formDetail = action.payload.formDetail
      })

      .addCase(fetchOrderStatsAsync.pending, (state, action) => {
        state.summary.loading = true;
      })
      .addCase(fetchOrderStatsAsync.rejected, (state, action) => {
        state.summary.loading = false;
      })
      .addCase(fetchOrderStatsAsync.fulfilled, (state, action) => {
        const {
          order_process_percentage,
          order_process_scanned,
          order_process_total
        } = action.payload;
        state.summary.loading = false;

        state.summary.order_process_percentage = order_process_percentage
        state.summary.order_process_scanned = order_process_scanned
        state.summary.order_process_total = order_process_total
      })


    ;
  },
})


const initialOrderProcessesState = {
  name: APP_ROUTES_NAME.ORDERS,
  search: {
    ...DEFAULT_VARIABLES.SEARCH_SETTINGS,
    queries: {
      ...DEFAULT_VARIABLES.SEARCH_SETTINGS.queries,
      sort: 'asc',
      sort_by: 'name'
    }
  },
  loading: false,
  data: [],
  dataMap: [],
  currentHistory: -1,
} as COMPONENT_INTERACTION & {
  dataMap: [],
  currentHistory: number,
}
export const orderProcessesSlice = createSlice({
  name: 'orderProcesses',
  initialState: initialOrderProcessesState,
  reducers: {
    reset: (state) => {
      state = initialOrderProcessesState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderHistoriesAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchOrderHistoriesAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchOrderHistoriesAsync.fulfilled, (state, action) => {
        const {
          processes,
          processesMap,
          currentHistory,
          queries,
        } = action.payload;
        state.loading = false;

        if (queries) {
          const {order_code, status, isSort} = queries

          state.search.queries = queries;
          state.search.selected = status;

        }

        state.data = processes;
        state.dataMap = processesMap;
        state.currentHistory = currentHistory;

      })

    ;
  },
})

export const orderDetailState = (state: RootState) => state.orderDetailPage

export const orderProcessesState = (state: RootState) => state.orderProcesses

export default orderDetailPageSlice.reducer;
