import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {Message} from "@src/types";
import {DEFAULT_MODAL} from "@src/variables";
import {ButtonVariant} from "react-bootstrap/types";

export interface MessageInterface {
    isShow: boolean
    title: string | any
    message: string | any
    messageData?: any
    type: Message
    size?: "sm" | "lg" | "xl",
    style?: {},
    cancelButton?: {
        visible: true,
        variant: ButtonVariant,
        label: string
    },
}

const initialState: MessageInterface = {
    ...DEFAULT_MODAL, size: "lg",
    title: "LABEL.PLEASE_LOGIN",
    message: "LABEL.TOKEN_EXPIRED.PLEASE_LOGIN",
    isShow: false,
    style:{
        zIndex: 999990002
    },
    cancelButton: {
        visible: true,
        variant: "success",
        label: "Login"
    },
}

export const sessionExpiredSlice = createSlice({
    name: 'sessionExpired',
    initialState,
    reducers: {
        showMessage: (state, action: PayloadAction<MessageInterface>) => {
            Object.assign(state, action.payload);
        },
        hideMessage: (state) => {
            state.isShow = false;
        }
    },
});
export const sessionExpiredState = (state: RootState) => state.sessionExpired;

export default sessionExpiredSlice.reducer;
