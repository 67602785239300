import React, {useEffect, useState} from 'react';
import _ from "lodash-es";
import axios from "axios";
import {Button, Modal} from "react-bootstrap";
import {DEFAULT_VARIABLES} from "@src/variables";
import {SEARCH_QUERIES} from "@src/types";
import {autoFetchNotification, blockPage, delay, formatShortDescription, translateV2} from '@src/helpers';
import {Link} from "react-router-dom";
import {handleFooter} from "@components/Footer/helper";
import {NOTIFICATION_API} from "@api/Notification";
import NotificationDetail from "@components/Notification/Detail/NotificationDetail";
import moment from "moment";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {configState} from "@redux/reducers/configSlice";
import {fetchNotificationAsync, markAllReadAsync, notificationState,} from "@redux/reducers/notificationSlice";
import {ASSETS} from "@assets/Assets";
import {appProviderState} from "@redux/reducers/appProviderSlice";

const ImageLazyLoader = React.lazy(() => import ("@v2components/Loading/ImageLazyLoader"))

const NotificationQuickList = ({
                                   settings = DEFAULT_VARIABLES.SEARCH_SETTINGS as SEARCH_QUERIES,
                                   onUpdateSettings = {} as any,
                                   onChange = {} as any,
                                   onInit = {} as any,
                               }: any) => {

    const notification = useAppSelector(notificationState);
    const appConfig = useAppSelector(configState);
    const {isLoggedIn} = useAppSelector(appProviderState)

    const dispatch = useAppDispatch();

    const [processing, setProcessing] = useState(false);
    const [searchSettings, setSearchSettings] = useState(settings)
    const [data, setData] = useState([] as any);
    const [notifyState, setNotifyState] = useState(false);
    const [messageModal, setMessageModal] = useState({
        isShow: false,
        text: '',
        type: 'info'
    });

    const [currentMessage, setCurrentMessage] = useState({
        id: "",
        summary: "",
        message: ""
    } as any)

    const searchSettingsHandle = {
        init: ({data, meta}) => {
            let st = {...searchSettings}, t = {};
            if (meta) {
                const total = Math.ceil(meta.total / searchSettings.queries.number_per_page);
                if (searchSettings.queries.page > 1 && !_.size(data)) {
                    st.queries.page = (meta.current_page - 1);
                }
                st.pagingData = {...st.pagingData, ...meta, total_pages: total}
                t = {...searchSettings, ...st}
            } else {
                st.pagingData = {...st.pagingData, total_pages: 1}
                t = {...searchSettings, ...st}
            }
            setSearchSettings(t)
            if (_.isFunction(onUpdateSettings)) onUpdateSettings(t)
        },
        onSort: (data: any) => {
            const t = {
                ...searchSettings,
                queries: {...searchSettings.queries, ...data}
            };
            setSearchSettings(t)
            if (_.isFunction(onUpdateSettings)) onUpdateSettings(t)
        },
        onSelectPage: (page: number) => {
            const t = {
                ...searchSettings,
                queries: {
                    ...searchSettings.queries,
                    page: page
                },
                pagingData: {
                    ...searchSettings.pagingData, current_page: page
                }
            };
            setSearchSettings(t)
            if (_.isFunction(onUpdateSettings)) onUpdateSettings(t)
        },
        onChange: ({selected, queries, pagingData}: SEARCH_QUERIES) => {
            const t = {
                ...searchSettings, ...
                    {
                        selected,
                        queries: {...searchSettings.queries, ...queries},
                        pagingData
                    }
            };
            setSearchSettings(t)
            if (_.isFunction(onUpdateSettings)) onUpdateSettings(t)
        },
        onTabChange: ({selected, queries, pagingData}: SEARCH_QUERIES) => {
            const t = {
                ...searchSettings, ...{
                    selected,
                    queries: {...searchSettings.queries, ...queries, page: 1}
                }
            }
            setSearchSettings(t)
            if (_.isFunction(onUpdateSettings)) onUpdateSettings(t)
        }

    }

    const handleMessageModal = {
        open: (data: any = {}) => {

            setMessageModal({
                ...messageModal,
                isShow: true,
            });
            setCurrentMessage({...currentMessage, ...data})
        },
        close: () => {
            setMessageModal({
                isShow: false,
                text: '',
                type: 'info'
            });
        }
    }

    const notificationHandle = {
        getBatch: async (extra = {}) => {
            try {
                if (!isLoggedIn) return false
                const processesReq = await NOTIFICATION_API.getBatch(_.omitBy(searchSettings.queries, _.isNil), extra);
                if (processesReq) {
                    let {data, meta, error} = processesReq;
                    if (data && !error) {
                        setData(data);
                        searchSettingsHandle.init({data, meta});
                        if (_.size(data) > 0) {
                            setNotifyState(!_.every(data, ['has_been_read', true]));
                            if (_.isFunction(onInit)) onInit({
                                data,
                                notifyState: !_.every(data, ['has_been_read', true])
                            })
                        }
                        if (_.isFunction(onChange)) onChange({
                            action: true,
                            data,
                            notifyState: !_.every(data, ['has_been_read', true])
                        })
                    }
                }
                return processesReq;
            } catch (e) {
                console.error(e)
            }
            blockPage(false);
            handleFooter();
        },
        setAsRead: async (notification: any) => {
            try {

            } catch (e) {
                console.error(e)
            }
        },
        markAllAsRead: async () => {
            try {
                setProcessing(true)
                dispatch(markAllReadAsync())
                await delay(500).then(() => {
                    setProcessing(false)
                })
            } catch (e) {
                console.error(e)
            }
        }
    }

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        (async () => {
            dispatch(fetchNotificationAsync({extra: source.token}))
            //await autoFetchNotification()
        })()

        return () => {
            setProcessing(false);
            blockPage(false);
        }
    }, [searchSettings.selected, searchSettings.queries])

    useEffect(() => {
        if (!_.isEqual(searchSettings, settings)) {
            setSearchSettings({...searchSettings, ...settings})
        }
    }, [settings])

    return (
        <>

            <div className={"quicknoti-header"}>
                <h3>{translateV2("LABEL.NOTIFICATION")}</h3>

                <div className={""}>

                    {notification.hasNotification &&
                        <button type={"button"} className={"text-center btn-textlink text-green fz-14"}
                                onClick={notificationHandle.markAllAsRead}
                        >
                            {processing && <FontAwesomeIcon icon={faSpinner} className="mr-1" spin/>}
                            {translateV2("LABEL.MARK_ALL_AS_READ")}
                        </button>}


                    <Link replace={true} to={"/notifications"} className={"btn-textlink fz-14"} onClick={() => {
                        if (_.isFunction(onChange)) onChange({close: true, data})
                    }}>{translateV2("LABEL.SEE_ALL")}</Link>
                </div>
            </div>


            <div className={"quicknoti-list"}>
                {
                    notification.list.map((item: any, itemIndex: any) =>
                        <div key={`notification-i-${itemIndex}`}
                             className={item.has_been_read ? "quicknoti-item-wrapper quicknoti-status read" : "quicknoti-item-wrapper quicknoti-status unread"}>

                            <div className={"quicknoti-item"}>

                                <div className={"quicknoti-summary"}>
                                    {item.logo &&
                                        <>
                                            <div className={"quicknoti-thumbnail"}>
                                                <ImageLazyLoader
                                                    decoding={"async"} loading={"lazy"}
                                                    alt={item.summary}
                                                    src={item.logo}
                                                    onError={({currentTarget}) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = ASSETS.logo
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                    <div className={"quicknoti-subject"}>
                                        <h3 onClick={(e) => handleMessageModal.open(item)} className={item.type}>
                                            {item.summary}
                                        </h3>

                                        <p>{formatShortDescription(item.message, 5)}...</p>
                                    </div>
                                </div>

                                <div className={"quicknoti-datetime"}>
                                    {moment.utc(item.created_at_utc).fromNow()}
                                </div>

                            </div>

                        </div>
                    )
                }

            </div>

            <Modal show={messageModal.isShow} onHide={handleMessageModal.close} size={"lg"}
                   className={"modal-notification"}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className={"d-flex justify-content-between"}
                             style={{fontSize: "14px", fontWeight: "bold"}}><span>{currentMessage.summary}</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NotificationDetail
                        id={!currentMessage.has_been_read ? currentMessage.id : ""}
                        data={currentMessage.has_been_read ? currentMessage : {}}
                        onRead={!currentMessage.has_been_read ? notificationHandle.setAsRead : () => {
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={handleMessageModal.close}>
                        {translateV2("LABEL.CLOSE")}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default NotificationQuickList;
