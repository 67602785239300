import {Button, Modal} from "react-bootstrap";
import {translateV2} from "@src/helpers";
import InputProducts from "@v2components/Order/LinkedOrder/InputProducts";
import React from "react";
import {linkedOrdersMessageSlice, linkedOrdersState} from "@redux/reducers/linkedOrderMessageSlice";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";

const ModalLinkedOrders = () => {

  const dispatch = useAppDispatch();
  const linkedOrdersStore = useAppSelector(linkedOrdersState)

  return (


    <Modal style={{zIndex: 999999999}} size="lg" show={linkedOrdersStore.isShow} onHide={() => {
      dispatch(linkedOrdersMessageSlice.actions.hideMessage())
    }}>
      <Modal.Header closeButton>
        <Modal.Title>{translateV2(linkedOrdersStore.title)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <InputProducts data={linkedOrdersStore.data || []}/>
      </Modal.Body>

      <Modal.Footer>
        <Button variant={"outline-success"} onClick={() => {
          dispatch(linkedOrdersMessageSlice.actions.hideMessage())
        }}>
          {translateV2("LABEL.CLOSE")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalLinkedOrders;
