import {axiosRequest, axiosRequestAlt} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";
import {serialize} from "object-to-formdata";
import {I_METADATA_GETTER, I_METADATA_SETTER} from "@src/types/metadata";

export const COMPANY_API = {

    BASE: '/company',

    getOne: (data: object = {}) => {
        return axiosRequest('/company', {
            method: 'GET',
            data: {},
            headers: {...AUTH.getHeader()},
        }, true);
    },

    getCompanyByID: (id: string) => {
        return axiosRequest(`/companies/${id}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, false);
    },

    getCompanyUsers: (data: object = {}) => {
        return axiosRequest('/company', {
            method: 'GET',
            params: data,
            headers: {...AUTH.getHeader()},
        }, false);
    },

    getCompanyTypes: (data: object = {}) => {
        return axiosRequest('/company/types', {
            method: 'GET',
            data: {},
            headers: {...AUTH.getHeader()},
        }, false);
    },

    getCompanyAuditors: async () => {
        try {
            return axiosRequest('/company/auditors', {
                method: 'GET',
                headers: {...AUTH.getHeader()},
            });
        } catch (e) {
            console.error(e);
            return false;
        }
    },

    updateCompany: (data: Object = {}) => {
        let t: Object = data;
        if (_.has(data, 'logo')) _.unset(t, 'logo');

        return axiosRequest('/company', {
            method: 'POST',
            data: t,
            headers: {...AUTH.getHeader()},
        }, false);
    },

    updateCompanyFormData: (data: any = {}, mode: string = "") => {

        let {types} = data;

        if (types && _.every(types, (v: any, k: number) => _.isObject(v))) {
            data.types = _.map(types, (v: any) => v.id + "")
        }

        const form_data = serialize(data);

        return axiosRequest('/company', {
            method: 'POST',
            body: form_data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': AUTH.getBearer()
            },
        }, false);
    },

    getCompanyFIDs: ({cancelToken}: any) => {
        return axiosRequest('/company/fibretrace-ids', {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            cancelToken,
        });
    },

    getCompanies: (params = {} as any) => {
        return axiosRequest('/companies', {
            method: 'GET',
            params,
            headers: {...AUTH.getHeader()},
        });
    },

    getCompaniesV2: (params = {} as any) => {
        return axiosRequestAlt('/companies', {
            method: 'GET',
            params,
            headers: {...AUTH.getHeader()},
        });
    },

    getAuditorByCompany: (data: { id: string | number, signal?: AbortSignal, [key: string]: any }) => {
        const {id, signal, ...params} = data;
        return axiosRequest(`/companies/${id}/auditors`, {
            method: 'GET', params,
            headers: {...AUTH.getHeader()},
        });
    },

    getAdmins: ({cancelToken = {}}) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        } as any
        if (cancelToken) options.cancelToken = cancelToken;
        return axiosRequest(`/company/admins`, options);
    },

    getBusinessTypes: ({signal}) => {
        let options = _.omitBy({
            method: 'GET', signal,
            headers: {...AUTH.getHeader()},
        }, _.isNil) as any
        return axiosRequest(`/business-types`, options);
    },

    updateCompanyV2: (data: Object = {}) => {
        return axiosRequestAlt('/company', {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    getCompanyMeta: (data: { fields: Array<string> }) => {
        return axiosRequest('/company-meta', {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, false);
    },

    updateCompanyFormDataV2: (data: any = {}, mode: string = "") => {

        /**
         * @todo
         * Test case submit empty types ...
         */
            // let {types} = data;
            //
            // if (types && _.every(types, (v: any, k: number) => _.isObject(v))) {
            //     data.types = _.map(types, (v: any) => v.id + "")
            // }

        const form_data = serialize(data);

        return axiosRequestAlt('/company', {
            method: 'POST',
            body: form_data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': AUTH.getBearer()
            },
        }, true, false);
    },

    checkUnclaimedCompany: (params: any) => {
        const {signal, company_name} = params;
        let options = _.omitBy({
            method: 'GET', signal,
            params: {company_name},
            headers: {...AUTH.getHeader()},
        }, _.isNil) as any
        return axiosRequest(`/check-unclaimed-company`, options);
    },

    getCompanyMetaData: (data: I_METADATA_GETTER = {object: "COMPANY",}) => {
        return axiosRequest('/get-metadata', {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    updateCompanyMetaData: (data: I_METADATA_SETTER) => {
        return axiosRequest('/update-metadata', {
            method: 'POST',
            data,
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    getPublicCompanies: (params = {} as any) => {
        return axiosRequest('/public-companies', {
            method: 'GET',
            params,
            headers: {...AUTH.getHeader()},
        });
    },
    getCompanyFIDsV2: (data: { signal?: AbortSignal } = {}) => {
        const {signal} = data;
        return axiosRequest('/company/fibretrace-ids', {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            signal,
        });
    },

}

